.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
}

#age-check {
    background-color: fade(@back-d4, 70%);

    .modal {
        .rounded(5px);
        overflow: hidden;
        text-align: center;

        #age-check-content {
            max-width: 25rem;
            margin: 0 2rem;
            padding: 0 1.5rem;
            background-color: var(--eighteen-overlay-bg-color);
            border-radius: 0.5rem;
        }

        h2 {
            padding: 1rem 0;
        }

        h3 {
            padding: 0.875rem 0;
            color: @white;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
        }

        p {
            color: @iron;
            font-weight: 400;
            font-size: 0.625rem;
            line-height: 1rem;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;

            .ft-button {
                background-image: linear-gradient(var(--primary-btn-bg-from-color), var(--primary-btn-bg-to-color));
                color: var(--primary-btn-color);
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.25rem;
                clear: both;
                border-radius: 0.2rem;
                text-shadow: none;
                box-shadow: none;
            }
        }

        #age-check-footer {
            display: flex;
            justify-content: space-evenly;
            padding: 1rem;

            a {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.25rem;
            }

            .parents {
                display: block;
                color: @rolling-stone;
            }

            .rta {
                width: 2.9375rem;
            }
        }
    }
}

#playlist-remodal {
    overflow: scroll;
    height: 100%;

    .title-inp {
        margin: 0.95rem 0 1.875rem 0;

        .placeholder-text {
            color: var(--playlist-modal-input-text-color);
            font-weight: 400;
            line-height: 1.25rem;
            background-color: var(--playlist-modal-input-background-color);
            padding: 0.625rem;
            width: 100%;
            border-radius: 0.25rem;
            border-color: transparent;
            font-size: 0.875rem;
        }
    }

    .playlist-list {
        padding-top: 0.625rem;
        clear: both;
        border-top: 0.0625rem solid var(--playlist-modal-divider-color);
        margin-top: 1.25rem;
        justify-content: center;
        overflow: scroll;
        max-height: 40vh;
        scrollbar-width: none;

        .list {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 0.5rem;

            .pl {
                margin-bottom: 1rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        h3 {
            color: var(--playlist-modal-title-color);
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin-top: 0.625rem;
        }

        p {
            width: 100%;
        }

        svg {
            color: var(--playlist-modal-icon-color);
            margin: 0 0.3125rem;
        }
    }
}

#missing-verification-remodal {
    max-width: 25rem;
    padding: 1.5rem;

    p {
        margin: 0.5rem 0 1rem;
    }

    .resend {
        width: 100%;
    }
}

html.desktop {
    #playlist-remodal {
        height: fit-content;
        position: relative;
        max-width: 25rem;
        overflow: scroll;
        scrollbar-width: none;

        .playlist-list {
            .list {
                .i_svg {
                    cursor: pointer;
                }
            }
        }
    }

    /* TODO: migrate to alpine version */
    #creator-edit-profile-link-placement {
        max-width: 25rem;

        .images {
            display: flex;
            justify-content: space-between;
        }
    }
}
