.manage_channels_content.main_content_container {
    .main_content_title {
        text-transform: capitalize;
    }

    .content-highlight-wrapper {
        #official {
            .create_account {
                .content_selector {
                    span {
                        .ft-button-bordered {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

html.desktop {
    .manage_channels_content.main_content_container {
        .main_content_title {
            text-align: center;
        }

        .content-highlight-wrapper {
            padding: 2.5rem 0 2rem;
            #official {
                .create_account {
                    width: 31%;
                    margin: 0 auto;

                    .content_selector {
                        display: grid;
                        grid-template-columns: 46% 46%;
                        column-gap: 8%;
                    }
                }
            }
        }
    }
}
