.playlist-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;

    .playlist-item {
        .adjusted-flex-basis(2, 0.5rem);
        position: relative;
        display: block;
        zoom: 1;
        background-color: var(--playlist-item-bg-color);
        overflow: hidden;
        border-radius: 0.25rem;

        .thumb {
            display: flex;
            flex-wrap: wrap;

            span.cover {
                width: 50%;
                position: relative;
                aspect-ratio: 1;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .play {
                position: absolute;
                top: 2%;
                left: 2%;
                color: rgba(0, 0, 0, 0.5);
                display: none;
            }

            span.len {
                position: absolute;
                line-height: 1.125rem;
                text-align: center;
                background-color: var(--playlist-length-background-color);
                border: 1px solid var(--playlist-length-border-color);
                color: var(--playlist-length-text-color);
                border-radius: 0.25rem;
                font-size: 0.75rem;
                font-weight: 400;
                padding: 0 0.5rem;
                left: 0.5rem;
                top: 0.5rem;
            }
        }

        .inf {
            padding: 0.5rem 0.75rem;
            overflow: hidden;
            clear: both;
            position: relative;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            text-align: left;
            color: var(--playlist-title-text-color);

            a {
                color: @back-d4;
            }

            strong {
                font-weight: 400;
                color: @text-l1;
                padding: 0 1px;
            }

            .pb {
                height: 24px;
                line-height: 24px;
                overflow: hidden;
                clear: both;
                width: 1%;
                background-color: @front-d4;
                display: none;
                margin-left: -5px;
            }
        }

        .pb {
            height: 24px;
            line-height: 24px;
            overflow: hidden;
            clear: both;
            width: 1%;
            background-color: @front-d3;
            display: none;
            margin-left: -5px;
            border-right: 4px solid rgba(0, 0, 0, 0.5);
        }
    }

    a.ft-button-bordered-short-width {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        margin-bottom: 0.5rem;
        font-size: 0.75rem;
        padding: 0.125rem 0.5rem;
    }

    .playlist-item:hover {
        z-index: 100;

        .play {
            display: block !important;
        }

        span.len {
            display: none !important;
        }
    }
}

// Video page list
#playlist_holder {
    h3 {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.25rem;
        color: @title-color;
        padding: 0;

        a {
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: var(--link-color);
        }

        em {
            margin-left: auto;
            margin-right: 0;
            color: @video-playlist-video-count-color;
            font-size: 0.875rem;
            font-weight: 400;
            font-style: normal;
            line-height: 1.25rem;
        }

        .edit {
            margin-left: 0.5rem;
        }
    }

    .prev_scroll,
    .next_scroll {
        svg {
            height: 20%;
            width: 2rem;
        }
    }
}

#playlist_list {
    height: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    white-space: nowrap;
    margin-top: 0.5rem;
    .flex-display();

    .video-item-deleted {
        .opacity(0.15);
        filter: gray;
        /* IE6-9 */
        -webkit-filter: grayscale(1);
        /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1);
        /* Microsoft Edge and Firefox 35+ */
    }

    .has_less {
        margin-left: 50px !important;
    }

    .video-item {
        .flex-basis(40%);
        .flex-shrink(0);
        max-width: 160px;
        padding: 0.5rem;
        position: relative;
        zoom: 1;
        overflow: hidden;

        .thumb {
            display: block;
            width: 100%;
            position: relative;
            height: 0;
            padding: 56% 0 0 0;
            overflow: hidden;
            .rounded(0.25rem);

            img.cover {
                position: absolute;
                display: block;
                width: 100%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .i-len {
                padding: 0.25rem;
                position: absolute;
                bottom: 0.25rem;
                right: 0.25rem;
                overflow: hidden;
                background-color: rgba(@black, 0.6);
                color: @text-color;
                font-size: 0.625rem;
                text-align: center;
                line-height: 0.875rem;
                font-weight: 700;
                .rounded(0.125rem);
            }

            > #mp4t_video {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: auto;
                cursor: pointer;
                .rounded(5px);
            }
        }

        &.video-item-active {
            background-color: @video-playlist-active-item-bg-color;
            max-height: fit-content;
            .rounded(0.25rem);

            .thumb {
                .rounded(0);
            }
        }

        .more_videos {
            background-color: @back-d4;

            span {
                position: absolute;
                top: 30px;
                left: 0;
                right: 0;
                width: 100%;
                display: block;
                text-align: center;
                color: @text-l1;
                text-transform: capitalize;
            }
        }

        .inf {
            line-height: 1.25rem;
            font-size: 0.875rem;
            text-align: center;
            color: @text-color;
            margin-top: 0.25rem;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

html.desktop {
    .playlist-list {
        .playlist-item {
            .adjusted-flex-basis(5, 0.5rem);
        }
    }

    #ajax_user_panel_playlists {
        .playlist-list {
            .playlist-item {
                .adjusted-flex-basis(4, 0.5rem);
            }
        }
    }

    #playlist_holder {
        h3 {
            gap: 0.5rem;

            em {
                margin-left: 0;
            }
        }
    }
}
