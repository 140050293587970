// main: style.less
/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
  ---------------------------------------------------*/

.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
    background: @color;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(@start),
        to(@stop)
    );
    background: -webkit-linear-gradient(top, @start, @stop);
    background: -moz-linear-gradient(top, @start, @stop);
    background: -ms-linear-gradient(top, @start, @stop);
    background: -o-linear-gradient(top, @start, @stop);
    background: linear-gradient(to bottom, @start, @stop);
}

.gradient_lr(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
    background: @color;
    background: -webkit-gradient(
        linear,
        left top,
        right bottom,
        from(@start),
        to(@stop)
    );
    background: -webkit-linear-gradient(left, @start, @stop);
    background: -moz-linear-gradient(left, @start, @stop);
    background: -ms-linear-gradient(left, @start, @stop);
    background: -o-linear-gradient(left, @start, @stop);
    background: linear-gradient(to right, @start, @stop);
}

.gradient_with_image(@color: #F5F5F5, @start: #EEE, @stop: #FFF, @image) {
    background: @color;
    background-image: url(@image);
    background-image:
        url(@image),
        -webkit-gradient(linear, left top, left bottom, from(@start), to(@stop));
    background-image:
        url(@image),
        -webkit-linear-gradient(top, @start, @stop);
    background-image:
        url(@image),
        -moz-linear-gradient(top, @start, @stop);
    background-image:
        url(@image),
        -ms-linear-gradient(top, @start, @stop);
    background-image:
        url(@image),
        -o-linear-gradient(top, @start, @stop);
    background-image: url(@image), linear-gradient(to bottom, @start, @stop);
}

.bw-gradient(@color: #F5F5F5, @start: 0, @stop: 255) {
    background: @color;
    background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0, rgb(@start, @start, @start)),
        color-stop(1, rgb(@stop, @stop, @stop))
    );
    background: -ms-linear-gradient(
        bottom,
        rgb(@start, @start, @start) 0%,
        rgb(@stop, @stop, @stop) 100%
    );
    background: -moz-linear-gradient(
        center bottom,
        rgb(@start, @start, @start) 0%,
        rgb(@stop, @stop, @stop) 100%
    );
    background: -o-linear-gradient(
        rgb(@stop, @stop, @stop),
        rgb(@start, @start, @start)
    );
    filter: e(
        %(
            "progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
            rgb(@stop, @stop, @stop),
            rgb(@start, @start, @start)
        )
    );
}
.bordered(@top-color: #EEE, @right-color: #EEE, @bottom-color: #EEE, @left-color: #EEE) {
    border-top: solid 1px @top-color;
    border-left: solid 1px @left-color;
    border-right: solid 1px @right-color;
    border-bottom: solid 1px @bottom-color;
}
.drop-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.3) {
    -webkit-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
    -moz-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
    box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}
.drop-shadow-white(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.3) {
    -webkit-box-shadow: @x-axis @y-axis @blur rgba(255, 255, 255, @alpha);
    -moz-box-shadow: @x-axis @y-axis @blur rgba(255, 255, 255, @alpha);
    box-shadow: @x-axis @y-axis @blur rgba(255, 255, 255, @alpha);
}
.text-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.4) {
    -webkit-text-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
    -moz-text-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
    text-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}

.text-shadow-white(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.6) {
    -webkit-text-shadow: @x-axis @y-axis @blur rgba(255, 255, 255, @alpha);
    -moz-text-shadow: @x-axis @y-axis @blur rgba(255, 255, 255, @alpha);
    text-shadow: @x-axis @y-axis @blur rgba(255, 255, 255, @alpha);
}

.rounded(@radius: 2px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}
.border-radius(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    -webkit-border-top-right-radius: @topright;
    -webkit-border-bottom-right-radius: @bottomright;
    -webkit-border-bottom-left-radius: @bottomleft;
    -webkit-border-top-left-radius: @topleft;
    -moz-border-radius-topright: @topright;
    -moz-border-radius-bottomright: @bottomright;
    -moz-border-radius-bottomleft: @bottomleft;
    -moz-border-radius-topleft: @topleft;
    border-top-right-radius: @topright;
    border-bottom-right-radius: @bottomright;
    border-bottom-left-radius: @bottomleft;
    border-top-left-radius: @topleft;
    .background-clip(padding-box);
}
.opacity(@opacity: 0.5) {
    opacity: @opacity;
    @opperc: @opacity * 100;
    -ms-filter: ~'progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})';
    filter: ~'alpha(opacity=@{opperc})';
}
.transition-duration(@duration: 0.2s) {
    transition-duration: @duration;
}
.transform(...) {
    -webkit-transform: @arguments;
    -moz-transform: @arguments;
    -o-transform: @arguments;
    -ms-transform: @arguments;
    transform: @arguments;
}
.rotation(@deg:5deg) {
    .transform(rotate(@deg));
}
.scale(@ratio:1.5) {
    .transform(scale(@ratio));
}
.transition(@duration:0.2s, @ease:ease-out) {
    transition: all @duration @ease;
}
.inner-shadow(@horizontal:0, @vertical:1px, @blur:2px, @alpha: 0.4) {
    -webkit-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    -moz-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
}
.inner-shadow-white(@horizontal:0, @vertical:1px, @blur:2px, @alpha: 0.4) {
    -webkit-box-shadow: inset @horizontal @vertical @blur
        rgba(255, 255, 255, @alpha);
    -moz-box-shadow: inset @horizontal @vertical @blur
        rgba(255, 255, 255, @alpha);
    box-shadow: inset @horizontal @vertical @blur rgba(255, 255, 255, @alpha);
}
.box-shadow(@arguments) {
    -webkit-box-shadow: @arguments;
    -moz-box-shadow: @arguments;
    box-shadow: @arguments;
}
.box-sizing(@sizing: border-box) {
    -ms-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    -webkit-box-sizing: @sizing;
    box-sizing: @sizing;
}
.user-select(@argument: none) {
    -webkit-user-select: @argument;
    -moz-user-select: @argument;
    -ms-user-select: @argument;
    user-select: @argument;
}
.columns(@colwidth: 250px, @colcount: 0, @colgap: 50px, @columnRuleColor: #EEE, @columnRuleStyle: solid, @columnRuleWidth: 1px) {
    -moz-column-width: @colwidth;
    -moz-column-count: @colcount;
    -moz-column-gap: @colgap;
    -moz-column-rule-color: @columnRuleColor;
    -moz-column-rule-style: @columnRuleStyle;
    -moz-column-rule-width: @columnRuleWidth;
    -webkit-column-width: @colwidth;
    -webkit-column-count: @colcount;
    -webkit-column-gap: @colgap;
    -webkit-column-rule-color: @columnRuleColor;
    -webkit-column-rule-style: @columnRuleStyle;
    -webkit-column-rule-width: @columnRuleWidth;
    column-width: @colwidth;
    column-count: @colcount;
    column-gap: @colgap;
    column-rule-color: @columnRuleColor;
    column-rule-style: @columnRuleStyle;
    column-rule-width: @columnRuleWidth;
}
.translate(@x:0, @y:0) {
    .transform(translate(@x, @y));
}
.background-clip(@argument: padding-box) {
    -moz-background-clip: @argument;
    -webkit-background-clip: @argument;
    background-clip: @argument;
}
.placeholder(@color: @input-color-placeholder) {
    &:-moz-placeholder {
        color: @color;
    } // Firefox 4-18
    &::-moz-placeholder {
        color: @color;
    } // Firefox 19+
    &:-ms-input-placeholder {
        color: @color;
    } // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: @color;
    } // Safari and Chrome
}

// --------------------------------------------------
// Flexbox LESS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
// flex or inline-flex
.flex-display(@display: flex) {
    display: ~'-webkit-@{display}';
    display: ~'-ms-@{display}box'; // IE10 uses -ms-flexbox
    display: ~'-ms-@{display}'; // IE11
    display: @display;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
.flex(@columns: initial) {
    -webkit-flex: @columns;
    -ms-flex: @columns;
    flex: @columns;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
.flex-direction(@direction: row) {
    -webkit-flex-direction: @direction;
    -ms-flex-direction: @direction;
    flex-direction: @direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
.flex-wrap(@wrap: nowrap) {
    -webkit-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
.flex-flow(@flow) {
    -webkit-flex-flow: @flow;
    -ms-flex-flow: @flow;
    flex-flow: @flow;
}

// Display Order
// - applies to: flex items
// <integer>
.flex-order(@order: 0) {
    -webkit-order: @order;
    -ms-order: @order;
    order: @order;
}

// Flex grow factor
// - applies to: flex items
// <number>
.flex-grow(@grow: 0) {
    -webkit-flex-grow: @grow;
    -ms-flex-grow: @grow;
    flex-grow: @grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
.flex-shrink(@shrink: 1) {
    -webkit-flex-shrink: @shrink;
    -ms-flex-shrink: @shrink;
    flex-shrink: @shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex items
// <width>
.flex-basis(@width: auto) {
    -webkit-flex-basis: @width;
    -ms-flex-basis: @width;
    flex-basis: @width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
.justify-content(@justify: flex-start) {
    -webkit-justify-content: @justify;
    -ms-justify-content: @justify;
    justify-content: @justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
.align-content(@align: stretch) {
    -webkit-align-content: @align;
    -ms-align-content: @align;
    align-content: @align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
.align-items(@align: stretch) {
    -webkit-align-items: @align;
    -ms-align-items: @align;
    align-items: @align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
.align-self(@align: auto) {
    -webkit-align-self: @align;
    -ms-align-self: @align;
    align-self: @align;
}
