// main: style.less

button {
    border: 0;
}

.ft-button {
    &:not(.ft-button-size-lg):not(.ft-button-size-sm) {
        .primary-button();
    }

    &.ft-button-size-lg {
        .primary-button(@size: lg);
    }

    &.ft-button-size-sm {
        .primary-button(@size: sm);
    }
}

.ft-button-bordered {
    &:not(.ft-button-size-lg):not(.ft-button-size-sm) {
        .primary-button(@type: bordered);
    }

    &.ft-button-size-lg {
        .primary-button(@type: bordered, @size: lg);
    }

    &.ft-button-size-sm {
        .primary-button(@type: bordered, @size: sm);
    }
}

.ft-button-bordered-short-width {
    .primary-button(@display: inline-block, @type: bordered);
}

.secondary-button {
    .secondary-button();
}

.age-no-check-button {
    .age-no-check-button();
}

.ad_button {
    display: block;
    width: 30rem;
    margin: 1.5rem auto;
    background-color: @dark-tan;
    padding: 0.5rem;
    border-radius: 0.25rem;

    a {
        display: block;
        text-align: center;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: white;
        letter-spacing: 0.1rem;
    }
}
