.empty_search {
    .empty-content {
        h3 {
            font-size: 1rem;

            span {
                color: var(--filter-item-color);
            }
        }
    }
}

html.desktop {
    .empty_search {
        .empty-content {
            padding: 1.5rem;

            h3 {
                font-size: 1.125rem;
            }
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        .empty_search {
            .empty-content {
                padding: 3.5rem;
            }
        }
    }
}
