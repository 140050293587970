// main:style.less

nav.paginate-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    gap: 0.25rem;

    a,
    .status {
        display: flex;
        justify-content: center;
        align-items: center;
        .flex-basis(calc(33.333% - 0.125rem));
    }

    a {
        .flex-shrink(0);
        gap: 0.5rem;
        height: 2.5rem;
        padding: 0.5rem;
        color: var(--pagination-prev-next-color);

        &.deactive {
            opacity: 0.4;
        }

        .i_svg {
            top: 0;
        }
    }

    .status {
        color: var(--pagination-item-color);
        background-color: var(--pagination-item-bg-color);
        font-size: 0.875rem;
        padding: 0 0.5rem;
        height: 2rem;
        min-width: 6.25rem;
        border-radius: 0.25rem;
    }
}

div.pagination {
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        align-self: center;
        gap: 0.125rem;

        li {
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:not(.previous):not(.next) {
                a {
                    font-size: 0.875rem;
                    padding: 0 0.25rem;
                }
            }

            &:not(.previous):not(.next):not(.disabled) {
                a {
                    color: var(--pagination-item-color);
                    background-color: var(--pagination-item-bg-color);
                    min-width: 2rem;
                    height: 2rem;
                    border-radius: 0.25rem;
                    .defaulthover;
                }

                &.active {
                    a {
                        background-color: var(--pagination-item-active-bg-color);
                        color: var(--pagination-item-active-color);
                    }
                }
            }

            &.disabled:not(.previous):not(.next) {
                a {
                    height: 1.5rem;
                    color: var(--pagination-dots-color);
                }
            }

            &.previous,
            &.next {
                .flex-basis(1.875rem);
                .flex-shrink(0);

                a {
                    width: 1.875rem;
                    height: 1.875rem;
                    color: var(--pagination-prev-next-color);

                    .i_svg {
                        top: 0;
                    }
                }

                &.disabled {
                    a {
                        .opacity(0.4);
                    }
                }
            }
        }
    }
}

.pagination-page-info {
    display: block;
    text-align: center;
    color: var(--pagination-info-color);
    font-size: 0.75rem;

    &:first-letter {
        text-transform: uppercase;
    }

    b {
        font: inherit;
    }
}

@media all and (min-width: 375px) {
    div.pagination {
        ul {
            gap: 0.25rem;

            li {
                &:not(.previous):not(.next) {
                    a {
                        font-size: 0.875rem;
                        padding: 0 0.5rem;
                    }
                }

                &:not(.previous):not(.next):not(.disabled) {
                    a {
                        min-width: 2.5rem;
                    }
                }

                &.previous,
                &.next {
                    .flex-basis(2.5rem);

                    a {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }
        }
    }
}

html.desktop {
    nav.paginate-bar {
        .prev,
        .next {
            font-size: 1rem;
        }

        a,
        .status {
            padding: 1.25rem 0.5rem;
            font-size: 1rem;
        }
    }

    div.pagination {
        ul {
            li {
                &:not(.previous):not(.next):not(.disabled) {
                    a {
                        font-size: 1rem;
                        width: 3rem;
                        height: 3rem;
                    }
                    &:not(.active):hover {
                        a {
                            background-color: var(--pagination-item-bg-hover-color);
                        }
                    }
                }

                &.previous,
                &.next {
                    a {
                        width: 3rem;
                        height: 3rem;

                        .i_svg {
                            width: 1rem;
                            height: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    .pagination-page-info {
        font-size: 0.875rem;
    }
}
