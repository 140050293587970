.playlist_page {
    .top-wrapper {
        .space-vertical();
        .top-buttons {
            display: flex;
            gap: 0.5rem;

            > * {
                .adjusted-flex-basis(2, 0.5rem);
                flex-grow: 1;
            }
        }
    }
}

html.desktop {
    .playlist_page {
        .top-wrapper {
            .space-vertical(0);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .top-buttons {
                > * {
                    flex-basis: auto;
                }

                .ft-button {
                    width: 18.75rem;
                }
            }
        }
    }
}
