// main:style.less

.authentication {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    padding: 1.5rem 2.5rem;

    .title {
        display: flex;
        flex-direction: column;
        background: none;
        align-items: center;

        .head {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            color: var(--signup-title-text-color);
        }

        .logo {
            width: 12.5rem;
        }
    }

    .features {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 45%;

        .features_content {
            display: flex;
            flex-direction: column;
            gap: 1.5625rem;
            background-color: var(--signup-features-bg-color);
            justify-content: space-around;
            border-radius: 0.5rem;
            padding: 2.5rem 3.125rem;

            li {
                display: flex;
                flex-direction: row;
                text-align: left;
                gap: 0.625rem;

                p {
                    align-self: center;
                }
            }
        }

        svg {
            fill: var(--signup-icon-color);
        }
    }

    .action {
        .primary-button(@size: lg);
        width: 100%;
        height: 2.5rem;
        margin: 1.5rem 0 1.5rem;
    }

    .secondary-action {
        color: white;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.25rem;

        .login_btn {
            color: white !important;
            margin-top: 0.625rem;
        }

        .desc {
            text-align: center;
            padding: 0 15%;
        }
    }

    &.login {
        width: 28.75rem;

        .new-form-holder {
            display: flex;
            flex-direction: column;
        }

        h3 {
            color: var(--form-auth-title-text-color);
            font-size: 1.25rem;
        }

        ul {
            margin-top: 0.9375rem;
            min-width: 20rem;
        }

        .secondary-action {
            font-weight: 400;
        }
    }
}
