.new-form-holder {
    &.edit_playlist {
        .title_container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        p.btn_container {
            display: flex;
            gap: 0.5rem;
            margin: 1rem 0 0;

            .submit {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--playlist-button-text-color);
                background-image: linear-gradient(
                    var(--playlist-button-gradient-color-start),
                    var(--playlist-button-gradient-color-end)
                );

                .i_svg {
                    color: var(--playlist-button-text-color);
                    width: 1.3125rem;
                    height: 1.3125rem;
                    top: 0;
                }
            }

            .delete {
                background-color: transparent;
                .i_svg {
                    width: 0.625rem;
                }
            }
        }
    }
}

html.desktop {
    #edit_playlist {
        padding: 0;
        .title_container,
        p.btn_container {
            width: 33%;
        }
    }
}
