@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] {
    display: none;
}

.responsive-page {
    .i_svg {
        top: 0;
    }
}

svg {
    vertical-align: baseline;
}

@layer base {
    textarea:focus,
    input:focus:not(.ms-ctn input) {
        @apply outline outline-link-primary;
    }
}

@layer components {
    .spinner {
        @apply h-12 w-12 animate-spin rounded-full border-8 border-solid border-surface-quaternary border-t-surface-brand-light;
    }
}

@layer utilities {
    .red-circle-behind-text {
        flex-shrink: 0;
        font-size: 1.25rem;
        border-radius: 50%;
        @apply bg-surface-brand;
        width: 2.75rem;
        height: 2.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}
