// main:style.less

.i_svg {
    height: 1em;
    width: 1em;
    display: inline-block;
    fill: @text;
    fill: currentColor;
    position: relative;
    top: 0.125em;
}
