#creator {
    overflow: hidden;

    .create_account,
    .create_creator {
        .top {
            .links {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .create_account,
    .edit_ads {
        .form {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            li {
                flex-basis: 100%;

                &.d {
                    font-size: 0.875rem;
                }

                &.hr {
                    background-color: var(--user-panel-separator-color);
                    height: 1px;
                    width: 100%;
                    margin: 1rem 0;
                }
            }
        }
    }

    .create_account {
        .form {
            .description textarea {
                height: 5rem;
            }

            .submit {
                .ft-button {
                    width: 100%;
                }
            }

            .error {
                color: var(--error-color);
            }
        }

        .title_section {
            .main_content_subtitle {
                padding: 0;
                font-size: 1.125rem;
                line-height: 1.5rem;
                color: var(--creator-main-content-subtitle-color);
            }

            .checkbox_list {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-top: 0.5rem;

                li {
                    display: flex;
                    flex-basis: 25%;
                    flex-direction: row;
                    gap: 0.5rem;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.25rem;

                    .i_svg {
                        color: var(--title-icon-color);
                        flex-shrink: 0;
                        align-self: center;
                        margin-top: -0.25rem;
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                }
            }
        }

        .identity_section {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .identify_text {
                color: var(--textarea-color);
                font-size: 0.875rem;
            }
            .identify_subtext {
                color: var(--creator-identify_subtext-color);
            }

            span {
                font-size: 0.875rem;
                line-height: 1.25rem;
            }

            h2,
            h3 {
                padding: 0;
                font-weight: 700;
                line-height: 1.5rem;
                color: var(--text-color);
            }

            h2 {
                font-size: 1rem;
                display: flex;
                align-items: center;

                .i_svg {
                    margin-right: 0.5rem;
                    color: var(--creator-icon-color);
                    width: 1.5rem;
                    height: 1.5rem;
                    top: 0;
                    padding: 0.125rem;
                }
            }

            h3 {
                font-size: 0.875rem;
            }

            ul {
                padding: 0 1rem;
                gap: 0.5rem;
                display: flex;
                flex-direction: column;
                font-size: 0.875rem;

                li {
                    padding: 0;
                    list-style: disc;
                }
            }
        }

        .links {
            display: flex;
            gap: 0.5rem;
            margin-top: 0.5rem;

            a {
                flex-grow: 1;
            }
        }

        form {
            a {
                color: var(--creator-link-color);
            }

            textarea {
                resize: vertical;
            }

            .banned_countries {
                select {
                    padding: 0.5rem;
                    border-radius: 0.25rem;
                    border: none;
                    background-color: var(--country-ban-bg-color);
                    color: var(--country-ban-text-color);
                }

                label {
                    margin-bottom: 0.5rem;
                }
            }

            li.s {
                button {
                    width: 100%;
                }
            }
        }

        // The ms- classes are coming from the MagicSuggest plugin
        .ms-ctn {
            border: none;
            background-color: var(--input-bg-color);
            padding: 0.625rem 0.75rem 0.625rem 1.25rem;
            border-radius: 0.25rem;
        }

        .ms-sel-ctn {
            display: flex;
            align-items: center;
        }

        .ms-sel-item {
            padding: 0.25rem 0.5rem;
            background: var(--tag-bg-color);
            display: flex;
            align-items: center;
        }

        .ms-res-item,
        .ms-sel-item {
            color: var(--textarea-color);
        }

        .ms-res-ctn {
            border: none;
        }

        .ms-close-btn {
            width: 0.875rem;
            height: 0.875rem;
            .svg-icon--clear(E9657B);
            .background();
            margin: 0 0 0 0.125rem;
        }
    }

    .create_channel {
        .next {
            padding: 0.625rem;
            background-color: @back-d4;
            .rounded(0.3125rem);
            text-align: center;
            margin: 0.3125rem;

            a {
                display: inline-block;
                width: 15.625rem;
                height: 6.25rem;
                line-height: 6.25rem;
                background-color: @front-d4;
                color: @text-l1;
                text-align: center;
                font-size: 1.5em;
                .rounded(0.625rem);
                margin: 0.3125rem;
            }
        }
    }

    .create_creator {
        .creators-list {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            a {
                .adjusted-flex-basis(2, 1rem);
            }
        }
    }
}

html.desktop {
    #creator {
        .create_account,
        .create_creator,
        .edit_ads {
            .content-highlight-wrapper {
                padding: 2.5rem;
            }
        }

        .create_account {
            .title_section {
                margin-bottom: 1.5rem;
                padding: 0 20%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .checkbox_list {
                    flex-direction: row;
                    margin-top: 1.5rem;

                    li {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        .i_svg {
                            margin-top: 0rem;
                        }
                    }
                }

                h1 {
                    align-self: center;
                }
            }

            .form {
                width: 37.5rem;
                margin: 0 auto;

                li {
                    &.name,
                    &.email,
                    &.password,
                    &.country,
                    &.birth_date {
                        .adjusted-flex-basis(2, 1rem);
                    }

                    &.birth_date {
                        flex-basis: calc(33.33% - 0.5rem);
                    }

                    &.s {
                        flex-basis: auto;
                    }
                }
            }

            .identity_section {
                margin-top: 0.5rem;
                padding: 1.5rem;
                border: 1px solid var(--creator-page-border-color);
                border-radius: 0.5rem;
            }
        }

        .edit_ads {
            .form {
                li {
                    &.link,
                    &.channel-page-ad {
                        .adjusted-flex-basis(2, 1rem);
                    }

                    &.video-player-ad {
                        .adjusted-flex-basis(3, 1rem);
                    }
                }
            }
        }


        .create_creator {
            .creators-list {
                a {
                    .adjusted-flex-basis(3, 1rem);
                }
            }
        }
    }

    #browse_new {
        &.creator_page {
            ul.top {
                .i {
                    .follow {
                        width: 100%;
                        padding: 6px;
                        font-size: 1em;
                        border: 1px solid @front-d4;
                        margin-top: 10px;

                        svg {
                            margin-right: 0.25rem;
                        }

                        em {
                            font-style: normal;
                            color: @front-l4;
                            .opacity(@opacity: 0.7);
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width: @breakpoint-xl) {
        #creator {
            .create_account,
            .create_creator {
                .top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .links {
                        margin-top: 0;
                    }
                }
            }

            .create_creator {
                .creators-list {
                    a {
                        .adjusted-flex-basis(4, 1rem);
                    }
                }
            }
        }
    }
}