// main:style.less

#embed {
    height: 100%;

    // For embed player

    #video-container-embed {
        position: relative;
        width: 100%;
        .flex-display(@display: flex);
        .flex-wrap(@wrap: wrap);
        min-height: 100vh;
        max-height: 100vh;
        height: 100vh;

        > #embed-video-player {
            width: 100%;
            height: 90vh;
        }

        > .promo {
            width: 100%;
            background-color: @back-d4;
            max-height: 10vh;
        }

        .button-logo,
        .button-hd,
        .button-dl {
            display: inline-block;
            padding: 5px;
            color: @text-l1;
            font-size: 2.2vw;
            z-index: 10000000;
            margin: 10px 10px 0 10px;

            .fa {
                color: @front-l4;
            }
        }

        .button-hd {
            float: right;
        }

        .button-dl {
            float: right;
        }

        .button-logo {
            padding: 0;
            width: 20vw;
        }

        &:hover {
            .button-logo,
            .button-hd,
            .button-dl {
                .opacity(1);
            }
        }
    }
}
