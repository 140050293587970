// Spaces
.space-vertical(@space: 1rem) {
    > :not([hidden]) ~ :not([hidden]) {
        margin-top: @space;
    }
}

.space-horizontal(@space: 1rem) {
    > :not([hidden]) ~ :not([hidden]) {
        margin-left: @space;
    }
}

// Divide
.divide-x(@width: 0.0625rem, @color: var(--divide-color), @gap: 1rem) {
    & > *:first-child:not(:last-child) {
        padding-right: @gap;
    }

    & > *:not(:first-child):not(:last-child) {
        padding-left: @gap;
        padding-right: @gap;
    }

    & > *:last-child:not(:first-child) {
        padding-left: @gap;
    }

    & > *:not(:last-child) {
        border-right-width: @width;
        border-right-color: @color;
        border-right-style: solid;
    }
}

// transitions & hover
.defaulthover {
    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out,
        opacity 0.3s ease-in-out,
        fill 0.3s ease-in-out,
        border-color 0.3s ease-in-out,
        transform 0.3s ease-in-out;
}

.hover-desktop-only(@content, @before: undefined) {
    & when (@before = undefined) {
        @media (hover: hover) {
            &:hover {
                @content();
            }
        }
    }
    & when (@before = before) {
        &:hover:before {
            @content();
        }
    }
}

.active-desktop-only(@content) {
    @media (hover: hover) {
        &:active {
            @content();
        }
    }
}

// Layout
.adjusted-flex-basis(@column-count: 4, @gap-width: 0.5rem) {
    @column-ratio: (100% / @column-count);
    @gap-adjustment: (((@column-count - 1) * @gap-width) / @column-count);

    flex-basis: calc(@column-ratio - @gap-adjustment);
}

.tag-list(@item-selector: li) {
    .flex-display();
    .align-items(center);
    overflow-x: auto;
    gap: 8px;

    @{item-selector} {
        white-space: nowrap;
        padding: 0.25rem 0.5rem;
        background-color: var(--tag-bg-color);
        color: var(--tag-color);
        font-size: 0.8125rem;
        line-height: 1.25rem;
        cursor: pointer;
        text-transform: capitalize;
        display: block;
        .rounded(0.25rem);
        .defaulthover;
        .hover-desktop-only({color: var(--tag-hover-text-color)});
        .active-desktop-only({background-color: @tag-active-bg-color; color: @tag-active-color;});

        img {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 0.1875rem;
            margin-right: 0.25rem;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .highlight-tag a {
        background-color: var(--tag-active-bg-color);
        .defaulthover;
    }
}

.primary-button(@display: flex, @type: fill, @size: md) {
    &:not(.hidden) {
        display: @display;
    }
    font-size: 0.875rem;
    color: var(--primary-btn-color);
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    .i_svg {
        width: 0.875rem;
        height: 0.875rem;
        top: 0;
    }

    & when (@display = flex) {
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }

    & when (@size = sm) {
        padding: 0.3125rem 1rem;
    }

    & when (@size = md) {
        padding: 0.5rem 1rem;
    }

    & when (@size = lg) {
        padding: 0.75rem 1rem;
    }

    & when (@type = fill) {
        background-image: linear-gradient(var(--primary-btn-bg-from-color), var(--primary-btn-bg-to-color));
        background-origin: border-box;

        .i_svg {
            color: var(--primary-btn-color);
        }
    }

    & when (@type = bordered) {
        background-color: transparent;
        border-color: var(--primary-btn-border-color);
        color: var(--primary-btn-bordered-color);

        .i_svg {
            color: var(--primary-btn-bordered-icon-color);
        }
    }

    html.desktop & {
        .defaulthover;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: '';
            inset: 0;
            border-radius: 0.25rem;
            z-index: -1;
            opacity: 0;
            .defaulthover;
        }

        & when (@size = sm) {
            padding: 0.3125rem 1rem;
        }

        & when (@type = fill) {
            &:before {
                background-image: linear-gradient(
                        var(--primary-btn-hover-bg-from-color),
                        var(--primary-btn-hover-bg-to-color)
                );
            }
            .hover-desktop-only({opacity: 1}, @before: before);
        }

        & when (@type = bordered) {
            &:before {
                background-color: var(--bordered-btn-hover-bg-color);
            }

            .hover-desktop-only({opacity: 0.1}, @before: before);
        }
    }
}

.secondary-button() {
    display: block;
    text-align: center;
    color: var(--secondary-btn-text-color);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background: none;
    width: 100%;
}

.age-no-check-button() {
    display: block;
    text-align: center;
    color: var(--text-color);
    font-weight: 400;
    padding: 0.625rem 2.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background: none;
    width: 100%;

    html.desktop & {
        .defaulthover;

        &:hover {
            color: var(--secondary-btn-text-color);
        }
    }
}

.radio-style() {
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    margin-right: 1rem;
    border: 0.0625rem solid var(--radio-item-dot-border-color);
    content: '';
    appearance: none;
    vertical-align: middle;
    border-radius: 100%;
}

.custom-radio(@type: fake) {
    &,
    .new-form-holder .form-field-wrapper & {
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: var(--radio-item-bg-color);
        color: var(--radio-item-color);
        font-size: 0.875rem;
        line-height: 1.25rem;
        border: 0.0625rem solid var(--radio-item-bg-color);
        display: block;
        text-align: left;
        border-radius: 0.25rem;
        .defaulthover;

        & when (@type = fake) {
            &.fake-radio:not(.active):hover {
                color: var(--radio-hover-item-text-color);
            }

            &.active {
                border-color: var(--radio-active-item-border-color);
                &:before {
                    border-color: var(--radio-active-item-dot-border-color);
                    background-color: var(--radio-active-item-dot-bg-color);
                }
            }

            &:before {
                .radio-style();
            }
        }

        & when (@type = real) {
            &.custom-radio:has(:not(input:checked):hover) {
                color: var(--radio-hover-item-text-color);
            }

            &:has(input:checked) {
                border-color: var(--radio-active-item-border-color);
                input {
                    border-color: var(--radio-active-item-dot-border-color);
                    background-color: var(--radio-active-item-dot-bg-color);
                }
            }

            input {
                .radio-style();
            }

            label {
                font: inherit;
                color: inherit;
                line-height: inherit;
                display: inline-block;
            }
        }
    }
}

.remove-scroll-indicator() {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

// Hide last row items if there is not enough item to fill it
.hide-last-row-items(@n-of-items-to-fill-a-row, @i: 1) when (@i < @n-of-items-to-fill-a-row) {
    @value: @n-of-items-to-fill-a-row - @i;

    &:nth-last-child(-n + @{value}):nth-child(@{n-of-items-to-fill-a-row}n + @{i}) {
        display: none;
    }

    .hide-last-row-items(@n-of-items-to-fill-a-row, (@i + 1));
}

.background(@repeat: no-repeat, @position: center, @size: cover) {
    background-position: @position;
    background-size: @size;
    background-repeat: @repeat;
}

.svg-icon--clear(@color) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23@{color}'%3E%3Cpath  d='M17.85 16.44a.5.5 0 0 1 0 .71l-.7.7a.5.5 0 0 1-.71 0L12 13.41l-4.44 4.44a.5.5 0 0 1-.71 0l-.7-.7a.5.5 0 0 1 0-.71L10.59 12 6.15 7.56a.5.5 0 0 1 0-.71l.7-.7a.5.5 0 0 1 .71 0L12 10.59l4.44-4.44a.5.5 0 0 1 .71 0l.7.7a.5.5 0 0 1 0 .71L13.41 12l4.44 4.44Z'/%3E%3C/svg%3E");
}
