#error {
    width: 100%;
    height: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: @back-d2;

    .box {
        position: absolute;
        left:50%;
        top:50%;
        width: 400px;
        background-color: @front-d4;
        height: 450px;
        margin: -320px 0 0 -200px;

        .logo {
            clear: both;
            display: block;
            overflow: hidden;
            text-align: center;
            margin:10px 0 30px 0;

            img {
                width: 200px;
            }
        }

        .message {
            text-align: center;
            color: @text;

            h1 {
                font-size: 2em;
                height: auto;
                line-height: 120px;
                color: @text-l1;
            }

            p {
                font-size: 14px;
                line-height: 30px;
            }
        }

        .search {
            padding: 10px;
            background-color: @front-d2;
            margin-top: 20px;
            overflow: hidden;

            .inp {
                height: 43px;
                margin-top: -4px;
                width: 334px;
                border: 1px solid rgba(0, 0, 0, 0.4);
                background-color: rgba(0, 0, 0, 0.2);
                font-size: 1.2em;
                line-height: 43px;
                text-indent: 1%;
                float: left;
                color:@text-l1;
                border-right: 0;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                }
                &:focus {
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    background-color: rgba(255, 255, 255, 0.1);
                    border-right: 0;
                }
            }

            .btn {
                background-color: rgba(0, 0, 0, 0.3);
                height: 43px;
                width: 43px;
                text-align: center;
                margin-top: -4px;
                color:@text;
                border: 1px solid rgba(0, 0, 0, 0.2);
                float: left;
                font-size: 1.5em;
                border-left: 0;
            }
            .btn:hover {
                background-color: @front-d2;
            }
        }

        .home {
            display: block;
            color: @text;
            text-align: center;
            padding-top: 20px;
            font-size: 22px;
        }

    }
}
