#home {
    .home_skin {
        margin-top: 15px;
        margin-bottom: -5px;
        margin-left: -2%;
        margin-right: -2%;
        text-align: center;

        ins {
            width: 100% !important;
        }
    }

    .videos {
        h1 {
            color: var(--title-color);
        }

        h2 {
            margin-bottom: 1rem;

            a {
                display: block;
                color: @text-l1;

                .i_svg {
                    fill: @back-l4;

                    &.i_angle-right {
                        fill: @front;
                    }
                }
            }
        }

        .more_videos {
            width: 500px;
            display: block;
            margin: 10px auto;
        }

        .empty {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        .pornstars {
            clear: both;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            --gap: 0.5rem;
            gap: var(--gap);

            a.ps {
                display: inline-block;
                flex-shrink: 0;
                color: var(--trending-title-color);
                text-align: center;
                font-size: 0.75rem;
                line-height: 1.125rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:nth-child(n + 7) {
                    display: none;
                }
                .adjusted-flex-basis(6, 0.5rem);

                img {
                    display: block;
                    width: 100%;
                    margin-bottom: 0.25rem;
                    border-radius: 0.25rem;
                    object-fit: cover;
                    aspect-ratio: 0.75;
                }
            }

            a.as {
                display: inline-block;
                color: @text;
                text-align: center;
                font-size: 0.9em;
                padding: 3px 10px 3px 1px;
                background-color: @back-d4;
                margin: 2px;
                .rounded(5px);

                img {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    .rounded(5px);
                    vertical-align: middle;
                    background-color: @back;
                }

                &:hover {
                    background-color: @front-d4;
                }
            }
        }

        .trending_categories {
            h2 {
                margin-bottom: 0.5rem;
            }

            .trending_categories_list {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 0.5rem;

                a.ps {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    color: var(--trending-title-color);
                    font-size: 0.75rem;
                    line-height: 1.125rem;

                    img {
                        display: block;
                        margin-bottom: 0.25rem;
                        border-radius: 0.25rem;
                    }
                }

                a.ps:nth-child(n + 7) {
                    display: none;
                }
            }
        }

        .searches {
            clear: both;
            overflow: hidden;

            a {
                display: inline-block;
                padding: 0.25rem 0.5rem;
                background-color: var(--tag-bg-color);
                color: var(--tag-color);
                font-size: 0.875rem;
                line-height: 1.5rem;
                border-radius: 0.25rem;

                &:hover {
                    color: var(--tag-hover-text-color);
                    cursor: pointer;
                }
            }
        }

        .showcase {
            a {
                color: @text-l1;
            }

            margin: 15px 0 10px 0;

            h1 {
                float: right;
                font-weight: 200;
            }

            .edition {
                margin-left: 5px;
                color: @text;

                img {
                    max-width: 28px;
                    max-height: 18px;
                }

                svg.pencil-square {
                    fill: @front-l4;
                    margin-left: 5px;
                }
            }
        }
    }

    .tweets {
        display: block;
        overflow: hidden;
        margin: 10px;

        .tweet {
            display: inline-block;
            width: 32%;
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        #home {
            .videos {
                .trending_categories {
                    .trending_categories_list {
                        grid-template-columns: repeat(12, 1fr);

                        a.ps:nth-child(-n + 12) {
                            display: flex;
                        }

                        a.ps:nth-child(n + 13) {
                            display: none;
                        }
                    }
                }
            }
            .pornstars {
                a.ps {
                    .adjusted-flex-basis(10, 0.5rem);
                    &:nth-child(n + 11) {
                        display: none;
                    }
                    &:nth-child(-n + 10) {
                        display: block;
                    }
                }
            }
        }
    }
}
