#user_panel {
    .sub_nav {
        display: flex;
        margin-bottom: 1rem;
        justify-content: space-around;

        a {
            width: 100%;
            padding: 0.5rem 0;
            flex-grow: 1;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            color: var(--subscriptions-text-color);
            text-align: center;

            &.active {
                border-bottom: 1px solid var(--subscriptions-content-contrast-color);
            }
        }
    }

    .content_selector {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        .ft-button-bordered {
            flex-basis: 0;
            flex-grow: 1;
            padding: 0.1875rem;
            border-color: var(--subscriptions-inactive-color);
            font-weight: 400;
            font-size: 0.875rem;

            &.active {
                border-color: var(--subscriptions-content-contrast-color);
            }
        }
    }

    .nav_quick_top {
        margin-bottom: 1rem;

        a {
            margin-right: 1rem;
            padding-bottom: 0.5rem;

            &.active {
                border-bottom: 1px solid var(--subscriptions-content-contrast-color);
            }
        }
    }

    .title {
        padding: 0.5rem;
        color: var(--subscriptions-text-color);
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        svg {
            fill: var(--subscriptions-content-contrast-color);
        }
    }

    .lists {
        a {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: var(--subscriptions-text-color);
        }

        .item {
            padding: 1rem 0;
            display: flex;
            justify-content: space-between;
            border: none;
            border-bottom: 0.0625rem solid var(--subscriptions-inactive-color);
            border-top: 0.0625rem solid var(--subscriptions-inactive-color);

            svg {
                height: 1rem;
                fill: var(--subscriptions-text-contrast-color);
            }
        }
    }
}
