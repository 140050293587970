.new-form-holder {
    .form-field-wrapper {
        &.has-icon {
            .text-field {
                padding-left: 2.5rem;
            }
        }

        &.select-field {
            .text-field {
                height: 2.5rem;
                -webkit-appearance: menulist;
            }
        }

        label {
            line-height: 1.5rem;
            font-size: 1rem;
            font-weight: 400;
            color: var(--label-text-color);
            text-align: left;

            &:not(.custom-checkbox) {
                display: block;
            }

            &.hidden {
                display: none;
            }

            & + .field_wrapper {
                margin-top: 0.5rem;
            }
        }

        .field_wrapper {
            position: relative;

            &.input_error {
                .text-field {
                    border: 0.0625rem solid var(--form-error-border-color);
                }
            }

            .icon,
            .reveal {
                .flex-display();
                .justify-content(center);
                .align-items(center);
                position: absolute;
                top: 0;
                width: 2.5rem;
                height: 100%;

                svg {
                    color: var(--input-icon-color);
                    top: 0;
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }

            .icon {
                left: 0;
            }

            .reveal {
                right: 0;
                cursor: pointer;
                svg {
                    color: var(--input-color);
                }
            }
        }

        .text-field {
            font-weight: 400;
            font-size: @input-font-size;
            line-height: 1.25rem;
            padding: 0.625rem 0.75rem;
            width: 100%;
            .rounded(0.25rem);
            display: block;
            background-color: var(--input-bg-color);
            color: var(--input-color);
            border: none;
            &:focus {
                border: none;
                &::placeholder {
                    color: transparent;
                }
            }
        }

        .required_mark {
            color: var(--form-required-mark-color);
            margin-left: -0.125rem;
        }
    }

    p.errors {
        padding: 0;
        overflow: hidden;
        color: var(--form-error-color);
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: left;
        margin-top: 0.25rem;
    }

    .desc {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 0.875rem;
        color: var(--form-description-color);
        text-align: left;
        margin-top: 0.5rem;

        &.desc-center {
            text-align: center;
        }

        &:first-letter {
            text-transform: uppercase;
        }

        ul {
            li {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin: 1rem 0;

                span {
                    margin-left: auto;

                    .i_svg {
                        width: 1.1rem;
                        height: 1.1rem;
                        color: var(--form-icon-color);
                    }

                    :hover {
                        cursor: pointer;
                    }
                }
            }

            // bullet-pints, flexbox make them disappear
            li::before {
                align-self: baseline;
                content: '•';
                color: var(--form-description-color);
            }
        }
    }
}

.text-area {
    background-color: var(--textarea-bg-color);
    width: 100%;
    min-height: 3.5rem;
    color: var(--textarea-color);
    padding: 0.5rem 0.75rem;
    font-size: @input-font-size;
    border: none;
    border-radius: 0.25rem;
    resize: vertical;
}

html.desktop {
    .new-form-holder {
        div.desc {
            ul {
                li {
                    margin: 0.25rem 0;
                }
            }
        }

        &.forgot {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .text-field {
                width: 36vw;
                max-width: 18.75rem;
            }
        }
    }
}
