#main_tags {
    .search_holder {
        margin-top: 1rem;
        position: relative;

        .icon {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            right: 0;
            width: 2.5rem;
            height: 100%;

            svg {
                color: var(--tags-input-icon-color);
                top: 0;
            }
        }

        input {
            padding: 0.5rem 1rem;
            width: 100%;
            font-weight: 400;
            font-size: @input-font-size;
            line-height: 1.5rem;
            display: inline-block;
            border: none;
            background: var(--tags-input-bg-color);
            border-radius: 0.25rem;
            color: var(--tags-input-text-color);
        }
    }

    .top_tags_list,
    .list {
        margin: 0.5rem 0 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        li {
            display: inline-block;
            text-transform: capitalize;
            padding: 0.5rem 0.5rem 0.5rem 0;
            cursor: pointer;

            a {
                display: block;
                color: var(--tags-text-color);
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.25rem;
                overflow: hidden;
                width: 100%;
            }
        }
    }
}

html.desktop {
    #main_tags {
        .search_holder {
            .icon {
                svg {
                    font-size: 1.25rem;
                }
            }
        }

        .top_tags_list,
        .list {
            grid-template-columns: repeat(3, 1fr);

            li {
                padding: 0;

                a {
                    color: var(--tags-desktop-text-color);
                    text-overflow: ellipsis;
                    padding: 0.5rem;
                    background-color: var(--tags-bg-color);
                    border-radius: 0.25rem;
                    .hover-desktop-only({background-color: var(--tags-input-bg-color)});
                    .defaulthover;
                }
            }
        }
    }

    @media all and (min-width: @breakpoint-xl) {
        #main_tags {
            .top_tags_list,
            .list {
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }
}
