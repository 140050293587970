// main:style.less

#tv {
    h1,
    h2 {
        margin: 0 0 1rem;
    }

    .ft-button {
        height: 2.5rem;
    }

    .tv-listpage-wrapper {
        .space-vertical();
    }

    .start {
        padding: 1rem;
        overflow: hidden;
        background-color: var(--tv-bg-color);
        border-radius: 0.25rem;

        .keyword {
            width: 100%;
            text-align: center;
            border-radius: 0.25rem;

            h3 {
                padding: 0;
                color: var(--tv-title-color);
                font-weight: 700;
                line-height: 1.25rem;
            }

            form {
                margin-top: 1rem;
                width: 100%;

                .ki {
                    width: 100%;
                    background-color: var(--tv-input-bg-color);
                    font-size: 0.875rem;
                    text-align: center;
                    line-height: 1.25rem;
                    padding: 0.5rem;
                    color: var(--tv-title-color);
                    border-radius: 0.25rem;
                    border: none;
                }

                hr {
                    margin: 1rem auto;
                }

                .ft-button {
                    margin: 0 auto;
                }
            }
        }
    }

    //.popular {
    //    width: 100%;
    //    text-align: center;
    //    border-radius: 0.25rem;
    //
    //    a.k {
    //        display: inline-block;
    //        background-color: @back;
    //        padding: 5px 10px;
    //        margin: 5px 3px;
    //        color: @text-l1;
    //        .rounded(5px);
    //        font-size: 1em;
    //    }
    //}

    .menu {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0.5rem;

        li {
            .adjusted-flex-basis(2, 0.5rem);

            .ft-button {
                display: flex;
                align-items: center;
                justify-content: center;

                .i_svg {
                    width: 0.7rem;
                }
            }
        }
    }

    #video_container {
        position: fixed;
        overflow: hidden;
        display: none;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 898;

        #video_player_container_1,
        #video_player_container_2 {
            display: block;
            width: 100%;
            height: 0;
            padding: 56% 0 0 0;
            position: absolute;
            left: 0;
            z-index: 10;

            > div {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                max-height: 98vh;
            }
        }

        #video_player_container_2 {
            z-index: 8;
        }

        .video-js.vjs-playing .vjs-tech {
            pointer-events: none;
        }

        .tap_to_unmute {
            position: fixed;
            top: 47%;
            z-index: 1200;
            left: 0;
            right: 0;
            width: 250px;
            margin: 0 auto;
            display: block;
            font-size: 1em;
            background-color: @front-d4;
            padding: 10px 10px;
            .rounded(3px);
        }
    }

    .details {
        display: none;
    }

    .loading {
        background-image: url('/static/mobile/Images/standby.png');
        background-size: contain;
        background-position: center center;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        overflow: hidden;

        span {
            position: absolute;
            bottom: 15%;
            display: block;
            text-align: center;
            width: 100%;

            .i_svg {
                fill: @text-l1;
                font-size: 4em;
            }

            h2 {
                margin-top: 20px;
            }
        }
    }

    .switch_to_landscape {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: @front-d4;
        z-index: 2300;
        text-align: center;

        span {
            margin-top: 120px;
            font-size: 2em;
            display: block;

            img {
                display: block;
                margin: 20px auto;
            }
        }
    }
}

@media only screen and (orientation: portrait) {
    #tv .switch_to_landscape {
        display: block !important;
        z-index: 2600 !important;
    }
    #tv .player,
    #tv .tv_is_loading {
        display: none;
    }
}

@media only screen and (orientation: landscape) {
    #tv .switch_to_landscape {
        display: none !important;
        z-index: 0 !important;
    }
    #tv .player,
    #tv .tv_is_loading {
        display: block;
    }
}

html.desktop {
    #tv {
        min-height: @empty-page-min-height;

        .tv-listpage-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            .space-vertical(0);
        }

        .start {
            padding: 1.5rem;
            .keyword {
                text-align: left;

                form {
                    .ki {
                        text-align: left;
                    }

                    .ft-button {
                        margin-left: 0;
                    }
                }
            }
        }

        .menu {
            li {
                .adjusted-flex-basis(3, 0.5rem);
            }
        }

        #video_container {
            position: relative;
            height: 0;
            overflow: hidden;
            padding: 60% 0 0;
            display: none;

            #video_player_container_1,
            #video_player_container_2 {
                top: 3.75rem;
            }
        }

        .details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 30;

            span {
                padding: 0.625rem 0.5625rem 0.625rem 0.5625rem;

                &.name {
                    color: @text-l1;
                }
            }

            ul.controls {
                li {
                    display: inline-block;

                    &.current_time {
                        padding: 10px 4px 10px 4px;
                    }
                    &.total_time {
                        padding: 10px 4px 10px 4px;
                    }
                    &.next_scene {
                        padding: 10px 40px 10px 40px;
                    }
                    &.voice_control {
                        display: none;
                    }
                }
            }
        }
    }

    @media all and (min-width: @breakpoint-xl) {
        #tv {
            .tv-listpage-wrapper {
                flex-direction: row;
                gap: 2.5rem;
            }

            .start-wrapper,
            .more-stations-wrapper {
                .adjusted-flex-basis(2, 2.5rem);
            }

            .start {
                padding: 1rem;
            }
        }
    }
}
