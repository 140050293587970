// main:style.less

.account-page {
    .content-highlight-wrapper {
        .fields-group {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            background-color: transparent;
            padding: 0;

            > label {
                .adjusted-flex-basis(2, 0.5rem);
            }
        }
    }
}

html.desktop {
    .account-page {
        .content-highlight-wrapper {
            padding: 2.5rem;
        }

        form {
            .ft-button {
                width: 40%;
                white-space: nowrap;
            }

            #reg_avatar {
                width: 50%;
            }
        }

        .fields-group {
            gap: 1rem;

            > label {
                .adjusted-flex-basis(2, 1rem);
            }
        }

        .secondary-button {
            display: inline-block;
            width: auto;
        }

        @media all and (min-width: @breakpoint-xl) {
            form {
                width: calc(50% - 1.5rem);
            }
        }
    }
}
