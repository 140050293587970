#official {
    overflow: hidden;

    .create_account {
        .form {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            li {
                &.s {
                    .ft-button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .create_channel {
        text-align: center;

        form {
            margin-top: 1rem;
        }

        li {
            margin: 1rem 0;

            &.s {
                display: flex;

                .ft-button {
                    margin: 1.5rem auto;
                    flex: 0 1 25rem;
                }
            }

            label {
                text-align: center;
            }

            .field_wrapper {
                display: flex;
                justify-content: center;

                input,
                textarea {
                    flex: 0 1 25rem;
                }
            }
        }

        h1,
        h2 {
            color: var(--text-color);
        }

        h2 {
            margin-top: 0.5rem;
            font-size: 1rem;
        }

        .next {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            text-align: center;

            .ft-button {
                flex-basis: 12rem;
            }
        }
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .links {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            flex-grow: 1;
            flex-basis: 80%;
            flex-wrap: wrap;

            a {
                flex-basis: 100%;
            }
        }
    }

    .edit_channel {
        ul {
            margin-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            li {
                flex-basis: 100%;
            }
        }

        textarea {
            resize: vertical;
            height: 6.625rem;
            width: 100%;
        }

        li.s {
            display: flex;
            justify-content: center;

            .ft-button {
                margin-top: 1.5rem;
                flex-basis: 25rem;
            }
        }
    }

    .bc {
        a,
        span {
            font-size: 1rem;
        }

        span {
            color: var(--text-color);
        }

        .i_svg {
            height: 0.75rem;
            margin-bottom: 0.1875rem;
        }
    }

    .edit_ads {
        .form {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            li {
                flex-basis: 100%;

                &.d {
                    font-size: 0.875rem;
                }

                &.hr {
                    background-color: var(--user-panel-separator-color);
                    height: 1px;
                    width: 100%;
                    margin: 1rem 0;
                }
            }
        }
    }
}

html.desktop {
    #official {
        .content-highlight-wrapper {
            padding: 2.5rem;
        }

        .nav {
            .links {
                flex-wrap: nowrap;

                a {
                    flex-basis: 50%;
                }
            }
        }

        .bc {
            a,
            span {
                font-size: 1.125rem;
            }
        }

        .edit_channel {
            ul {
                li:not(.d):not(.s) {
                    .adjusted-flex-basis(2, 1rem);
                }
            }
        }

        .create_account {
            .form {
                flex-direction: row;
                flex-wrap: wrap;

                li {
                    &:not(.year):not(.country):not(.logo):not(.s):not(.agreement) {
                        .adjusted-flex-basis(2, 1rem);
                    }

                    &.year,
                    &.country,
                    &.logo {
                        .adjusted-flex-basis(2, 1rem);
                    }

                    &.s {
                        .adjusted-flex-basis(3, 1rem);
                    }

                    &.agreement {
                        flex-basis: 100%;
                    }
                }
            }
        }

        .edit_ads {
            .form {
                li {
                    &.link,
                    &.channel-page-ad {
                        .adjusted-flex-basis(2, 1rem);
                    }

                    &.video-player-ad {
                        .adjusted-flex-basis(3, 1rem);
                    }
                }
            }
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        #official {
            .nav {
                .links {
                    justify-content: flex-end;
                    flex-basis: 0;

                    a {
                        flex-basis: fit-content;
                    }
                }
            }

            .create_account {
                .form {
                    li {
                        &.year,
                        &.country,
                        &.logo {
                            .adjusted-flex-basis(3, 1rem);
                        }
                    }
                }
            }
        }
    }
}
