.form-holder {
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0 2%;
    margin-top: 10px;
    .rounded(5px);
    text-align: left !important;

    .register,
    .login {
        width: 46%;
        margin: 0 2%;
        padding: 1%;
        min-height: 300px;
        display: inline-block;
        background-color: @back-d4;
        border: 1px solid @back-l4;
        .rounded(5px);
    }

    p.btn {
        text-align: center;
        margin: 10px 0;
    }

    h3 {
        padding-bottom: 10px;
    }

    a {
        color: @front;
        text-decoration: underline;
    }

    ul.features {
        display: inline-block;
        width: 49%;
        height: 350px;
        vertical-align: top;

        li {
            padding: 3px 0;
            color: @text-l1;

            svg {
                fill: @front;
            }

            h1 {
                text-align: center;
            }

            font-size: 1.5em;

            &.head {
                font-size: 2em;
                font-weight: 200;
                color: @front-l4;
            }

            &.logo {
                img {
                    width: 200px;
                }
            }
        }
    }

    ul.country_list {
        margin: 5px 0;

        li {
            display: inline-block;
            cursor: pointer;
            width: 40px;
            text-align: center;
            .rounded(5px);

            &:hover {
                background-color: @back;
            }

            img {
                width: 32px;
                height: 20px;
                display: inline-block;
                margin: 5px 0;
                .rounded(3px);
                cursor: pointer;

                &.ww {
                    width: auto;
                    border-right: 1px solid @back;
                    padding-right: 10px;
                }
            }
        }
    }

    ul {
        width: 600px;
        margin: 0 auto;
        overflow: hidden;

        li {
            clear: both;
            margin: 4px 0;
            overflow: hidden;

            label {
                color: @text-d1;
            }

            label.main {
                display: block;
                float: left;
                background-color: @front-d4;
                width: 120px;
                padding-left: 5px;
                height: 30px;
                line-height: 30px;
                color: @text-l1;
            }

            p.desc {
                color: @text;
                font-size: 0.9em;
                clear: both;
                padding: 5px 0 5px 5px;
            }

            p.errors {
                clear: both;
                overflow: hidden;
                color: @front-l4;
                padding: 4px 0 5px 124px;
                max-width: 400px;
            }

            input[type='radio'] {
                display: block;
                margin: 7px 6px 0 4px;
                float: left;
            }

            input[type='checkbox'] {
                display: block;
                margin: 7px 6px 0 4px;
                float: left;
            }

            .text-field {
                height: 30px;
                line-height: 30px;
                width: 250px;
                border: 1px solid @back-d2;
                display: block;
                float: left;
                text-indent: 5px;
                font-size: 1.1em;
                background-color: @back-d1;
                color: @text-d1;

                &:focus {
                    background-color: @back-d3 !important;
                    border: 1px solid @front-d4;
                }

                &:hover {
                    background-color: @back-d3;
                }
            }

            .text-field-small {
                width: 100px !important;
            }

            .text-field-medium {
                width: 150px;
            }

            .textarea-field {
                height: 100px;
                line-height: 20px;
                width: 250px;
                border: 1px solid @back-d4;
                display: block;
                float: left;
                text-indent: 5px;
                font-size: 1em;
                background-color: @back-d4;
                color: @text-d1;

                &:focus {
                    background-color: @back-d3 !important;
                    border: 1px solid @front-d4;
                }

                &:hover {
                    background-color: @back-d3;
                }
            }
        }
    }
}

.big-text-black {
    background-color: @back-d4;
    width: 100%;
    max-width: 400px;
    height: 40px;
    color: @front-l4;
    padding: 4px;
    font-size: 1.3em;

    .bordered(#222,#111,#111,#333);
    .drop-shadow();
}
