// main:style.less

#channel {
    ul.left {
        width: 210px;
        float: left;
        overflow: hidden;
        padding-right: 5px;
        .drop-shadow(@x-axis: 3px, @y-axis: 0, @blur: 3px, @alpha: 0.5);
        .border-radius(@topright: 10px, @bottomright: 10px, @bottomleft: 0, @topleft: 0);
        margin-right: 10px;
        .gradient_lr(@color: @back-d1, @start: @back-d1, @stop: @back-d2);

        li {
            clear: both;
            margin: 5px 0;
            display: block;

            &.p {
                text-align: center;

                img {
                    width: 180px;

                    .gradient(@color: @back-d3, @start: @back-d3, @stop: @back-d4);
                    .drop-shadow(@x-axis: 0, @y-axis: 3px, @blur: 5px, @alpha: 0.8);
                    .rounded(5px);
                }
            }

            &.s {
                text-align: center;
            }

            &.i {
                margin-top: 20px;
                span {
                    width: 100%;
                    display: block;

                    strong {
                        color: @back-l4;
                        width: 80px;
                        display: inline-block;
                    }

                    &.desc {
                        margin: 20px 0;
                    }
                }
            }

            &.parent {
                text-align: center;
                padding: 10px 0;
                border-top: 1px solid @back-d4;

                a {
                    display: block;
                    padding: 5px 0;
                    font-size: 1.5em;
                    color: @front-l4;
                }
            }
        }
    }

    .info {
        padding-left: 220px;
        background-color: @back-d4;
        line-height: 30px;
    }

    .right {
        margin-left: 200px;
        overflow: hidden;

        .ads {
            text-align: center;
        }
    }

    .nav-bar-left {
        .gradient(@color: rgba(0,0,0,0), @start: rgba(0,0,0,0), @stop: rgba(0,0,0,0.15));
        .drop-shadow(@x-axis: 0, @y-axis: 3px, @blur: 5px, @alpha: 0.8);
        .rounded(10px);
        margin: 0 0 10px 0;
        height: 30px;

        #time_slider {
            margin: -2px 5px 0 5px;
            display: block;
            width: 200px;
            float: left;
        }

        #time_slider_value {
            display: block;
            margin-left: 10px;
            float: right;

            li {
                display: inline-block;
                font-size: 1em;
                color: @back-l4;
                line-height: normal;
                padding: 0;
                margin: 0;

                &.min {
                    float: none;
                    padding-right: 5px;
                }

                &.max {
                    float: none;
                    margin-left: 5px;
                }
            }
        }

        > ul {
            margin: 0;
            height: 30px;

            > li {
                display: block;
                float: left;
                padding: 0 7px;
                text-align: center;
                margin: 0;
                height: 20px;
                position: relative;
                background-color: transparent;

                &.h {
                    color: @back-l4;
                    font-size: 1em;
                    padding: 0 0 0 10px;
                }

                ul.sub {
                    position: absolute;
                    left: 0;
                    width: 100px;
                    background-color: @back-d2;
                    text-align: left;
                    font-size: 0.8em;
                    padding: 5px;
                    display: none;
                    z-index: 1000;

                    a {
                        color: @text;

                        &.active {
                            color: @front-l4;
                        }

                        &:hover {
                            color: @text-l1;
                        }
                    }
                }

                &.time_slider_parent {
                    width: 300px;
                    margin: 0 0 0 15px;
                }

                > a {
                    color: @text;
                    display: block;
                    font-size: 1em;
                    padding: 0 3px;
                    border-bottom: 3px solid transparent;
                    height: 100%;

                    span {
                        float: right;
                        color: @back-l2;
                    }

                    &:hover {
                        color: @text-l1;
                    }
                }

                &.active {
                    > a {
                        color: @front-l4;
                        border-bottom: 3px solid @front-d4;
                    }

                    ul.sub {
                        a {
                            color: @text;

                            &.active {
                                color: @front-l4;
                            }

                            &:hover {
                                color: @text-l1;
                            }
                        }
                    }
                }

                &:hover {
                    ul.sub {
                        display: block;
                    }
                }
            }
        }
    }
}

#browse_new.channel_page {
    ul.top {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        overflow: hidden;

        li {
            flex-grow: 0;
            flex-shrink: 0;

            .p {
                text-align: center;
                width: 6.25rem;
                flex-basis: 6.25rem;

                img {
                    width: 100%;
                    border-radius: 0.25rem;
                }
            }

            &.i {
                display: flex;
                gap: 1rem;
                padding-right: 0.5rem;

                .channel-info {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                h1 {
                    font-size: 1.125rem;
                    line-height: 1.5rem;

                    a {
                        float: right;
                    }
                }

                .parent {
                    display: block;
                    text-align: left;
                    color: var(--channel-page-description-color);
                    font-size: 0.75rem;

                    a {
                        line-height: 0.75rem;
                    }
                }

                .follow {
                    width: 100%;
                    max-width: 10.1875rem;

                    em {
                        font-style: normal;
                    }
                }

                .cta_container {
                    display: flex;
                    flex-direction: row;
                    gap: 0.5rem;
                    max-height: 2rem;

                    a {
                        .i_svg {
                            top: 0;
                            margin: 0;
                            color: var(--channel-page-visit-cta-icon-color);
                        }
                    }
                }
            }

            &.sp {
                flex-grow: 1;
                flex-shrink: 1;
            }

            &.t {
                display: flex;
                justify-content: center;

                iframe {
                    overflow: hidden;
                    border-radius: 0.25rem;
                }
            }
        }
    }

    .similar_channels {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        padding-bottom: 0.25rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        gap: 1rem;

        a.image {
            display: flex;
            flex-direction: column;
            text-align: center;

            img {
                border-radius: 0.25rem;
                margin-bottom: 0.25rem;
            }
        }

        a.image:nth-child(n + 7) {
            display: none;
        }
    }

    p.desc {
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        color: var(--channel-page-description-color);
        font-size: 0.75rem;
        line-height: 1.25rem;
    }

    .ttaa2v3 {
        display: block;
        text-align: center;
        padding: 0.625rem;
        background-color: var(--ad-button-bg-color);
        color: var(--ad-button-color);
        border-radius: 0.25rem;
        margin-top: 1rem;
        font-size: 0.75rem;
    }
}

@media all and (min-width: @breakpoint-xl) {
    #browse_new.channel_page {
        ul.top {
            flex-direction: row;
            justify-content: space-between;

            li {
                &.i {
                    flex-grow: 1;

                    .channel-info {
                        flex-grow: 1;

                        .follow {
                            max-width: 18.75rem;
                        }
                    }
                }
            }
        }

        .similar_channels {
            grid-template-columns: repeat(10, 1fr);

            a.image:nth-child(-n + 10) {
                display: flex;
            }

            a.image:nth-child(n + 11) {
                display: none;
            }
        }
    }
}
