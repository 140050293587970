// Colors New UI
@apricot: #f08e84;
@brick-red: #cf3952;
@bulgarian-rose: #440b05;
@burnt-maroon: #380804;
@bunker: #11181f;
@bunker-rgb: 17, 24, 31;
@black: #000;
@black-rgb: 0, 0, 0;
@bright-sun: #ffda46;
@cerise-red: #e43f5a;

@california: #f99f06;
@crail: #bb493d;
@cornflower-blueapprox: #4b555e;

@dark-tan: #60140c;
@ebony-clay: #273441;
@ebony-clay-rgb: 39, 52, 65;
@gothic: #7399a9;
@gray-chateau: #a5aaaf;
@heavy-metal: #19221e;
@iron: #d2d4d7;
@limed-spruce: #353f4a;
@limed-spruce-rgb: 53, 63, 74;

@mandy: #e9657b;
@moccaccino: #701b13;
@mirage: #1e2a36;
@mirage-d: #18222b;
@mirage-d-rgb: 24, 34, 43;

@pistachio: #85d400;
@rolling-stone: #787f86;
@silver-chalice: #aaa;
@supernova: #ffcc00;
@van-cleef: #50100b;
@white: #fff;
@white-rgb: 255, 255, 255;
@your-pink: #ffc4be;
@alto: #d9d9d9;
@tamarillo: #9b2118;
@atlantis: #9ddd33;
@jungle-green: #27ae60;
@kournikova: #ffe16b;
@yelloworange: #fab238;
@surfaceinfo: #679ff5;

// Common
@title-color: @white;
@text-color: @white;
@title-subtext-color: @rolling-stone;

// 18+ overlay
@eighteen-overlay-bg-color: @mirage;

// Footer
@footer-bg-color: @mirage-d;
@footer-color: @white;
@footer-nav-link-color: @white;
@footer-nav-icon-color: @mandy;
@footer-highlighted-bg-color: @mirage;
@footer-highlighted-text-color: @rolling-stone;
@footer-highlighted-text-highlighted-color: @white;
@footer-bottom-btn-bg-color: @bunker;
@footer-bottom-btn-color: @white;
@footer-bottom-btn-icon-color: @mandy;
@footer-link-color: @iron;
@footer-account-user-name-color: @mandy;
@footer-bottom-btn-badge-bg-color: @brick-red;
@footer-bottom-btn-badge-color: @white;
@footer-benefits-text-color: @white;
@footer-benefits-icon-color: @brick-red;
@footer-login-separator-color: @limed-spruce;

// Playlist
@playlist-item-bg-color: @mirage;
@playlist-length-background-color: @bunker;
@playlist-input-bg-color: @bunker;
@playlist-length-border-color: @mandy;
@playlist-title-text-color: @mandy;
@playlist-length-text-color: @white;

// Video list
@video-list-hd-bg-color: @black;
@video-list-hd-color: @white;
@video-list-user-color: @mandy;
@video-list-stats-color: @rolling-stone;
@video-list-name-color: @gray-chateau;
@video-list-context-menu-trigger-color: @gray-chateau;
@video-list-context-menu-trigger-active-color: @brick-red;

// Popout menu
@popout-menu-bg-color: @mirage;
@popout-menu-color: @white;
@popout-menu-icon-color: @gray-chateau;
@popout-menu-divider-color: @bunker;

// Video page
@video-toolbar-item-bg-color: @mirage;
@video-toolbar-item-color: @rolling-stone;
@video-toolbar-icon-color: @gray-chateau;
@video-toolbar-quality-menu-bg-color: @mirage;
@video-toolbar-quality-menu-border-color: @mirage-d;
@video-toolbar-quality-menu-color: @gray-chateau;
@video-user-bg-color: @mirage;
@video-user-color: @gray-chateau;
@video-user-link-color: @mandy;
@video-avatar-default-bg-color: @black;
@video-playlist-video-count-color: @rolling-stone;
@video-playlist-active-item-bg-color: @dark-tan;

// Messagebar
@messagebar-bg-color: @mirage;
@messagebar-textarea-bg-color: @bunker;
@messagebar-textarea-text-color: @gray-chateau;

// Tags
@tag-active-bg-color: @brick-red;
@tag-active-color: @white;

// Signup
@signup-features-bg-color: @bunker;
@signup-button-text-color: @white;
@signup-feature-icon-color: @mandy;
@signup-feature-text-color: @iron;

// Profile (channel, pornstar, user)
@profile-top-name-color: @white;
@profile-top-details-color: @rolling-stone;
@profile-top-details-info-color: @iron;
@profile-icon-color: @mandy;
@profile-success-color: @pistachio;

// Channel page
@channel-page-description-color: @gray-chateau;

// Bottom sheet
@bottom-sheet-overlay-bg-color: @bunker;
@bottom-sheet-title-color: @rolling-stone;
@bottom-sheet-title-icon-color: @rolling-stone;
@bottom-sheet-bg-color: @mirage;
@bottom-sheet-color: @white;
@bottom-sheet-divider-color: @limed-spruce;
@bottom-sheet-icon-color: @mandy;

// Pornstars
@pornstars-badge-bg-color: @bunker;

// Search empty
@search-empty-color: @white;

// Forgot password
@forgot-bg-color: @mirage;
@forgot-text-color: @white;
@forgot-icon-color: @mandy;
@forgot-input-bg-color: @bunker;
@forgot-error-color: @crail;

// TV
@tv-title-color: @white;
@tv-bg-color: @mirage;
@tv-icon-color: @brick-red;
@tv-input-bg-color: @bunker;

// Modals
@modal-inner-content-bg-color: @mirage;
@modal-title-color: @white;

// Report video modal
@report-video-item-bg-color: @bunker;
@report-video-item-color: @white;
@report-video-active-item-border-color: @mandy;
@report-video-item-dot-border-color: @limed-spruce;
@report-video-active-item-dot-border-color: @mandy;
@report-video-active-item-dot-bg-color: @mandy;

// Playlist modal
@playlist-modal-input-text-color: @white;
@playlist-modal-input-background-color: @bunker;
@playlist-modal-divider-color: @limed-spruce;
@playlist-modal-title-color: @white;
@playlist-modal-icon-color: @mandy;

// Input
@input-bg-color: @bunker;
@input-color: @white;
@label-text-color: @white;
@input-icon-color: @limed-spruce;
@form-error-color: @crail;
@form-description-color: @iron;

// Profile
@profile-details-color: @rolling-stone;
@profile-details-icon-color: @limed-spruce;
@profile-content-bg-color: @mirage;
@profile-secondary-bg-color: @bunker;
@profile-text-color: @white;
@profile-contrast-text-color: @mandy;
@profile-contrast-color: @brick-red;

// Notifications page
@notifications-results-text-color: @rolling-stone;
@notifications-icon-color: @mandy;
@notifications-notification-title-color: @white;
@notifications-notification-date-color: @rolling-stone;
@notifications-notification-subtext-color: @mandy;

// Subscriptions
@subscriptions-text-color: @white;
@subscriptions-content-contrast-color: @brick-red;
@subscriptions-text-contrast-color: @mandy;
@subscriptions-inactive-color: @limed-spruce;
@subscriptions-title-bg-color: @dark-tan;

// Messenger
@messenger-inbox-name-color: @mandy;
@messenger-inbox-date-color: @rolling-stone;
@messenger-inbox-text-color: @rolling-stone;
@messenger-inbox-unread-text-color: @white;
@messenger-header-border-color: @limed-spruce;
@messenger-header-background-color: @bunker;
@messenger-header-icon-color: @mandy;
@messenger-header-content-text-color: @mandy;
@messenger-message-name-color: @white;
@messenger-message-background-color: @mirage;
@messenger-message-date-color: @rolling-stone;
@messenger-message-other-name-color: @mandy;
@messenger-messagebar-background-color: @bunker;
@messenger-messagebar-border-color: @limed-spruce;

// Notification bubble
@notification-bubble-item-bg-color: @limed-spruce;
@notification-bubble-item-success-icon-color: @pistachio;

:root {
    // Common
    --divide-color: rgba(@white-rgb, 0.2);
    --title-color: @white;
    --title-icon-color: @brick-red;
    --text-color: @white;
    --link-color: @mandy;
    --title-subtext-color: @rolling-stone;
    --primary-btn-border-color: @mandy;
    --primary-btn-bordered-color: @white;
    --primary-btn-bordered-icon-color: @mandy;
    --content-highlight-bg-color: @mirage;
    --content-highlight-color: @white;
    --secondary-btn-text-color: @mandy;
    --primary-btn-bg-from-color: @bright-sun;
    --primary-btn-bg-to-color: @california;
    --primary-btn-hover-bg-from-color: @kournikova;
    --primary-btn-hover-bg-to-color: @yelloworange;
    --bordered-btn-hover-bg-color: @mandy;
    --misc-btn-bg-from-color: @brick-red;
    --misc-btn-bg-to-color: @brick-red;
    --primary-btn-color: @burnt-maroon;
    --misc-btn-color: @white;
    --textarea-bg-color: @bunker;
    --textarea-color: @iron;
    --success-color: @atlantis;
    --error-color: @crail;

    // 18+ overlay
    --eighteen-overlay-bg-color: @mirage;
    --verify-my-age-restricted-circle-color: @brick-red;

    // Header
    --header-bg-color: @dark-tan;
    --header-link-color: @your-pink;
    --header-link-bg-color: @bulgarian-rose;
    --header-tooltip-bg-color: @bunker;
    --header-tooltip-text-color: @white;
    --header-switches-color: @white;
    --header-gender-selector-icon-color: @mandy;

    // Header - searchbar
    --searchbar-bg-color: @burnt-maroon;
    --searchbar-dark-border-color: @heavy-metal;
    --searchbar-focus-text-color: @white;
    --searchbar-text-color: @white;
    --searchbar-text-color-rgb: @white-rgb;
    --searchbar-icon-color: @white;

    // Header - autosuggest
    --autosuggest-bg-color: @bunker;
    --autosuggest-bg-color-rgb: @bunker-rgb;
    --autosuggest-text-color: @rolling-stone;
    --autosuggest-text-highlight-color: @white;
    --autosuggest-text-active-bg-color: @mirage-d;
    --autosuggest-text-active-bg-color-rgb: @mirage-d-rgb;
    --autosuggest-text-active-color: @apricot;

    // Header - tooltip
    --tooltip-bg-color: @heavy-metal;
    --tooltip-text-color: @silver-chalice;

    // Footer
    --footer-bg-color: @mirage-d;
    --footer-nav-text-color: @apricot;
    --footer-color: @white;
    --footer-nav-link-color: @white;
    --footer-nav-icon-color: @mandy;
    --footer-highlighted-bg-color: @mirage;
    --footer-highlighted-text-color: @rolling-stone;
    --footer-highlighted-text-highlighted-color: @white;
    --footer-bottom-btn-bg-color: @bunker;
    --footer-bottom-btn-color: @white;
    --footer-bottom-btn-icon-color: @mandy;
    --footer-link-color: @iron;
    --footer-account-user-name-color: @mandy;
    --footer-bottom-btn-badge-bg-color: @brick-red;
    --footer-bottom-btn-badge-color: @white;
    --footer-benefits-text-color: @white;
    --footer-benefits-icon-color: @brick-red;
    --footer-login-separator-color: @limed-spruce;

    // Playlist
    --playlist-item-bg-color: @mirage;
    --playlist-length-background-color: @bunker;
    --playlist-input-bg-color: @bunker;
    --playlist-length-border-color: @mandy;
    --playlist-title-text-color: @mandy;
    --playlist-length-text-color: @white;
    --playlist-button-gradient-color-start: @bright-sun;
    --playlist-button-gradient-color-end: @california;
    --playlist-button-text-color: @burnt-maroon;
    --playlist-header-text-color: @apricot;

    // Video list
    --video-list-hd-bg-color: @black;
    --video-list-hd-color: @white;
    --video-list-user-color: @mandy;
    --video-list-stats-color: @rolling-stone;
    --video-list-name-color: @gray-chateau;
    --video-list-context-menu-trigger-color: @gray-chateau;
    --video-list-context-menu-trigger-active-color: @brick-red;
    --video-list-item-deleted-filter-color: @rolling-stone;
    --video-list-mp4-item-bg-color: @bunker;
    --video-list-video-badge-bg-color-rgba: rgba(@black, 0.6);
    --video-list-video-badge-bg-color: @black;
    --video-list-video-badge-color-rgba: rgba(@white, 0.8);
    --video-list-video-badge-color: @white;
    --video-list-info-text-color: @alto;

    // Popout menu
    --popout-menu-bg-color: @mirage;
    --popout-menu-color: @white;
    --popout-menu-icon-color: @gray-chateau;
    --popout-menu-divider-color-rgb: @bunker-rgb;

    // Channel
    --channel-text-color: @mandy;

    // Video page
    --video-toolbar-item-bg-color: @mirage;
    --video-toolbar-item-color: @rolling-stone;
    --video-toolbar-item-hover-color: @apricot;
    --video-toolbar-item-hover-background-color: @limed-spruce;
    --video-toolbar-icon-color: @gray-chateau;
    --video-toolbar-text-color: @white;
    --video-toolbar-quality-menu-bg-color: @mirage;
    --video-toolbar-quality-menu-border-color: @mirage-d;
    --video-toolbar-quality-menu-color: @gray-chateau;
    --video-toolbar-rate-text-color: @gray-chateau;
    --video-toolbar-separator-color: @limed-spruce;
    --video-toolbar-delete-bg-color: @tamarillo;
    --video-toolbar-delete-text-color: @white;
    --video-toolbar-active-text-color: @brick-red;
    --video-user-bg-color: @mirage;
    --video-user-color: @gray-chateau;
    --video-user-link-color: @mandy;
    --video-avatar-default-bg-color: @black;
    --video-playlist-video-count-color: @rolling-stone;
    --video-playlist-active-item-bg-color: @dark-tan;
    --video-thumbnail-bg-color: @black;
    --video-removed-video-text-color: @text;
    --video-thumbnail-bg-color-rgba: rgba(0, 0, 0, 0.4);
    --video-thumbnail-bg-color-rgb: @black-rgb;
    --video-thumbnail-play-hover-color: @gray-chateau;
    --video-play-button-color: @white;
    --video-pause-button-color: @white;
    --video-play-bg-color-rgba: rgba(0, 0, 0, 0.6);
    --video-thumbnail-text-color: @white;
    --video-details-channel-color: @apricot;
    --video-no-available-icon-color: @mandy;
    --video-tag-hover-chip-color: @apricot;
    --video-positions-skip-to-color: @apricot;
    --video-positions-time-color: @iron;
    --video-positions-title-color: @white;
    --video-positions-backdrop-color: @black;
    --video-positions-hover-color: @dark-tan;
    --video-progress-bar-seen: @cerise-red;
    --video-ui-progress-color: rgba(@white-rgb, 0.2);
    --video-progress-bar-left: rgba(@white-rgb, 0.3);
    --video-views-text-color: @white;
    --video-views-bg-color-rgba: rgba(0, 0, 0, 0.6);
    --video-time-color: @white;
    --video-scene-indicator: @white;
    --video-scene-border-color: @white;
    --video-scene-box-shadow-color-rgba: rgba(0, 0, 0, 0.25);
    --video-time-tooltip-bg-color: rgba(0, 0, 0, 0.6);
    --video-timeline-item-bg-color: rgba(0, 0, 0, 0.6);
    --video-settings-item-border-color: @limed-spruce;
    --video-spinner-color: #e9657b;
    --video-spinner-base-color: @limed-spruce;

    // Messagebar
    --messagebar-bg-color: @mirage;
    --messagebar-textarea-bg-color: @bunker;
    --messagebar-textarea-text-color: @gray-chateau;

    // Tags
    --tag-bg-color: @mirage-d;
    --tag-color: @iron;
    --tag-active-bg-color: @brick-red;
    --tag-active-color: @white;
    --tag-hover-text-color: @apricot;
    --tag-chip-hover-background-color: @brick-red;
    --tag-chip-hover-text-color: @white;

    // Comments
    --comments-wrapper-bg-color: @mirage-d;
    --comments-sorting-link-color: @gray-chateau;
    --comments-sorting-link-active-color: @mandy;
    --comments-sorting-position-color: @mandy;
    --comments-name-color: @mandy;
    --comments-time-color: @gray-chateau;
    --comments-text-color: @white;
    --comments-buttons-color: @gray-chateau;
    --comments-link-color: @mandy;
    --comments-form-bg-color: @mirage;

    // Snip
    --snip-color: @rolling-stone;
    --snip-highlight-color: @iron;

    // Signup
    --signup-features-bg-color: @bunker;
    --signup-button-text-color: @white;
    --signup-feature-icon-color: @mandy;
    --signup-feature-text-color: @iron;
    --signup-title-text-color: @mandy;
    --signup-icon-color: @mandy;

    // Trending
    --trending-title-color: @mandy;
    --trending-live-bg-color-rgb: @ebony-clay-rgb;
    --trending-live-led-color: @jungle-green;

    // Profile (channel, pornstar, user)
    --profile-top-name-color: @white;
    --profile-top-details-color: @rolling-stone;
    --profile-top-details-info-color: @iron;
    --profile-top-details-bio-color: @silver-chalice;
    --profile-icon-color: @mandy;
    --profile-success-color: @pistachio;

    // Channel page
    --channel-page-description-color: @gray-chateau;

    // Filter
    --filter-item-color: @mandy;
    --filter-item-icon-color: @limed-spruce;
    --filter-active-item-border-color: @brick-red;
    --filter-active-item-color: @mandy;

    // Bottom sheet
    --bottom-sheet-overlay-bg-color: @bunker;
    --bottom-sheet-title-color: @rolling-stone;
    --bottom-sheet-title-icon-color: @rolling-stone;
    --bottom-sheet-bg-color: @mirage;
    --bottom-sheet-color: @white;
    --bottom-sheet-divider-color: @limed-spruce;
    --bottom-sheet-icon-color: @mandy;

    // Pagination
    --pagination-prev-next-color: @mandy;
    --pagination-item-bg-color: @mirage;
    --pagination-item-bg-hover-color: @limed-spruce;
    --pagination-item-color: @white;
    --pagination-item-active-bg-color: @brick-red;
    --pagination-item-active-color: @white;
    --pagination-dots-color: @white;
    --pagination-info-color: @rolling-stone;

    // Search empty
    --search-empty-color: @white;

    // Forgot password
    --forgot-bg-color: @mirage;
    --forgot-text-color: @white;
    --forgot-icon-color: @mandy;
    --forgot-input-bg-color: @bunker;
    --forgot-error-color: @crail;

    // TV
    --tv-title-color: @white;
    --tv-bg-color: @mirage;
    --tv-icon-color: @brick-red;
    --tv-input-bg-color: @bunker;

    // Tags Page
    --tags-text-color: @mandy;
    --tags-desktop-text-color: @apricot;
    --tags-input-bg-color: @mirage;
    --tags-input-text-color: @white;
    --tags-input-icon-color: @rolling-stone;
    --tags-bg-color: @bunker;

    // Buttons
    --go-button-bg-color: @dark-tan;
    --go-button-color: @white;
    --go-button-icon-color: @mandy;
    --ad-button-bg-color: rgba(0, 0, 0, 0.6);

    // Modals
    --modal-background-color: @bunker-rgb;
    --modal-inner-content-bg-color: @mirage;
    --modal-title-color: @white;
    --modal-text-color: @white;
    --modal-title-text-color: @white;
    --modal-close-button-color: @white;

    // Report video modal
    --report-video-item-bg-color: @bunker;
    --report-video-item-color: @white;
    --report-video-active-item-border-color: @mandy;
    --report-video-item-dot-border-color: @limed-spruce;
    --report-video-active-item-dot-border-color: @mandy;
    --report-video-active-item-dot-bg-color: @mandy;

    // Playlist modal
    --playlist-modal-input-text-color: @white;
    --playlist-modal-input-background-color: @bunker;
    --playlist-modal-divider-color: @limed-spruce;
    --playlist-modal-title-color: @white;
    --playlist-modal-icon-color: @mandy;

    // Empty content
    --empty-content-icon-color: @mandy;

    // Radio
    --radio-item-bg-color: @bunker;
    --radio-item-color: @white;
    --radio-hover-item-text-color: @apricot;
    --radio-active-item-border-color: @mandy;
    --radio-item-dot-border-color: @limed-spruce;
    --radio-active-item-dot-border-color: @mandy;
    --radio-active-item-dot-bg-color: @mandy;

    // Checkbox
    --checkbox-border-color: @mandy;
    --checkbox-background-color: @bunker;

    // Input
    --input-bg-color: @bunker;
    --input-color: @white;
    --label-text-color: @white;
    --input-icon-color: @limed-spruce;
    --input-outline-color: @mandy;
    --form-error-color: @crail;
    --form-description-color: @iron;

    // Profile
    --profile-details-color: @rolling-stone;
    --profile-details-icon-color: @limed-spruce;
    --profile-content-bg-color: @mirage;
    --profile-secondary-bg-color: @bunker;
    --profile-text-color: @white;
    --profile-contrast-text-color: @mandy;
    --profile-contrast-color: @brick-red;

    // Notification card
    --notification-card-bg-color: @limed-spruce;
    --notification-card-success-color: @atlantis;
    --notification-card-error-color: @mandy;
    --notification-card-info-color: @surfaceinfo;
    --notification-card-warning-color: @yelloworange;

    // Notifications page
    --notifications-results-text-color: @rolling-stone;
    --notifications-icon-color: @mandy;
    --notifications-notification-title-color: @white;
    --notifications-notification-date-color: @rolling-stone;
    --notifications-notification-subtext-color: @mandy;
    --notifications-delete-button-icon-color: @apricot;
    --notifications-delete-text-icon-color: @white;

    // Sidebar nav
    --sidebar-nav-item-bg-color: @mirage;
    --sidebar-nav-item-tablet-bg-color: @limed-spruce;
    --sidebar-nav-item-border-color: @limed-spruce;
    --sidebar-nav-item-color: @white;
    --sidebar-nav-item-hover-bg-color: @limed-spruce;
    --sidebar-nav-item-active-color: @mandy;
    --sidebar-nav-item-active--hover-color: @white;
    --sidebar-nav-item-icon-color: @mandy;
    --sidebar-nav-item-warning-icon-color: @yelloworange;

    // Subscriptions
    --subscriptions-text-color: @white;
    --subscriptions-content-contrast-color: @brick-red;
    --subscriptions-text-contrast-color: @mandy;
    --subscriptions-inactive-color: @limed-spruce;
    --subscriptions-title-bg-color: @dark-tan;

    // Messenger
    --messenger-inbox-name-color: @mandy;
    --messenger-inbox-date-color: @rolling-stone;
    --messenger-inbox-text-color: @rolling-stone;
    --messenger-inbox-unread-text-color: @white;
    --messenger-header-border-color: @limed-spruce;
    --messenger-header-background-color: @bunker;
    --messenger-header-icon-color: @mandy;
    --messenger-header-content-text-color: @mandy;
    --messenger-message-name-color: @white;
    --messenger-message-background-color: @mirage;
    --messenger-message-date-color: @rolling-stone;
    --messenger-message-other-name-color: @mandy;
    --messenger-messagebar-background-color: @mirage;
    --messenger-messagebar-border-color: @limed-spruce;
    --messenger-messagebar-text-color: @rolling-stone;

    // Forms
    --form-background-color: @mirage;
    --form-error-border-color: @crail;
    --form-auth-title-text-color: @white;
    --form-auth-subtitle-text-color: @iron;
    --form-auth-creator-block-background-color: @ebony-clay;
    --form-required-mark-color: @mandy;
    --form-icon-color: @mandy;

    // Show more button
    --show-more-button-outline-color: @cornflower-blueapprox;
    --show-more-button-background-color: @bunker;
    --show-more-button-color: @white;

    // Horizontal scroller
    --scroller-icon-color: @white;
    --scroller-gradient-bg-color: @bunker;

    // Embed
    --embed-textarea-bg-color: @mirage;
    --embed-textarea-color: @rolling-stone;

    // File upload
    --upload-no-file-selected-text-color: @rolling-stone;
    --upload-major-storage-text-color: @iron;
    --upload-inactive-option-border-color: @limed-spruce;
    --upload-star-color: @mandy;
    --upload-default-option: @gray-chateau;
    --upload-button-text-color: @bulgarian-rose;
    --upload-progress-background-color: @bunker;
    --upload-progress-color: @mandy;
    --upload-button-gradient-color-start: @bright-sun;
    --upload-button-gradient-color-end: @california;
    --upload-url-text-color: @title-color;
    --upload-suggestion-background-active: @mirage-d;
    --upload-suggestion-matched-word-active: @apricot;
    --upload-suggestion-text-color: @rolling-stone;
    --upload-suggestion-background: @mirage-d;
    --upload-suggestion-matched-word: @white;

    // User panel
    --user-panel-item-bg-color: @mirage;
    --user-panel-item-bg-hover-color: @limed-spruce;
    --user-panel-separator-color: @limed-spruce;
    --user-panel-date-color: @rolling-stone;
    --user-panel-name-text-color: @white;
    --user-panel-results: @rolling-stone;

    // Dropdown
    --basic-dropdown-shadow-color: rgba(@black-rgb, 0.25);
    --basic-dropdown-bg-color: rgba(@bunker-rgb, 0.95);
    --basic-dropdown-title-color: @white;
    --basic-dropdown-divider-bg-color: rgba(@white-rgb, 0.08);
    --basic-dropdown-item-color: @white;
    --basic-dropdown-item-hover-bg-color: rgba(@limed-spruce-rgb, 0.4);
    --basic-dropdown-item-hover-color: @apricot;

    // Info pages
    --partnership-title-color: @apricot;
    --partnership-subtitle-color: @iron;
    --partnership-text-color: @white;
    --partnership-steps-bg-color: @limed-spruce;

    // creator registration
    --creator-page-border-color: @limed-spruce;
    --creator-required-star-color: @mandy;
    --creator-link-color: @apricot;
    --creator-main-content-subtitle-color: @rolling-stone;
    --creator-identify_subtext-color: @iron;
    --creator-icon-color: @mandy;

    // Creator
    --country-ban-bg-color: @bunker;
    --country-ban-text-color: @iron;

    // Prohibited search
    --search-prohibited-warning-icon-color: @brick-red;

    &.desktop {
        // Common
        --title-icon-color: @brick-red;
        --link-color: @apricot;

        // signup
        --signup-icon-color: @brick-red;

        // Header
        --header-link-color: @white;

        // Header nav and action
        --header-nav-bg-color: @mirage-d;
        --header-nav-item-link-color: @white;
        --header-nav-item-icon-color: @gray-chateau;
        --header-nav-item-active-icon-color: @mandy;
        --header-nav-item-active-border-color: @brick-red;
        --header-nav-item-caret-icon-color: @gray-chateau;
        --header-action-item-link-color: @white;
        --header-action-item-upload-icon-color: @supernova;
        --header-nav-item-icon-highlighted-color: @mandy;

        // Header nav submenu
        --header-nav-submenu-bg-color: @mirage-d;
        --header-nav-submenu-top-item-color: @white;
        --header-nav-submenu-top-item-bg-color: @bunker;
        --header-nav-submenu-top-item-icon-color: @mandy;
        --header-nav-submenu-top-item-hover-bg-color: @limed-spruce;

        // Video
        --video-user-link-color: @apricot;

        // Filter
        --filter-item-color: @apricot;
        --filter-active-item-border-color: @brick-red;
        --filter-active-item-color: @apricot;

        // Popout menu
        --popout-menu-hover-color: @apricot;

        // Comments
        --comments-link-color: @apricot;
        --comments-name-color: @apricot;
        --comments-sorting-link-active-color: @apricot;
        --comments-sorting-position-color: @apricot;

        // Channel
        --channel-text-color: @apricot;
        --channel-page-description-color: @rolling-stone;
        --channel-page-visit-cta-icon-color: @mandy;

        // Messenger
        --messenger-inbox-name-color: @apricot;
        --messenger-conversation-header-bg-color: @bunker;

        // Playlists
        --playlist-title-text-color: @apricot;

        // Video list
        --video-list-name-color: @alto;
    }
}
