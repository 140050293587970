.video-list {
    --video-list-columns: 2;
    clear: both;
    overflow: hidden;
    gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(var(--video-list-columns), 1fr);
    grid-auto-flow: dense;

    &.one-col {
        --video-list-columns: 1;
    }

    &.two-col {
        --video-list-columns: 2;
    }

    &.hide-last-row {
        &.two-col {
            .video-item {
                .hide-last-row-items(2);
            }
        }
    }

    .video-item-deleted {
        .opacity(0.35);
        filter: var(--video-list-item-deleted-filter-color); /* IE6-9 */
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    }

    .video-inline-ads,
    .video-item {
        position: relative;
        display: block;
        zoom: 1;
        overflow: hidden;
        width: 100%;
    }

    .video-item {
        .thumb {
            display: block;
            width: 100%;
            position: relative;
            background-color: var(--video-list-hd-bg-color);
            .rounded(0.25rem);
            overflow: hidden;

            * {
                cursor: pointer;
            }

            .click_layer {
                position: absolute;
                z-index: 10;
                cursor: pointer;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            picture {
                height: 0;
                padding: 56.25% 0 0 0;
                display: block;
                width: 100%;
                overflow: hidden;
                position: relative;
                z-index: 1;

                img.cover {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    left: 0;
                    top: 0;
                    z-index: 1;
                }
            }

            > #mp4t_video {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: auto;
                .rounded(5px);
                cursor: pointer;
                z-index: 5;
                background-color: var(--video-list-mp4-item-bg-color);
                opacity: 0;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    left: 0;
                    top: 0;
                    .rounded(5px);
                }
            }

            .video-badge {
                position: absolute;
                z-index: 10;
                background-color: var(--video-list-video-badge-bg-color-rgba);
                color: var(--video-list-video-badge-color-rgba);
                padding: 0 0.25rem;
                line-height: 1.125rem;
                font-size: 0.75rem;
                .rounded(0.25rem);
                opacity: 0.75;
            }

            .h {
                left: 0.5rem;
                top: 0.5rem;
                font-weight: 700;
                text-transform: uppercase;
            }

            .l {
                top: 0.5rem;
                right: 0.5rem;
            }
        }

        .video-info {
            margin-top: 0.25rem;

            .uploader-and-stats-wrapper {
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;

                .user {
                    display: block;

                    a {
                        display: block;
                        color: var(--video-list-user-color);
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                    }
                }

                .stats {
                    margin-right: 0;
                    font-size: 0.75rem;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    gap: 0.5rem;
                    z-index: 10;
                    position: relative;
                    color: var(--video-list-stats-color);
                    overflow: hidden;

                    > span {
                        display: flex;
                        align-items: center;
                        flex-shrink: 0;
                        gap: 0.125rem;

                        .icon {
                            width: 0.75rem;
                            height: 0.75rem;
                            font-size: 0.625rem;
                            .flex-display();
                            .align-items(center);
                            .justify-content(center);

                            .i_svg {
                                display: block;
                                top: 0;
                            }
                        }
                    }
                }

                .show-items-menu-trigger {
                    cursor: pointer;
                    width: 1.5rem;
                    height: 1.5rem;
                    color: var(--video-list-context-menu-trigger-color);
                    .flex-display();
                    .flex-shrink(0);
                    .align-items(center);
                    .justify-content(center);

                    &.active {
                        color: var(--video-list-context-menu-trigger-active-color);
                    }

                    .i_svg {
                        display: block;
                        top: 0;
                        font-size: 1rem;
                    }
                }
            }

            .name-and-menu-wrapper {
                .flex-display();
                .align-items(flex-start);
                gap: 0.5rem;

                p {
                    line-height: 1.25rem;
                    font-size: 0.875rem;
                    text-transform: capitalize;
                    color: var(--video-list-name-color);
                    font-weight: 400;
                    width: 100%;

                    strong {
                        font-weight: inherit;
                        color: inherit;
                    }

                    a {
                        color: inherit;
                    }
                }
            }
        }

        .menu {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            z-index: 100;
            width: 20%;
            height: 20%;
            text-align: right;
            padding: 1px;
            font-size: 1.5em;

            .i_svg {
                display: inline-block;
                background-color: fade(@back-d2, 70);
                padding: 3px;
                text-align: center;
                .rounded(3px);
                top: 0;
            }
        }

        .ft-button-bordered {
            padding: 0.3125rem;

            .i_svg {
                width: 0.5rem;
            }
        }

        .ui_edit {
            background-color: @back-d2;
            border: 1px solid @back-d4;
            padding: 5px 0;
            font-size: 0.9em;
            display: block;
            color: @text;
            width: 80%;
            text-align: center;
            margin: 0 auto 4px auto;
            position: relative;

            svg {
                fill: @text;
            }
        }

        .mod_delete {
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: 1000;
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background-color: @front-d4;
            color: @text-l1;
            .rounded(5px);
            border: 1px solid @back-d4;
            cursor: pointer;
        }
    }

    .video-inline-ads {
        min-width: 300px;
        min-height: 250px;
        grid-column-start: 1;
        grid-column-end: -1;
        display: flex;
        justify-content: center;
        align-items: center;

        &.s-ad {
            padding: 5px 0;
            border: 1px solid @back;
            border-right: 0;
            border-left: 0;
        }
    }

    &.livecamv2-rotate {
        --video-list-columns: 2;
    }

    .live-item {
        overflow: hidden;
        box-sizing: border-box;

        .click_layer {
            position: absolute;
            z-index: 1000;
            cursor: pointer;
            width: 100%;
            height: 100%;
        }

        .thumb {
            &.is_live {
                height: 100%;

                img.cover {
                    object-fit: cover;
                }
            }

            > #mp4t_live {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: auto;
                .rounded(0);
                cursor: pointer;
                z-index: 5;
                background-color: @back-d2;
                display: none;
                text-align: center;

                video {
                    object-fit: cover;
                    object-position: center center;
                    width: 100%;
                    height: auto;
                }
            }

            .i-status {
                padding: 4px;
                position: absolute;
                top: 2%;
                right: 2%;
                overflow: hidden;
                background-color: darken(#27ae60, 15%);
                color: @text-l1;
                font-size: 0.65em;
                text-align: center;
                .rounded(3px);
                z-index: 10;
                display: block;
                .opacity(0.8);
            }

            .i-partychat {
                background-color: darken(#8e44ad, 15%);
            }

            .i-goldshow {
                background-color: darken(#f1c40f, 0%);
                color: @back-d4;
            }

            .i-modeloffline,
            .i-modeloffline-ajax {
                background-color: @back-d4;
            }

            .i-join-private {
                left: 0;
                right: 0;
                background-color: @back-d2;
                margin: 30% 10% 0 10%;
                font-size: 1em;
                display: none;
            }

            .i-modeloffline-ajax {
                display: none;
            }

            p.tl {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                color: var(--text-color);
                height: 1.75rem;
                bottom: 0;
                line-height: 1.25rem;
                z-index: 10;
                position: absolute;
                padding: 0 0.25rem;
                width: 100%;
                overflow: hidden;
                background-color: rgba(var(--trending-live-bg-color-rgb), 0.7);
                font-size: 0.875rem;

                .ln,
                .lo,
                .ll,
                .lh {
                    padding: 0 0.25rem;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                .ln {
                    flex-grow: 1;
                    flex-shrink: 1;
                    text-transform: uppercase;
                    font-weight: 400;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .lh {
                    height: 0.75rem;
                    width: 1.5rem;
                    border-radius: 0.25rem;
                }

                @keyframes liveStatusColor {
                    from {
                        background-color: #2ecc71;
                    }
                    to {
                        background-color: #27ae60;
                    }
                }

                .lo {
                    width: 0.625rem;
                    height: 0.625rem;
                    border-radius: 1.25rem;
                    background-color: var(--trending-live-led-color);
                    margin-right: 0.375rem;
                    .drop-shadow(@x-axis: 0, @y-axis: 2px, @blur: 1px, @alpha: 0.8);
                    animation: liveStatusColor 1s ease-in-out infinite alternate;
                }
            }
        }
    }

    .video-item.is_rotating {
        z-index: 100;

        @keyframes mediaPreviewLoading {
            from {
                opacity: 1;
            }
            to {
                opacity: 0.8;
            }
        }

        .thumb {
            .i-join-private {
                display: block !important;
            }

            .i-loading {
                display: block;
            }

            .i-status {
                display: none;
            }

            picture img.cover {
                animation: mediaPreviewLoading 0.5s ease-in-out infinite alternate;
            }
        }
    }
}

@media screen and (min-width: 568px) {
    .video-list {
        &.livecamv2-rotate {
            --video-list-columns: 4;
        }
    }
}

html.desktop {
    .video-list {
        row-gap: 1rem;
        --video-list-columns: 4;

        &.four-col {
            --video-list-columns: 4;
        }

        &.six-col {
            --video-list-columns: 6;

            &.with-ads {
                .video-item {
                    .hide-last-row-items(3);
                }
            }
        }

        &.eight-col {
            --video-list-columns: 8;

            &.with-ads {
                .video-item {
                    .hide-last-row-items(3);
                }
            }
        }

        &.hide-last-row {
            &.four-col:not(.with-ads) {
                .video-item {
                    .hide-last-row-items(4);
                }
            }

            &.six-col:not(.with-ads) {
                .video-item {
                    .hide-last-row-items(6);
                }
            }

            &.eight-col:not(.with-ads) {
                .video-item {
                    .hide-last-row-items(8);
                }
            }
        }

        .video-item {
            .video-info {
                .name-and-menu-wrapper {
                    color: var(--video-list-name-color);

                    p {
                        font-size: 0.75rem;
                    }
                }
            }

            .thumb {
                .video-badge {
                    background-color: var(--video-list-video-badge-bg-color);
                    color: var(--video-list-video-badge-color);
                    opacity: 0.75;
                }
            }
        }

        .similar_native_ad {
            .video-list {
                width: 100%;
                padding: 8px 0;
                display: block;
                clear: both;
                overflow: hidden;
                position: relative;

                a {
                    picture {
                        img.cover {
                            display: block;
                            width: 100%;
                            left: 0;
                            top: 0;
                            margin: auto;
                            .rounded(5px);
                            z-index: 1;
                            max-height: 320px;
                        }
                    }

                    .title {
                        display: block;
                        color: @text;
                        padding: 3px 0 5px 0;
                    }

                    .btn {
                        padding: 6px 6px;
                        background-color: @front-d4;
                        color: @text;
                        .rounded(5px);
                        position: absolute;
                        bottom: 40px;
                        right: 3px;
                        text-transform: uppercase;
                        font-size: 0.85em;

                        .i_svg {
                            fill: @front-l4;
                        }
                    }

                    &:hover .btn {
                        background-color: @front-d3;
                    }
                }

                .beacon {
                    display: none;
                }
            }
        }
    }

    .lv_cm_int_come_on {
        .space-vertical();
        .button-wrapper {
            display: none;
        }
    }

    .live-models {
        .video-list {
            --video-list-columns: 4;
        }

        &.one-row-list {
            .video-list {
                .video-item {
                    &:nth-child(n + 5) {
                        display: none;
                    }
                }
            }
        }

        &.two-row-list {
            .video-list {
                .video-item {
                    &:nth-child(n + 9) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media all and (min-width: @breakpoint-md) {
    html.desktop {
        .video-list {
            &.four-col {
                .video-inline-ads {
                    grid-column: 3 / span 2;
                    grid-row: auto / span 2;
                }
            }
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        .video-list {
            &.six-col {
                .video-inline-ads {
                    grid-column: 5 / span 2;
                    grid-row: auto / span 2;
                }
            }

            &.eight-col {
                .video-inline-ads {
                    grid-column: 6 / span 3;
                    grid-row: auto / span 2;
                }
            }

            .video-item {
                .video-info {
                    .uploader-and-stats-wrapper {
                        .stats {
                            font-size: 0.6875rem;
                        }
                    }
                }
            }
        }

        .live-models {
            .video-list {
                --video-list-columns: 5;
            }

            &.one-row-list {
                .video-list {
                    .video-item {
                        &:nth-child(n + 5) {
                            display: block;
                        }
                    }
                }
            }

            &.two-row-list {
                .video-list {
                    .video-item {
                        &:nth-child(n + 9) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media all and (min-width: 1366px) {
    html.desktop {
        .video-list {
            &.four-col {
                .video-inline-ads {
                    grid-column: 4 / span 1;
                    grid-row: auto / span 1;
                }

                &.with-ads {
                    .video-item {
                        .hide-last-row-items(2);
                    }
                }
            }
        }
    }
}

@media all and (min-width: @breakpoint-2xl) {
    html.desktop {
        .video-list {
            &.eight-col {
                .video-inline-ads {
                    grid-column: 7 / span 2;
                    grid-row: auto / span 2;
                }

                &.with-ads {
                    .video-item {
                        display: initial !important;
                    }
                }
            }
        }
    }
}

@media all and (min-width: @breakpoint-4xl) {
    html.desktop {
        .video-list {
            &.six-col {
                .video-inline-ads {
                    grid-column: 6 / span 1;
                    grid-row: auto / span 1;
                }

                &.with-ads {
                    .video-item {
                        .hide-last-row-items(6);
                    }
                }
            }
        }
    }
}
