.nav_quick_top {
    .space-vertical(0.5rem);
    white-space: nowrap;
    text-transform: capitalize;
    position: relative;

    .order,
    .filter {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        a {
            display: flex;
            color: var(--filter-item-color);

            .i_svg {
                top: 0;
            }
        }
    }

    .order {
        gap: 0.75rem;
        align-items: center;

        &:has(.grid-layout-change-wrapper) {
            margin-right: 5rem;
        }

        .icon {
            display: flex;
            align-items: center;
            width: 1rem;
            height: 1rem;
            padding: 0.1875rem;
            margin-bottom: 0.0625rem;
            margin-right: -0.5rem;

            .i_svg {
                width: 100%;
                height: 100%;
                display: block;
                top: 0;
                color: var(--filter-item-icon-color);
            }
        }

        a {
            border-bottom: 1px solid transparent;
            font-size: 0.8125rem;
            line-height: 1.5rem;

            &.active {
                border-bottom-color: var(--filter-active-item-border-color);
            }

            .i_svg {
                width: 1.5rem;
                height: 1.5rem;
                color: var(--filter-item-icon-color);
                margin-right: 0.25rem;
                padding: 0.1875rem;

                &.i_new-ui-caret-up,
                &.i_new-ui-caret-down {
                    padding: 0.5rem;
                }
            }
        }

        .grid-layout-change-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            height: 1.5rem;
        }
    }

    .filter {
        gap: 1rem;

        &:has(> .ed) {
            margin-right: 5rem;
        }

        li:not(.ed) {
            > a {
                font-size: 0.8125rem;
                line-height: 1.25rem;
                border-bottom: 1px solid transparent;

                &.active,
                &:active {
                    color: var(--filter-active-item-color);
                }

                &.keyword {
                    padding-bottom: 0.25rem;

                    &.active {
                        border-bottom-color: var(--filter-active-item-border-color);
                    }
                }
            }

            .i_svg {
                width: 1rem;
                height: 1rem;
                color: var(--filter-item-icon-color);
                margin-right: 0.25rem;
                margin-top: 0.1rem;

                &.i_new-ui-double-caret-vertical {
                    padding: 0.1875rem;
                }

                &.i_new-ui-home,
                &.i_new-ui-visible-outlined,
                &.i_new-ui-time,
                &.i_new-ui-calendar-month {
                    padding: 0.0625rem;
                }
            }

            > div {
                display: none;

                a {
                    display: block;
                }
            }
        }
    }

    .ed {
        position: absolute;
        right: 0;
        top: 0;
        height: 1.25rem;
        display: flex;
        align-items: center;
        .space-horizontal(0.75rem);

        .edition {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        img {
            height: 14px;
            border-radius: 0.1875rem;
        }
    }
}

.profile_page {
    .nav_quick_top {
        margin-bottom: 1rem;
    }
}

html.desktop {
    .nav_quick_top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .order,
        .filter {
            gap: 1rem;
            overflow: visible;

            a {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                font-size: 0.875rem;
                line-height: 1.5rem;
                align-items: center;

                &:hover {
                    border-bottom-color: var(--filter-active-item-border-color);
                }
            }
        }

        .order {
            order: 2;

            .icon {
                padding: 0;
                margin-right: 0;
            }

            a {
                .i_svg {
                    &.i_new-ui-caret-down,
                    &.i_new-ui-caret-up {
                        padding: 0.375rem;
                    }
                }
            }
        }

        .filter {
            order: 3;

            li {
                &:not(.ed) {
                    position: relative;

                    > a {
                        font-size: 0.875rem;

                        &:hover {
                            border-bottom-color: var(--filter-active-item-border-color);
                        }
                    }

                    .i_svg {
                        margin-top: 0;
                        width: 1.125rem;
                        height: 1.125rem;

                        &.i_new-ui-home,
                        &.i_new-ui-visible-outlined,
                        &.i_new-ui-time,
                        &.i_new-ui-calendar-month {
                            padding: 0;
                        }
                    }

                    .opt {
                        min-width: 9.375rem;
                        max-width: 15.625rem;
                        position: absolute;
                        left: 0;
                        top: 100%;
                        background-color: var(--popout-menu-bg-color);
                        z-index: 101;
                        display: block;
                        border-radius: 0.5rem;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.2s ease 0.3s;

                        a {
                            display: block;
                            padding: 0.5rem 0.75rem;
                            cursor: pointer;
                            color: var(--popout-menu-color);
                            font-size: 0.875rem;
                            line-height: 1.25rem;
                            border-top: 1px solid var(--popout-menu-divider-color);
                            .defaulthover;

                            &:hover {
                                color: var(--popout-menu-hover-color);
                            }

                            &:first-child {
                                border-top: none;
                            }
                        }
                    }

                    &:hover {
                        .opt {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .order + .filter {
            margin-left: 1rem;
        }

        .space-vertical(0);

        .horizontal_scroll_container {
            margin-bottom: 1rem;
            margin-right: 0;
            flex-grow: 1;
            order: 1;
            width: 100%;
        }
    }

    @media all and (min-width: @breakpoint-xl) {
        .nav_quick_top {
            flex-wrap: nowrap;

            .order {
                order: 1;
            }

            .filter {
                order: 2;
            }

            .horizontal_scroll_container {
                margin-left: 1.5rem;
                margin-bottom: 0;
                order: 3;
                width: auto;
            }
        }
    }
}
