// main: style.less

#sidebar {
  position: fixed;
  display: block;
  width: 200px;
  height: 100vh;
  background-color: @back-d3;
  border-right: 1px solid @back-d4;
  top: 0;
  left: 0;

  ul.main {
    margin: 0;

    li {
      display: block;

      a {
        display: block;
        padding: 8px 5px;

        font-size: 1.2em;
        color: @text;
        border-bottom: 1px solid @back-d1;
        border-top: 1px solid #000;

        svg {
          fill: @front;
        }
      }
    }

  }
}
