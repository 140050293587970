// main:style.less

#browse {
    div.top {
        background-color: @back-d3;
        padding: 5px;
        height: 41px;
        z-index: 1000;
        margin-bottom: 5px;
    }

    div.nav_left {
        width: 210px;
        float: left;
        overflow: hidden;
        padding-right: 5px;
        margin-right: 10px;
        border-right: 1px solid rgba(0, 0, 0, 0.3);
    }

    div.results_search {
        margin-left: 200px;
        overflow: hidden;
        margin-top: 5px;
    }

    div.pornstar_search {
        width: 150px;
        margin-bottom: 10px;
        text-align: center;

        a.image {
            display: block;
            position: relative;
            color: @front-l4;

            img {
                width: 100%;
                .rounded(5px);
            }

            span {
                position: absolute;
                display: inline-block;
                padding: 5px;
                background-color: @back-d4;
                color: @text;
                .opacity(0.8);
                .rounded(5px);
                font-size: 0.8em;

                &.views {
                    left: 2px;
                    bottom: 25px;
                }

                &.videos {
                    right: 2px;
                    bottom: 25px;
                }
            }
        }

        a.title {
            display: block;
            text-align: center;
            color: @front-l4;
            font-size: 1.3em;
            padding: 5px 0;
        }
    }

    div.categories {
        overflow: hidden;
        margin-bottom: 30px;
        a {
            width: 16%;
            height: 150px;
            float: left;
            font-size: 2.2em;
            position: relative;
            color: @text-l1;
            display: block;
            overflow: hidden;
            margin: 0.2%;
            .rounded(10px);
            .drop-shadow(@x-axis: 0, @y-axis: 2px, @blur: 1px, @alpha: 0.6);
            .text-shadow(@x-axis: 0, @y-axis: 2px, @blur: 2px, @alpha: 1);

            img {
                width: 100%;
                position: absolute;
            }

            span {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 100%;
                background-color: rgba(0, 0, 0, 0.8);
                vertical-align: middle;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover {
                span {
                    background-color: rgba(0, 0, 0, 0.9);
                    color: @front-l4;
                }
            }
        }
    }

    ul.keyword_list {
        margin: 20px;
        li {
            display: inline-block;

            a {
                display: block;
                font-size: 1.2em;
                color: @front-l4;
                padding: 4px 8px;
                border: 1px solid @back;
                .rounded(5px);
                margin: 2px;

                &:hover {
                    background-color: @front-d4;
                    color: @text;
                }
            }
        }
    }
}

#search_empty {
    background-color: @back-d3;
    border: 1px solid @back-d4;
    .drop-shadow();
    padding: 5px;
    text-align: center;
    font-size: 1.3em;
    line-height: 2em;
    color: @text-l1;

    .hope {
        color: @front-l4;
    }

    svg {
        fill: @front;
    }
}

#search_empty {
    background-color: @back-d3;
    border: 1px solid @back-d4;
    .drop-shadow();
    padding: 5px;
    text-align: center;
    font-size: 1.3em;
    line-height: 2em;
    color: @text-l1;

    .hope {
        color: @front-l4;
    }

    svg {
        fill: @front;
    }
}
