// main:style.less

.messenger {
    .inbox_card {
        width: 100%;

        .avatar_container {
            max-width: 3.75rem;
            .flex-shrink(0);

            img {
                object-fit: cover;
                margin-right: 0;
            }
        }

        .details {
            .flex-display();
            .flex-direction(column);
            .flex(1);
            font-weight: 400;
            line-height: 1.25rem;

            .top_section {
                .flex-display();
                .align-items(center);
                .align-self(flex-start);
                width: 100%;

                .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: @messenger-inbox-name-color;
                    font-size: 0.875rem;
                }

                .date {
                    margin-left: auto;
                    .flex-shrink(0);
                    font-size: 0.8125rem;
                    color: @messenger-inbox-date-color;
                }
            }

            .text {
                padding-right: 0.3125rem;
                line-break: anywhere;
                font-size: 0.8125rem;
                color: @messenger-inbox-text-color;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;

                &.unread {
                    color: @messenger-inbox-unread-text-color;
                }
            }
        }
    }

    &#message_page {
        .conversation_header {
            display: none;
        }

        .message {
            padding: 0;
            .flex-display();
            .flex-direction(row-reverse);
            text-align: right;

            .avatar {
                .align-self(center);
                width: 2rem;
                height: 2rem;
            }

            .message_content {
                .flex-display();
                .flex-direction(column);
                .flex-basis(fit-content);
                max-width: 70%;
                font-weight: 400;

                .name {
                    font-size: 0.75rem;
                    line-height: 1.125rem;
                    color: @messenger-message-name-color;
                }

                .message_text {
                    background-color: @messenger-message-background-color;
                    .rounded(0.25rem);
                    padding: 0.75rem;
                    overflow-wrap: break-word;
                }

                .date {
                    font-size: 0.6875rem;
                    line-height: 0.875rem;
                    color: @messenger-message-date-color;
                }
            }

            &.other {
                .flex-direction(row);
                text-align: left;

                .name {
                    color: @messenger-message-other-name-color;
                }
            }
        }

        .messagebar {
            .flex-display();
            .flex-direction(row);
            position: fixed;
            bottom: 3rem;
            width: 100%;
            background-color: @messenger-messagebar-background-color;
            padding: 1rem;
            height: auto;
            border-top: 0.0625rem solid @messenger-messagebar-border-color;

            textarea {
                .align-items(center);
                font-size: @input-font-size;
                line-height: 1.25rem;
                font-weight: 400;
                border: none;
                height: 2rem;
                padding: 0;
            }
        }
    }
}

html.desktop {
    .messenger {
        .inbox_title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .broadcast_cta {
                margin-left: auto;
            }
        }

        &.inbox {
            border-radius: 0.5rem;

            .details {
                .top_section {
                    .name {
                        color: var(--messenger-inbox-name-color);
                    }
                }

                .middle_section {
                    color: var(--messenger-inbox-text-color);

                    &.read {
                        color: var(--messenger-inbox-unread-text-color);
                    }
                }
            }
        }
    }

    .messenger#message_page {
        height: 100%;
        max-height: calc(100vh - 15.3125rem);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .conversation_header {
            display: flex;
            padding-bottom: 1rem;
            border-bottom: 0.0625rem solid var(--messenger-header-border-color);
            background-color: var(--messenger-conversation-header-bg-color);

            .recipient_name {
                font-weight: 700;
                line-height: 1.5rem;
                font-size: 1.125rem;
                align-self: center;
                color: var(--messenger-message-name-color);
            }

            .message_actions {
                flex-direction: row;
                display: flex;
                margin-left: auto;
                gap: 0.5rem;
                white-space: nowrap;

                .ft-button-bordered {
                    max-width: 10.9375rem;
                }
            }
        }

        .conversation_wrapper {
            height: 100%;
            overflow: scroll;

            .conversation {
                position: relative;
                display: flex;
                flex-direction: column;

                &.main_content_container {
                    padding: 0 0 1rem;
                }
            }
        }

        .messagebar {
            position: static;
            background-color: var(--messenger-messagebar-background-color);
            padding: 0.5rem 1rem 0.5rem 1rem;
            border-radius: 0.5rem;
            border-top: none;

            textarea {
                align-content: center;
                background-color: inherit;
                width: 100%;
                height: 100%;
                font-size: 0.875rem;
                color: var(--messenger-messagebar-text-color);
                resize: none;
            }
        }
    }

    .message {
        max-width: 60%;
        font-weight: 400;
        font-size: 0.8125rem;
        line-height: 1.25rem;

        &.you {
            margin-left: auto;

            .avatar {
                margin-left: 8px;
            }
        }

        &.other {
            .avatar {
                margin-right: 8px;
            }
        }
    }
}
