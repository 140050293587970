.dropdown-wrapper {
    position: relative;

    .dropdown-trigger {
        cursor: pointer;
    }

    .dropdown-content {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        .defaulthover;
        z-index: 1;
    }

    &.is-open .dropdown-content {
        opacity: 1;
        pointer-events: all;
    }

    &.top .dropdown-content,
    &.top-start .dropdown-content,
    &.top-end .dropdown-content {
        bottom: 100%;
    }

    &.bottom .dropdown-content,
    &.bottom-start .dropdown-content,
    &.bottom-end .dropdown-content {
        top: 100%;
    }

    &.left .dropdown-content,
    &.left-start .dropdown-content,
    &.left-end .dropdown-content {
        right: 100%;
    }

    &.right .dropdown-content,
    &.right-start .dropdown-content,
    &.right-end .dropdown-content {
        left: 100%;
    }

    &.top-start .dropdown-content,
    &.bottom-start .dropdown-content {
        left: 0;
    }

    &.top-end .dropdown-content,
    &.bottom-end .dropdown-content {
        right: 0;
    }

    &.left-start .dropdown-content,
    &.right-start .dropdown-content {
        top: 0;
    }

    &.left-end .dropdown-content,
    &.right-end .dropdown-content {
        bottom: 0;
    }
}

.basic-dropdown-content {
    box-shadow: 0 0.125rem 1rem 0 var(--basic-dropdown-shadow-color);
    padding: 0.5rem 1rem 1rem;
    background-color: var(--basic-dropdown-bg-color);
    border-radius: 0.5rem;

    .title {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        padding-left: 0.375rem;
        color: var(--basic-dropdown-title-color);
        font-size: 0.875rem;
        line-height: 1.25rem;

        > a {
            .defaulthover;
        }
    }

    .divider {
        height: 0.0625rem;
        background-color: var(--basic-dropdown-divider-bg-color);
        margin: 0.5rem -1rem;
    }

    .item {
        padding: 0.25rem 0.75rem;
        gap: 0.75rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        line-height: 1.5rem;
        border-radius: 0.25rem;
        color: var(--basic-dropdown-item-color);
        white-space: nowrap;
        .hover-desktop-only ({
            background-color: var(--basic-dropdown-item-hover-bg-color, 0.4);
        });;
        .defaulthover;

        .i_svg {
            width: 1rem;
            height: 1rem;
            top: 0;
        }
    }

    .title > a,
    .item {
        &:hover {
            color: var(--basic-dropdown-item-hover-color);
        }
    }
}
