#user_panel {
    .results {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--user-panel-results);
    }

    .user_panel_list_card {
        display: flex !important;
        align-items: center;
        gap: 0.625rem;

        .left_section {
            max-width: 3.75rem;

            img {
                object-fit: cover;
                margin-right: 0;

                &.avatar {
                    padding: 0.5rem;
                }
            }
        }

        .right_section {
            align-self: center;
            position: relative;
            right: 0.625rem;
            color: var(--user-panel-date-color);

            .i_svg {
                color: var(--notifications-icon-color);
            }
        }

        .middle_section {
            .flex-grow(1);
            display: flex;
            flex-direction: column;
            padding: 0.4rem;
            gap: 0.2rem;

            .name {
                display: flex;
                gap: 0.25rem;
                font-size: 1rem;
                line-height: 1.5rem;
                color: var(--user-panel-name-text-color);
            }

            p {
                color: @notifications-notification-title-color;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }

            .date,
            .text {
                font-weight: 400;
                line-height: 1.25rem;
                font-size: 0.8125rem;
            }

            .date {
                color: @notifications-notification-date-color;
            }

            .text {
                color: @notifications-notification-subtext-color;

                svg {
                    margin-right: 0.3125rem;
                }
            }
        }

        &.comment {
            .left_section {
                max-width: 6.875rem;
            }

            .middle_section {
                padding: 0;
            }

            .right_section {
                .date {
                    font-size: 1rem;
                    color: var(--user-panel-date-color);
                }
            }
        }

        &:not(.comment) {
            padding: 0.5rem 0.95rem !important;
        }
    }

    .achievements {
        .achievement {
            border: 1px solid @back;
            padding: 10px;
            margin: 5px 0;
            .rounded(5px);

            h3 {
                padding-bottom: 5px;
                color: @text-l1;

                .i_svg {
                    fill: @back-l2;
                }

                .n {
                    display: inline-block;
                    width: 250px;
                }

                .l,
                .m {
                    display: inline-block;
                    width: 80px;
                    color: @front-d4;
                }

                .m {
                    width: 400px;
                }
            }

            p {
                color: @front-l4;
                padding: 5px 0;
            }

            .progress {
                height: 30px;
                line-height: 30px;
                position: relative;
                text-align: center;
                border: 1px solid @back-d2;
                background-color: @back-d4;
                .rounded(5px);
                margin-bottom: 10px;

                span {
                    z-index: 1000;
                    position: absolute;
                }

                .inner {
                    position: absolute;
                    height: 28px;
                    left: 0;
                    top: 0;
                    background-color: @front-d4;
                    .rounded(5px);
                    z-index: 0;
                }
            }

            .stats {
                color: @back-l4;
            }
        }
    }

    .message {
        clear: both;
        overflow: hidden;
        padding-bottom: 60px;

        ul {
            min-height: 250px;
            clear: both;
            overflow: hidden;
        }

        li {
            line-height: 20px;
            overflow: hidden;
            max-width: 80%;
            min-width: 40%;
            clear: both;
            position: relative;
            padding: 0 0 0 50px;
            float: left;

            span.f {
                color: @text-d1;
                display: block;
                padding: 3px 10px;

                a {
                    color: @front-l4;
                }

                .i_svg {
                    fill: @front-d4;
                }
            }

            span.m {
                display: block;
                background-color: darken(@front-d4, 20%);
                .rounded(10px);
                padding: 10px;
            }

            span.d {
                color: @back-l2;
                padding: 3px 10px;
                display: block;
            }

            span.a {
                position: absolute;
                left: 5px;
                top: 25px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            &.you {
                float: right;
                clear: both;
                padding: 0 50px 0 0;

                span.m {
                    background-color: @back-d3;
                }

                span.f,
                span.d {
                    text-align: right;
                }

                span.a {
                    left: auto;
                    right: 5px;
                }
            }
        }

        .messagebar {
            clear: both;
            position: fixed;
            bottom: 60px;
            left: 0;
            width: 100%;
            font-size: @input-font-size;
        }

        .reply_form {
            padding: 15px 0 15px 0;
            background-color: @back-d4;
            clear: both;
            text-align: center;

            .message_actions {
                text-align: center;
                padding: 10px 0 0 0;

                span {
                    display: inline-block;
                    padding: 5px;
                    background-color: @back-d1;
                    border: 1px solid @back-d2;
                    cursor: pointer;
                    color: @back-l4;

                    &:hover {
                        background-color: @back-d4;
                    }
                }
            }
        }
    }

    .similar_list {
        clear: both;
        overflow: hidden;

        div.u {
            width: 49%;
            display: inline-block;
            text-align: center;
            margin-bottom: 10px;

            a {
                display: block;
                text-align: center;
                color: @text;
                margin-bottom: 10px;

                img {
                    display: block;
                    width: 75px;
                    height: 75px;
                    .rounded(50px);
                    margin: 10px auto;
                    border: 1px solid @back-l4;
                }
            }
        }
    }

    .edit_account {
        p.btn {
            text-align: left;
            margin: 10px 0;
        }

        #avatar_form {
            border-bottom: 2px solid @front;
            margin: 0 0 20px 0;
        }

        ul {
            margin: 0;

            li {
                label.main {
                    background-color: @back-l1;
                }
            }
        }

        .thumbnails {
            img {
                width: 32%;
                border: 2px solid @back-d3;
                cursor: pointer;
                .opacity(0.7);

                &.active {
                    border-color: @front-d4 !important;
                    .opacity(1);
                }

                &:hover {
                    border-color: @back-l4;
                    .opacity(1);
                }
            }
        }
    }

    .edit_video {
        form {
            margin-top: 1rem;

            li {
                margin-bottom: 1.625rem;

                label {
                    margin-bottom: 0.5rem;
                }

                textarea {
                    resize: vertical;
                }
            }

            fieldset {
                margin-top: 1rem;
            }

            fieldset:not(.category_fields) {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;

                label:has(> input[type='radio']) {
                    display: flex;
                    align-items: baseline;
                    .adjusted-flex-basis(2, 1rem);
                }
            }

            .category_fields {
                display: flex;
                gap: 0.5rem;
                flex-wrap: wrap;

                label {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    .adjusted-flex-basis(2, 0.5rem);
                }
            }

            p {
                font-size: 0.875rem;
                line-height: 1.25rem;
            }

            .thumbnails {
                .wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;

                    img {
                        width: calc(50% - 0.4rem);
                        border: 0.5rem solid var(--video-user-bg-color);

                        &.active {
                            border-color: var(--video-playlist-active-item-bg-color);
                        }
                    }
                }
            }

            .delete {
                display: inline-block;
                margin-top: 1.5rem;
                color: var(--link-color);
                cursor: pointer;
            }
        }
    }

    .notifications_list {
        overflow: hidden;
        .text-shadow();
        display: flex;
        flex-direction: column;

        > li.read {
            margin-top: 30px;
        }

        > li.read ~ li.read {
            margin-top: 0;
        }

        li {
            padding: 0.4375rem 0;
            overflow: hidden;
            position: relative;
            font-size: 1em;
            line-height: 1.25rem;

            &.views {
                padding: 0.625rem 0;
            }

            &.social {
                padding: 0.625rem 0;
            }

            &.unread {
                background-color: @back-d4;
            }

            img.primary_img {
                width: 80px;
                max-height: 100px;
                float: left;
                border: 2px solid @back-d4;
                display: block;
                margin: 0 5px 0 0;
                float: right;
            }

            img.avatar_img {
                width: 40px;
                max-height: 100px;
                float: left;
                border: 2px solid @back-d4;
                display: block;
                margin: 0 5px 0 0;
                float: right;
            }

            .i_svg {
                float: left;
                margin: 2px 10px 0 0;
                font-size: 1em;
                fill: @back-l2;
            }

            a {
                color: @front;
                font-style: italic;
            }

            a.uname {
                color: @front;
                font-style: normal;
                font-weight: bold;
            }

            span.date {
                font-size: 0.8em;
                color: @back-l3;
                width: 100px;
                display: inline-block;
                text-align: center;
            }
        }
    }
}

.notification_delete_button {
    margin-left: auto;
    max-width: 7rem;
    padding: 0.5rem 0.75rem !important;
}

html.desktop {
    .user_panel_list_card {
        background-color: var(--user-panel-item-bg-color);
        border-top: 0.0625rem solid var(--user-panel-separator-color);
        .defaulthover;

        &:hover {
            background-color: var(--user-panel-item-bg-hover-color);
        }

        &:first-child:not(:last-child) {
            border-radius: 0.5rem 0.5rem 0 0;
            border-top: none;
        }

        &:last-child:not(:first-child) {
            border-radius: 0 0 0.5rem 0.5rem;
        }

        &:first-child:last-child {
            border-radius: 0.5rem;
            border-top: none;
        }
    }

    .notifications_list {
        cursor: pointer;
    }

    .notification_delete_button {
        margin-left: auto;
        font-size: 0.875rem;
        max-width: 8.75rem;
    }

    #user_panel {
        min-height: @empty-page-min-height;

        .user-panel-wrapper {
            display: flex;
            gap: 1.5rem;

            .sidebar {
                display: none;
                width: 19rem;
                flex-grow: 0;
                flex-shrink: 0;
                .space-vertical();
            }

            .data {
                flex-basis: 100%;
                flex-grow: 0;
                overflow: hidden;
            }

            .video-list {
                --video-list-columns: 4;
            }
        }

        .data {
            .main_content_container {
                padding: 0;
            }
        }

        .sub_nav {
            gap: 1rem;
            justify-content: start;

            a {
                width: auto;
                flex-grow: 0;
                color: var(--playlist-header-text-color);
                font-size: 0.875rem;
            }
        }

        .user_panel_list_card {
            &.comment {
                padding: 1.5rem !important;
            }

            .middle_section {
                align-self: baseline;
            }
        }

        .data {
            .pending-video {
                clear: both;
                padding: 5px 0;
                display: block;

                span {
                    color: @front-l2;
                    font-weight: 600;
                }

                .i_svg {
                    fill: @back-l4;
                    font-size: 1.2em;
                    margin-right: 5px;
                }

                a {
                    color: @text-l1;
                }
            }

            .pending-video-wt {
                padding: 1px 0px 5px 30px;
                color: @back-l4;
                margin-top: -5px;
            }

            .empty {
                h3 {
                    color: @front-l3;
                    padding: 20px 0 10px 0;
                }

                p {
                    color: @text;

                    a {
                        text-decoration: underline;
                        color: @front;
                    }
                }
            }
        }

        .uploaded_video_list {
            li {
                border-bottom: 1px solid @back-d2;
                overflow: hidden;

                span {
                    display: block;
                    float: left;
                    padding: 5px;
                }

                .t {
                    width: 10%;

                    img {
                        width: 100%;
                    }
                }

                .n {
                    width: 30%;
                }

                .v {
                    width: 7%;
                    font-size: 1.5em;
                    text-align: center;
                }

                .d {
                    width: 12%;
                }

                .e {
                    a {
                        display: inline-block;
                        color: @front;
                        padding: 5px;
                        background-color: @back-d2;
                    }
                }
            }

            li.h {
                background-color: @back-d2;
                color: @back-l4;

                .v {
                    font-size: 1em;
                    text-align: center;
                }
            }
        }

        .edit_account {
            ul {
                width: 850px;

                li {
                    label.main {
                        width: 150px;
                    }
                }
            }

            .thumbnails {
                img {
                    width: 18%;
                    border: 6px solid @back-d3;
                    cursor: pointer;

                    &.active {
                        border-color: @front-d4 !important;
                    }

                    &:hover {
                        border-color: @back-l4;
                    }
                }
            }
        }

        .edit_video {
            form {
                padding: 2.5rem;

                fieldset:not(.category_fields) {
                    justify-content: space-between;
                    flex-wrap: nowrap;

                    label:has(> input[type='radio']) {
                        width: 100%;
                    }
                }

                .category_fields {
                    label {
                        .adjusted-flex-basis(4, 0.5rem);
                        margin: 0;
                    }
                }

                .thumbnails {
                    .wrapper {
                        img {
                            width: calc(20% - 0.4rem);
                            aspect-ratio: 1.4;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .broadcast-container {
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 1.125rem;
            }
        }

        .broadcast_list {
            margin-top: 1rem;

            li:first-child:not(:last-child) {
                border-radius: 0.5rem 0.5rem 0 0;
                border-top: none;
            }

            li:last-child:not(:first-child) {
                border-radius: 0 0 0.5rem 0.5rem;
            }

            li:first-child:last-child {
                border-radius: 0 0 0.5rem 0.5rem;
                border-radius: 0.5rem;
                border-top: none;
            }

            li {
                display: flex;
                flex-direction: column;
                background: var(--user-panel-item-bg-color);
                padding: 1rem;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -ms-word-break: break-all;
                word-break: break-all;
                word-break: break-word;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                -webkit-hyphens: auto;
                hyphens: auto;
                border-top: 0.0625rem solid var(--user-panel-separator-color);
                cursor: pointer;
                .defaulthover;

                .bc_main_content {
                    display: flex;
                    justify-content: space-between;

                    .user {
                        display: flex;
                        align-items: center;

                        img {
                            width: 3.125rem;
                            margin-right: 0.5rem;
                        }

                        .text {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            height: 100%;
                            font-size: 0.875rem;
                            line-height: 1.25rem;
                        }
                    }

                    .reply {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex-shrink: 0;

                        .date {
                            display: block;
                            text-align: right;
                            font-size: 0.75rem;
                            color: var(--notifications-results-text-color);
                        }

                        .ft-button-bordered {
                            font-size: 0.75rem;
                            padding: 0 0.5rem;
                        }
                    }
                }

                .bc_videos {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 0.5rem;
                    gap: 0.5rem;

                    .video_card {
                        .adjusted-flex-basis(4, 0.5rem);
                        position: relative;
                        flex-grow: 0;
                        overflow: hidden;

                        span.ext {
                            position: absolute;
                            top: 0.5rem;
                            right: 0.5rem;
                            padding: 0 0.25rem;
                            border-radius: 0.25rem;
                            background-color: black;
                            opacity: 75%;
                            line-height: 1.125rem;
                        }

                        img {
                            border-radius: 0.25rem;
                            width: 100%;
                        }

                        .title_wrap {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            a.link {
                                color: var(--video-list-info-text-color);
                                line-height: 1.5rem;
                            }
                        }
                    }
                }

                .bc_users {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                    padding-top: 0.5rem;

                    .user_card {
                        display: flex;
                        .adjusted-flex-basis(4, 0.5rem);
                        flex-grow: 0;
                        overflow: hidden;
                        height: 2.5rem;
                        background-color: var(--user-panel-item-bg-color);
                        border-radius: 0.5rem;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0.5rem;

                        .user_details {
                            display: flex;
                            align-items: center;
                            overflow: hidden;

                            .title_wrap {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }

                            img {
                                width: 1.5rem;
                                margin-right: 0.5rem;
                            }

                            a {
                                flex-shrink: 0;
                            }
                        }

                        .ft-button {
                            flex-shrink: 0;
                            padding: 0.125rem 0.5rem;
                            font-size: 0.75rem;
                            line-height: 1.125rem;
                        }
                    }
                }
                &:hover {
                    background-color: var(--user-panel-item-bg-hover-color);
                }
            }
        }
    }

    @media all and (min-width: @breakpoint-xl) {
        #user_panel {
            .user-panel-wrapper {
                .sidebar {
                    display: block;
                }
            }

            .edit_video {
                form {
                    .category_fields {
                        label {
                            .adjusted-flex-basis(5, 0.5rem);
                        }
                    }
                }
            }
        }
    }
}
