// main:style.less

.video-similar {

    width:100%;
    clear:both;


    h1 {
        margin: 0.5% 0.5%;
        height:26px;
        line-height: 26px;
        font-size: 1.2em;
        padding:0;
    }

  .active {
    background-color: @front-d4;
  }

	.video-item {

		width: 100%;
		position: relative;
		display: block;
		zoom: 1;
    overflow:hidden;
    padding: 0px 15px 0px 0px;
    margin: 0 0 10px 0;
    overflow:hidden;

		.thumb {
			  display: block;
	  		width: 150px;
        height: 85px;
	  		position: relative;
	  		overflow: hidden;
        float: left;

		     img.cover {
              display: block;
              margin: auto;
              .rounded(3px);
            }

            .play {
              position: absolute;
              top: 2%;
              left: 2%;
              color: fade(@back, 50);
              display: none;
            }

            span.len {
                position: absolute;
                right: 2px;
                bottom: 2px;
                font-size: 0.9em;
                text-align: center;
                background-color: @back-d2;
                background-color: fade(@back, 80);
                color:@text;
                .rounded(3px);
                padding:2px 3px;
                .text-shadow();
            }

        }

        .i-hd {
            width: 26px;
            height: 15px;
            position: absolute;
            top: 2%;
            left: 2%;
            overflow:hidden;
            background-color: @back-d2;
            color: @text-l1;
            font-size: 0.7em;
            text-align: center;
            line-height: 15px;
            .rounded(3px);
            font-style: italic;
            font-weight: bold;
            .opacity(0.8);
        }

        .i-fe {
            position: absolute;
            right: -1px;
            top: -1px;
            margin: auto;
            z-index: 2;
            background-image: url('../Images/i_badge_pop.png');
            background-repeat: no-repeat;
            background-position: center center;
            width:36px;
            height:31px;
            overflow:hidden;
            text-indent: -200px;
        }

	      .i-wl {
	          width: 28px;
						height: 28px;
	          position: absolute;
	          bottom: 2%;
	          right: 2%;
	          overflow:hidden;
						background-color: @back-d4;
						color: @text-l1;
						font-size: 1.2em;
						text-align: center;
						line-height: 28px;
						.rounded(3px);
						.opacity(0.8);
						cursor: pointer;
            display: none;

						&:hover {
							background-color: @back;
						}


						svg {
							fill: @text-l1 !important;
						}
	      }

        .inf_side {
            font-size: 0.9em;
            padding: 3px 0 0 0;
            position: relative;
            text-align: left;
            margin-left:160px;

            a {
                color:@text;
                font-size: 1.1em;
            }

            strong {
                font-weight: 400;
                color:@text-l1;
            }



        }

        .pb {
            height: 24px;
            line-height: 24px;
            overflow: hidden;
            clear: both;
            width: 100%;
            background-color: #c0392b;
            border-right: 4px solid rgba(0, 0, 0, 0.5);
            z-index: 110;
        }

        &:hover {
  	      .i-wl {
            display: block;
          }
        }
	}

    .is_rotating {
        z-index: 0;

        .play {
            display: block !important;
        }

        .i-hd, .i-fe, p, .i-wl  {
            display: none !important;
        }

    }

}

.video-playlist {
  height: 483px;
  overflow: scroll !important;
}

#video-similar-thumb-rotate {
  width: 250px;
  min-height: 100px;
  background-color: @back-d4;
  .rounded(5px);
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 1000;
  border: 1px solid @back;
}
