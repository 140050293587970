#video_theater {
    display: none;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

#video {
    .quality-items {
        > *:not(:last-child) {
            border-bottom: 1px solid @limed-spruce;
        }
    }

    .quality-item {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
    }

    .quality-item-icon {
        font-size: 1rem;
        margin-right: 0.875rem;
        color: @mandy;

        &:not(.selected) {
            visibility: hidden;
        }
    }

    .quality-item-text {
        font-size: 0.75rem;
        color: @white;
    }

    #video_container {
        #main_video_player.video-js.vjs-theme-sb.video_container_removed {
            pointer-events: none;
            .removed {
                background-color: var(--video-thumbnail-bg-color-rgba);
                position: absolute;
                left: 0;
                right: 0;
                display: none;
                justify-content: center;
                align-items: center;
                width: 10.5rem;
                height: 10.5rem;
                padding: 0;
                margin: auto;
                border-radius: 0.25rem;
                pointer-events: all;
                font-size: 100%;
                color: var(--video-removed-video-text-color);
                transform: translate(0, -50%);
            }
        }
    }
}

#main_video_player_html5_api {
    position: relative;
}

.theater-mode {
    margin-bottom: 1rem;
}

#video #video_container,
#video_theater #video_container {
    display: none;
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 1rem;

    > #main_video_player,
    #vast_video_player {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
    }

    > #main_video_player {
        position: absolute;
    }

    > #vast_video_player {
        z-index: 18;
        cursor: pointer !important;
        display: block;
        position: absolute;
        font-size: 0.9em !important;

        &.hidden {
            display: none;
        }
    }

    .vjs-poster {
        display: none;
    }
}

html.desktop {
    #video {
        display: flex;
        gap: 1.5rem;

        .views {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            white-space: nowrap;
            font-size: 0.875rem;
            line-height: 1.25rem;
            border-radius: 0.25rem;
        }

        span.i-play {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: auto;
            pointer-events: all;
            z-index: 51;
            background-color: var(--video-play-bg-color-rgba);
            width: 5rem;
            height: 5rem;
            border-radius: 4.5rem;
            border: transparent;
            font-size: 3.5rem;

            .i_new-ui-play {
                height: 2.5rem;
                width: 2.5rem;
                top: 0;
                fill: var(--video-thumbnail-text-color);
            }
        }

        #vr_player {
            width: 100%;
            height: 0;
            padding-top: 62.25%;
            position: relative;

            .vr_player_inner {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }

            span.i-length,
            span.i-plays,
            span.i-date,
            span.i-watch,
            span.i-hd,
            .ttaa2v3 {
                height: 30px;
                position: absolute;
                top: 45px;
                left: 5px;
                padding: 0 5px;
                overflow: hidden;
                background-color: fade(@back-d4, 55);
                color: @text;
                font-size: 0.875rem;
                text-align: center;
                line-height: 1.25rem;
                .rounded(3px);
            }

            span.hd-time {
                position: absolute;
                top: 45px;
                left: 5px;
            }

            .ttaa2v3 {
                top: 8px;
                left: 5px;
                text-transform: uppercase;
                background-color: fade(@back-d4, 80);

                span {
                    color: lighten(@front-l4, 10);
                    font-weight: bold;
                    padding: 0 2px;
                }
            }

            span.i-hd {
                position: static;
                background-color: fade(@back-d4, 80);
                display: inline-block;
                font-weight: bold;
                font-style: italic;
                margin-left: -2px;
            }

            span.i-length {
                position: static;
                background-color: fade(@back-d4, 75);
                display: inline-block;
            }

            span.i-plays {
                top: 78px;
            }

            span.i-date {
                top: 112px;
            }

            span.i-watch {
                top: auto;
                bottom: 5px;
                color: @front-d4;
                background-color: transparent;
                .opacity(1);
            }
        }

        #player_wrapper_outer {
            position: relative;

            img.player_thumb {
                width: 100%;
                margin: 0 auto;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }

            span.i-length,
            span.i-plays,
            span.i-date,
            span.i-watch,
            span.i-hd,
            .ttaa2v3 {
                display: flex;
                align-items: center;
                height: 1.875rem;
                padding: 0.5rem;
                overflow: hidden;
                background-color: var(--video-thumbnail-bg-color);
                color: var(--video-thumbnail-text-color);
                opacity: 0.75;
                font-size: 0.75rem;
                text-align: center;
                line-height: 1.125rem;
                border-radius: 0.25rem;
                white-space: nowrap;
            }

            span.hd-time {
                display: flex;
                gap: 0.5rem;
                position: absolute;
                top: 2.75rem;
                left: 0.5rem;
            }

            .ttaa2v3 {
                background-color: var(--ad-button-bg-color);
                text-transform: uppercase;
                border-radius: 0.25rem;

                span {
                    color: lighten(@front-l4, 10);
                    font-weight: bold;
                    padding: 0 2px;
                }

                a {
                    color: var(--video-thumbnail-text-color);
                }
            }

            span.i-hd {
                position: static;
                font-weight: 700;
                margin-left: -2px;
            }

            span.i-length {
                position: static;
            }

            span.i-plays {
                font-weight: 700;
            }

            span.i-date {
                top: 112px;
            }

            span.i-watch {
                top: auto;
                bottom: 5px;
                color: @front-d4;
                background-color: transparent;
                .opacity(1);
            }

            .loading-icon img {
                height: 90px;
                display: block;
                margin: 25px auto 0 auto;
            }

            .loading-icon,
            .loading-icon::before,
            .loading-icon::after {
                left: 50%;
                top: 50%;
                margin-left: -70px;
                margin-top: -70px;
                position: absolute;
                background-color: fade(@back-d4, 80);
                background: fade(@back-d4, 80);
                width: 140px;
                height: 140px;
                border-radius: 30%;
                border: 2px solid @back-l2;
            }

            .loading-icon::before {
                content: '';
                animation: bounce 1.5s infinite;
                animation-timing-function: ease-out;
            }

            .loading-icon::after {
                content: '';
                animation: bounce 1.5s -0.4s infinite;
                animation-timing-function: ease-out;
            }

            #vast_player_container {
                display: none;
                width: 100%;
                padding-top: 56.25%;
                position: relative;

                video,
                iframe {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                .advertisement {
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    display: inline;
                    padding: 5px;
                    background-color: fade(@back-d4, 70);
                    color: @text-l1;

                    .i_svg {
                        fill: @front-l4;
                    }
                }

                .skip_button {
                    background-color: @front-d4;
                    padding: 5px 10px 5px 2px;
                    line-height: 56px;
                    .border-radius(0, 0, 5px, 5px);
                    font-size: 1.5em;
                    .drop-shadow(@x-axis: 0, @y-axis: 2px, @blur: 1px, @alpha: 0.6);
                    .text-shadow(@x-axis: 0, @y-axis: 2px, @blur: 2px, @alpha: 1);
                    .gradient(@color: @front-d4, @start: @front-d4, @stop: @middle-d4);
                    bottom: 15px;
                    position: absolute;
                    right: 0;
                    text-align: center;

                    img {
                        float: left;
                        width: 90px;
                        margin-right: 14px;
                        .rounded(5px);
                        border: 1px solid @back;
                    }

                    a {
                        font-size: 1.1em;
                    }
                }
            }
        }

        .left {
            height: 100%;
            flex-grow: 1;
            width: calc(100% - 18.75rem - 1.5rem);

            .warning_process {
                background-color: @front-d4;
                color: @front-l4;
                text-align: center;
                padding: 10px;
                margin-bottom: 10px;
                .rounded(5px);
            }

            h2 {
                font-size: 1.125rem;
                color: var(--title-color);
                background-color: transparent;
                margin-bottom: 1rem;
                height: auto;
                line-height: 1.5rem;
            }

            .searches {
                margin: 0 0 4px 0;

                .tag-list(a);

                .extra {
                    display: none;
                }
            }

            ul.video_toolbar {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: stretch;
                align-items: stretch;
                line-height: 2.625rem;
                position: relative;
                z-index: 20;
                gap: 0.5rem;

                .i_svg {
                    fill: var(--video-toolbar-icon-color);
                    top: 0;
                    .defaulthover;

                    &.i_new-ui-chevron-right {
                        right: 0;
                        margin-left: auto;
                        width: 1rem;
                        height: 1rem;
                        top: 0;
                    }
                }

                > li {
                    flex-shrink: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    border-radius: 0.25rem;
                    font-size: 0.8125rem;
                    padding: 0 1rem 0 1rem;
                    text-align: center;
                    cursor: pointer;
                    overflow: hidden;
                    background-color: var(--video-toolbar-item-bg-color);
                    gap: 0.5rem;

                    .i_svg {
                        flex-shrink: 0;
                    }

                    &.primary_actions_container {
                        background-color: transparent;
                        padding: 0;
                        font-weight: 700;
                        flex-shrink: 1;
                        flex-grow: 1;

                        .ft-button {
                            background-image: unset;
                            line-height: unset;

                            &:before {
                                content: unset;
                            }
                        }

                        > * {
                            background-color: var(--video-toolbar-item-bg-color) !important;
                            color: var(--video-toolbar-text-color);
                            gap: 0.5rem;
                            padding: 0 1rem 0 1rem;
                            flex-basis: 33.333%;
                            justify-content: center;
                            .defaulthover;
                        }

                        .text {
                            font-weight: 400;
                        }

                        img {
                            width: 1.5rem;
                            height: 1.5rem;
                        }

                        .i_svg:not(.i_new-ui-chevron-right) {
                            width: 1.5rem;
                            height: 1.5rem;
                            margin-left: unset;
                        }

                        .subscriber-count {
                            font-size: 0.8125rem;
                        }

                        span {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 0.5rem;
                        }

                        a {
                            display: inline-block;
                        }

                        a.ul {
                            color: var(--video-user-link-color);
                            font-weight: 700;
                            display: flex;
                            flex-direction: row;
                            gap: 0.5rem;
                            align-items: center;
                            flex-grow: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            border-radius: 0.25rem;
                            .defaulthover;

                            &:hover {
                                color: var(--video-toolbar-item-hover-color);
                                background-color: var(--video-toolbar-item-hover-background-color);
                                cursor: pointer;

                                .i_svg {
                                    fill: var(--video-toolbar-item-hover-color);
                                    .defaulthover;
                                }
                            }

                            img {
                                display: inline-block;
                                height: 100%;
                                max-height: 2.625rem;
                                float: left;
                                margin-right: 0.3125rem;
                                border-radius: 0.3125rem;
                            }

                            .name {
                                display: block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        span,
                        a.wbs,
                        .join {
                            border-radius: 0.25rem;
                            margin: 1px;

                            &:hover {
                                color: var(--video-toolbar-item-hover-color);
                                cursor: pointer;
                                background-color: var(--video-toolbar-item-hover-background-color);
                                .defaulthover;

                                em {
                                    color: var(--video-toolbar-item-hover-color);
                                    .defaulthover;
                                }

                                .i_svg {
                                    fill: var(--video-toolbar-item-hover-color);
                                    .defaulthover;
                                }
                            }

                            em {
                                font-style: normal;
                                color: var(--video-toolbar-icon-color);
                                font-weight: 700;
                                font-size: 1rem;
                                .defaulthover;
                            }
                        }

                        a.join {
                            display: flex;
                            align-items: center;
                            padding: 1px;
                        }
                    }

                    &.secondary_actions_container {
                        padding: 0;

                        div {
                            justify-content: center;
                            flex-basis: 33%;
                            flex-shrink: 1;
                        }

                        .rt {
                            display: flex;
                            flex-wrap: nowrap;
                            align-content: stretch;
                            align-items: stretch;

                            gap: 0.5rem;

                            .hot,
                            .rate,
                            .not {
                                flex-grow: 0;
                            }

                            .hot,
                            .not {
                                display: block;
                                height: 100%;
                                min-width: 1.5625rem;
                                text-align: left;
                                padding: 0 0.25rem;
                            }

                            .not {
                                text-align: right;
                            }

                            .rate {
                                flex-shrink: 1;
                                flex-grow: 0;
                                color: var(--video-toolbar-rate-text-color);
                                font-weight: 400;
                            }

                            .i_svg {
                                height: 1.25rem;
                                width: 1.25rem;
                                top: 0.3125rem;

                                &:hover {
                                    fill: var(--video-toolbar-item-hover-color);
                                    cursor: pointer;
                                }
                            }
                        }

                        .short_buttons {
                            padding: 0 1.6rem;
                            display: flex;
                            gap: 1rem;
                            flex-grow: 0;
                            flex-shrink: 1;
                            position: relative;

                            &::before {
                                content: '';
                                width: 0.0625rem;
                                height: 1.625rem;
                                top: 0.5rem;
                                background-color: var(--video-toolbar-separator-color);

                                position: absolute;
                                margin: 0;
                            }

                            &::before {
                                left: -0.3125rem;
                            }

                            &::after {
                                right: -0.3125rem;
                            }
                        }

                        .dl,
                        .fv,
                        .wl,
                        .pl,
                        .fl,
                        .qt {
                            flex-grow: 0;

                            &:hover:not(.active) {
                                cursor: pointer;

                                > span {
                                    color: var(--video-toolbar-item-hover-color);
                                    .defaulthover;
                                }

                                .i_svg {
                                    fill: var(--video-toolbar-item-hover-color);
                                }
                            }

                            a {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }

                            .i_svg {
                                height: 1.25rem;
                                width: 1.25rem;
                                top: 10%;
                            }
                        }

                        .dl {
                            text-transform: uppercase;
                        }

                        .mod_delete {
                            background-color: var(--video-toolbar-delete-bg-color);
                            color: var(--video-toolbar-delete-text-color);
                            cursor: pointer;
                        }

                        .active {
                            svg {
                                fill: var(--video-toolbar-active-text-color);
                            }
                        }

                        a {
                            color: inherit;
                        }
                    }
                }
            }

            .share {
                clear: both;
                overflow: hidden;
                padding: 0 0;
                width: 100%;

                a {
                    display: inline-block;
                    font-size: 1.3em;
                    background-color: @back;
                    color: @text-l1;
                    width: 50px;
                    padding: 5px;
                    text-align: center;
                    margin: 2px;
                    .rounded(5px);

                    &:hover {
                        background-color: @front;
                        color: @text-l1;
                    }
                }
            }

            .embed {
                .space-vertical(0.5rem);

                h2 {
                    font-size: 0.875rem;
                    font-weight: 700;
                    line-height: 1.25rem;
                }

                .text-area {
                    background-color: var(--embed-textarea-bg-color);
                    color: var(--embed-textarea-color);
                    padding: 16px;
                    min-height: 0;
                }
            }

            .positions-wrapper {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                .skip-to {
                    color: var(--video-positions-skip-to-color);
                    font-size: 0.8125rem;
                    flex-shrink: 0;
                }

                ul.positions {
                    .tag-list();
                }
            }

            section.timeline {
                overflow: hidden;
                clear: both;
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
                margin: 0.5rem 0 1.5rem;
                overflow: hidden;

                div {
                    .adjusted-flex-basis(5, 0.5rem);
                    position: relative;
                    zoom: 1;
                    overflow: hidden;
                    cursor: pointer;

                    .nav_time {
                        display: inline-block;
                        position: absolute;
                        right: 0.5rem;
                        bottom: 0.5rem;
                        background-color: var(--video-thumbnail-bg-color);
                        font-size: 0.75rem;
                        line-height: 1.125rem;
                        padding: 0 0.25rem;
                        border-radius: 0.25rem;
                        z-index: 2;
                    }

                    span {
                        display: block;
                        width: 100%;
                        position: relative;
                        height: 0;
                        padding: 56% 0 0 0;
                        overflow: hidden;
                        background-color: var(--video-thumbnail-bg-color-rgba);
                        border-radius: 0.25rem;

                        img {
                            position: absolute;
                            display: block;
                            width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                            left: 0;
                            top: 0;
                            margin: auto;
                            .rounded(0);
                            z-index: 1;
                        }
                    }
                }
            }

            section.details {
                margin-top: 1.5rem;
                overflow: hidden;

                div.bottom {
                    display: flex;
                    line-height: 0.9rem;
                    font-size: 0.75rem;
                    margin-bottom: 1.5rem;

                    div.cat {
                        margin-right: 1.5rem;

                        span.t {
                            display: block;
                            color: var(--video-toolbar-item-color);
                            font-weight: 700;
                            font-size: 0.75rem;
                        }

                        div.ent {
                            display: inline-block;
                            background-color: var(--video-toolbar-item-bg-color);
                            overflow: hidden;
                            border-radius: 0.25rem;
                            padding: 0.5rem;
                            font-size: 0.75rem;
                            font-weight: 700;
                            margin-top: 0.5rem;

                            a {
                                color: var(--video-details-channel-color);
                            }

                            .i_svg {
                                padding: 5px;
                                background-color: @back-d1;
                                cursor: pointer;

                                &:hover {
                                    background-color: @front-d4;
                                }
                            }

                            svg.times {
                                fill: @text-d1;
                            }
                        }
                    }

                    a.bt_tag_video {
                        display: inline-block;
                        padding: 2px 5px;
                        margin-left: 10px;
                        border: 1px solid @back-d4;
                        background-color: @back-d1;
                        .rounded(3px);
                        color: @text;

                        .i_svg {
                            fill: @text-d1;
                        }
                    }

                    .channel-by {
                        display: flex;
                        flex-direction: column;
                        flex-shrink: 0;
                    }
                }
            }

            section.user_uploads {
                margin-top: 1.5rem;
                overflow: hidden;
                position: relative;

                h2 {
                    padding-bottom: 5px;
                }

                .video-list {
                    --video-list-columns: 5;
                }
            }

            section.all_comments {
                padding: 1.5rem;
                border-radius: 0.25rem;
                background-color: var(--comments-wrapper-bg-color);
            }

            section.share {
                .rounded(5px);
                background-color: rgba(0, 0, 0, 0.15);
                padding: 5px;
                margin: 10px 0;
            }
        }

        .similar-ads-horizontal {
            display: flex;
            justify-content: center;
            gap: 2rem;

            .video-inline-ads {
                width: 300px;
                height: 250px;
                display: block;

                > iframe,
                > ins {
                    margin-top: 0 !important;
                }
            }
        }

        .right {
            display: none;
            width: 18.75rem;
            flex-shrink: 0;

            .video-list {
                --video-list-columns: 1;

                .video-item {
                    p.t span.n {
                        margin-left: -3px;
                    }

                    .stats span.v {
                        padding-left: 0;
                    }

                    .stats span.g {
                        display: none;
                        padding-left: 0;
                    }
                }
            }

            .more_videos {
                width: 100%;
            }
        }
    }
}

#video_removed {
    .notice {
        width: 38.8%;
        margin: 0.8% 0.3%;
        background-color: @back-d3;
        float: right;
        position: relative;
        height: 400px;

        .content {
            padding: 56% 0 0 0;
            overflow: hidden;
            width: 100%;
            position: relative;

            .message {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: @back-d4;
                background-color: fade(@back-d4, 80);
                text-align: center;
                font-size: 2em;
                color: @text-d1;

                span.text {
                    margin-top: 90px;
                    padding: 5px;
                    display: block;
                }

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    font-size: 0.7em;
                    color: @text;

                    .play-circle {
                        display: block;
                        text-align: center;
                        font-size: 6em;
                        margin-top: 30px;
                        fill: @front;
                        .text-shadow(0,1px,1px,1);
                    }

                    p {
                        font-size: 1.6em;
                    }

                    &:hover {
                        .play-circle {
                            color: @front-d1;
                        }
                    }
                }
            }
        }

        .filler {
            overflow: hidden;

            .info {
                border: 0;
                padding: 10px 10px;

                a {
                    color: @front;
                }
            }

            .info_dark {
                background-color: @back-d4;
            }
        }
    }

    .notice_long {
        width: 58.2% !important;
        text-align: center;

        .content {
            padding: 60px !important;
        }
    }

    .video-item {
        width: 19.4%;
        margin: 0.8% 0.3%;
        .flex-basis(@width: 19.4%);
    }
}

.grid_container {
    position: fixed;
    width: 100%;
    height: 120px;
    background-color: @front-d4;
    border-top: 2px solid @back-d4;
    bottom: 0;
    left: 0;
    z-index: 1000000;
    padding: 5px 10px;
    display: none;

    ul {
        li {
            display: inline-block;
            height: 80px;
            line-height: 80px;
            vertical-align: middle;

            &.thumb {
                width: 120px;
                border: 1px solid @front;
                .rounded(5px);
                overflow: hidden;

                img {
                    height: 80px;
                }
            }
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        #video {
            .right {
                display: block;
            }
        }
    }
}

@media all and (min-width: 1366px) {
    html.desktop {
        #video {
            .left {
                ul.video_toolbar {
                    flex-direction: row;

                    li.primary_actions_container {
                        flex-grow: 1;

                        .ul,
                        span {
                            flex-basis: auto;
                            justify-content: flex-start;
                        }

                        .text {
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    li.secondary_actions_container {
                        flex-shrink: 1;
                        padding: 0 1rem;
                        gap: 0.5rem;

                        div {
                            justify-content: center;
                            flex-basis: auto;
                        }

                        .rt {
                            flex-shrink: 0;

                            .hot,
                            .rate,
                            .not {
                                flex-shrink: 0;
                                flex-grow: 1;
                            }
                        }

                        .short_buttons {
                            padding: 0 0.25rem;
                            gap: 1rem;

                            &::before,
                            &::after {
                                position: relative;
                                left: unset;
                                right: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}
