// main:style.less

#info {
    .info-page-text-content {
        p {
            margin-bottom: 1rem;
            line-height: 1.25rem;
            font-size: 0.875rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            .highlighted {
                color: var(--link-color);
            }
        }
    }

    #content_partnership {
        h1,
        h2 {
            text-align: center;
        }

        h1,
        h3 {
            font-weight: 700;
            color: var(--partnership-title-color);
        }

        h1,
        h2,
        h3 {
            padding: 0;
        }

        h1 {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        h2 {
            color: var(--partnership-subtitle-color);
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.5rem;
        }

        h3 {
            text-align: center;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-bottom: 1rem;
        }

        .pr {
            text-align: center;
            color: var(--partnership-text-color);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25rem;
        }

        .steps {
            display: flex;
            flex-direction: column;

            ul {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                flex-flow: wrap;
                gap: 0.5rem;

                li {
                    border-radius: 0.5rem;
                    background: var(--partnership-steps-bg-color);
                    padding: 1.5rem 1rem;
                    color: var(--partnership-text-color);
                    text-align: center;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.25rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1 0 0;
                    align-self: stretch;
                }
            }
        }

        .content_partnership_spots {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .new-form-holder {
            display: flex;
            flex-direction: column;

            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 1rem;

                li {
                    flex-basis: 35%;
                    flex-grow: 1;

                    li label.main {
                        color: var(--partnership-text-color);
                        margin-bottom: 0.5rem;
                    }

                    &:nth-of-type(0),
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        flex-basis: 25%;
                    }

                    &:last-of-type {
                        flex-basis: 100%;
                    }
                }
            }

            textarea {
                min-height: 5rem;
                resize: vertical;
            }

            .btn {
                align-self: center;

                button {
                    margin-top: 1.5rem;
                    padding: 0.5rem 3rem;
                }
            }
        }
    }
}

html.desktop {
    #info {
        margin-top: 1.5rem;

        .content-with-sidebar {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .sidebar {
                width: auto;

                .sidebar-dropdown {
                    display: block;

                    .dropdown-wrapper .item {
                        background-color: var(--sidebar-nav-item-tablet-bg-color);
                    }

                    .dropdown-wrapper.is-open .dropdown-trigger .item {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    .dropdown-content {
                        width: 100%;

                        .item {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            background-color: var(--sidebar-nav-item-tablet-bg-color);
                        }
                    }
                }

                .sidebar-content {
                    display: none;
                }
            }

            .content {
                flex-grow: 1;
            }

            .content-highlight-wrapper {
                padding: 1.5rem;
            }
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        #info {
            .content-with-sidebar {
                flex-direction: row;
                gap: 1.5rem;

                .sidebar {
                    width: 19rem;
                    flex-shrink: 0;

                    .sidebar-dropdown {
                        display: none;
                    }

                    .sidebar-content {
                        display: block;
                    }
                }

                .content-highlight-wrapper {
                    padding: 2.5rem;
                }
            }
        }
    }
}
