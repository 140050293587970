.comment_form {
    background-color: var(--comments-form-bg-color);
    padding: 1rem;
    border-radius: 0.5rem;

    .emojis {
        padding: 0.25rem;

        img {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
        }
    }
}

.comments {
    .space-vertical(1.5rem);
    .sorting {
        display: flex;
        align-items: center;
        line-height: 1.25rem;
        gap: 0.5rem;
        font-size: 1rem;

        a {
            color: var(--comments-sorting-link-color);
            padding-left: 0.25rem;
            padding-right: 0.25rem;

            &:first-child {
                margin-left: 0.5rem;
            }

            &.active {
                color: var(--comments-sorting-link-active-color);
            }
        }
    }

    .comm {
        overflow: hidden;
        display: none;

        &.show {
            display: block;
        }

        .vote {
            text-align: center;
            float: left;
            width: 1.5rem;

            li {
                height: 1.25rem;
                line-height: 1.25rem;

                .pos,
                .neg,
                .nil {
                    font-size: 0.875rem;
                    font-weight: 700;
                    color: var(--comments-sorting-position-color);
                }

                &.upvote,
                &.downvote {
                    height: 1.5rem;

                    .i_svg {
                        top: 0;
                        width: 100%;
                        height: 100%;
                        padding: 0.375rem;
                    }
                }
            }

            //&.act_up {
            //  .i_chevron-up {
            //      background-color: fade(@front, 20);
            //  }
            //}
            //
            //&.act_down {
            //  .i_chevron-down {
            //      background-color: fade(@back-l4, 20);
            //  }
            //}
        }

        .c_body {
            padding: 0.25rem;
            margin-left: 2.25rem;
            width: auto;

            .comment-by {
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;

                .name {
                    color: var(--comments-name-color);
                    font-weight: 700;
                    line-height: 1.25rem;
                    font-size: 1rem;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    a {
                        color: inherit;
                    }
                }

                .d {
                    color: var(--comments-time-color);
                    font-size: 0.875rem;
                    flex-shrink: 0;
                }
            }

            .medals {
                display: inline-block;
            }

            a {
                color: var(--comments-link-color);
            }

            .hidden {
                display: inline-block;
                padding: 0.125rem 0.3125rem;
                background-color: rgba(0, 0, 0, 0.3);
                cursor: pointer;
                border-radius: 0.25rem;
            }

            .emoji {
                display: inline-block;
                width: 1.125rem;
                height: 1.125rem;
            }

            .comment_text_body {
                margin-top: 0.25rem;
                color: var(--comments-text-color);
                font-size: 0.875rem;
                line-height: 1.25rem;
            }

            .buttons-wrapper {
                margin-top: 0.5rem;
                .space-horizontal(0.5rem);

                span.reply {
                    cursor: pointer;
                    color: var(--comments-buttons-color);
                    display: inline-block;
                    font-size: 0.875rem;

                    .i_svg {
                        margin-right: 0.25rem;
                    }
                }
            }
        }

        .side {
            width: 9.375rem;
            float: left;
        }

        .comment_form {
            padding-left: 2.1875rem;
        }

        .replies {
            margin: 1rem 0 0 1.25rem;
        }
    }

    .show-more-button {
        background-color: transparent;
    }
}

html.desktop {
    .comments {
        .comm {
            display: block;
        }

        .show-more-button {
            &.ft-button-bordered {
                display: none;
            }
        }
    }

    .comment_form {
        padding: 0;
        border-radius: 0;
        background: none;

        .text-area {
            min-height: 2.5rem;
        }
    }
}
