// main:style.less

.edition-form {
  h2 {
    color: @modal-title-color;
    background: none;
    padding: 0;
    text-align: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  form {
    margin-top: 1rem;

    .country_list {
      margin: 0;
      .flex-display();
      .flex-wrap(wrap);
      .justify-content(center);
      row-gap: 1.5rem;

      li {
        .flex-basis(20%);

        img {
          width: 2rem;
          height: 1.25rem;
          display: block;
          margin: 0 auto;
          cursor: pointer;
          .rounded(0.25rem);

          &.ww {
            width: auto;
          }
        }
      }
    }

    .country-select-wrapper {
      margin-top: 1rem;

      li {
        &:last-child {
          margin-top: 1.5rem;
        }
      }

      button {
        width: 100%;
      }

      .text-field {
        height: 2.5rem;
      }
    }
  }
}
