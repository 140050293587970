// main:style.less

.contact-wrapper {
    p {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    form {
        ul {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        textarea {
            resize: vertical;
            height: 2.5rem;
        }
    }
}

html.desktop {
    .contact-wrapper {
        form {
            ul {
                flex-direction: row;
                flex-wrap: wrap;

                li {
                    .adjusted-flex-basis(2, 1rem);
                }
            }
        }
    }
}
