a.go {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.5rem 1rem;
    background-color: var(--go-button-bg-color);
    color: var(--go-button-color);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    border-radius: 0.25rem;

    .text {
        strong {
            font: inherit;
        }
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
            color: var(--go-button-icon-color);

            .i_svg {
                height: 1.25rem;
                width: 1.25rem;
                top: 0;
            }
        }

        &:last-child {
            color: inherit;
            margin-left: auto;
            margin-right: 0;

            .i_svg {
                top: 0;
                width: 100%;
                height: 0.75rem;
            }
        }
    }
}

.related_keywords {
    .keywords-wrapper {
        display: flex;
        column-gap: 1rem;
        row-gap: 0.5rem;
        flex-wrap: wrap;

        a {
            .adjusted-flex-basis(2, 1rem);
            display: block;
            font-size: 1rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            strong {
                font: inherit;
            }
        }
    }
}

#browse_new {
    .channel_result {
        overflow: hidden;

        .ps {
            display: inline-block;
            width: 120px;
            text-align: center;
            color: @front-l4;
            margin: 10px;

            img {
                .rounded(5px);
            }
        }
    }

    div.pornstar_search {
        width: 150px;
        margin-bottom: 10px;
        text-align: center;

        a.image {
            display: block;
            position: relative;
            color: @front-l4;

            img {
                width: 100%;
                .rounded(5px);
            }

            span {
                position: absolute;
                display: inline-block;
                padding: 5px;
                background-color: @back-d4;
                color: @text;
                .opacity(0.8);
                .rounded(5px);
                font-size: 0.8em;

                &.views {
                    left: 2px;
                    bottom: 25px;
                }

                &.videos {
                    right: 2px;
                    bottom: 25px;
                }
            }
        }

        a.title {
            display: block;
            text-align: center;
            color: @front-l4;
            font-size: 1.3em;
            padding: 5px 0;
        }
    }

    div.categories {
        overflow: hidden;
        margin-bottom: 30px;
        a {
            width: 16%;
            height: 150px;
            float: left;
            font-size: 2.2em;
            position: relative;
            color: @text-l1;
            display: block;
            overflow: hidden;
            margin: 0.2%;
            .rounded(10px);
            .drop-shadow(@x-axis: 0, @y-axis: 2px, @blur: 1px, @alpha: 0.6);
            .text-shadow(@x-axis: 0, @y-axis: 2px, @blur: 2px, @alpha: 1);

            img {
                width: 100%;
                position: absolute;
            }

            span {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 100%;
                background-color: rgba(0, 0, 0, 0.8);
                vertical-align: middle;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover {
                span {
                    background-color: rgba(0, 0, 0, 0.9);
                    color: @front-l4;
                }
            }
        }
    }

    ul.keyword_list {
        margin: 20px;
        li {
            display: inline-block;

            a {
                display: block;
                font-size: 1.2em;
                color: @front-l4;
                padding: 4px 8px;
                border: 1px solid @back;
                .rounded(5px);
                margin: 2px;

                &:hover {
                    background-color: @front-d4;
                    color: @text;
                }
            }
        }
    }

    &.video_removed_page {
        .message_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1.5rem;
            background-color: var(--modal-inner-content-bg-color);
            border-radius: 0.5rem;
        }

        .main_content_title {
            margin-bottom: 1rem;
        }
    }
}

#search_prohibited {
    display: flex;
    align-items: center;
    flex-direction: column;

    .message {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        gap: 1rem;
        max-width: 35rem;
        line-height: 1.5rem;
    }

    .icon {
        width: fit-content;
        height: fit-content;

        .i_svg {
            width: 3rem;
            height: 3rem;
            color: var(--search-prohibited-warning-icon-color);
        }
    }
}

html.desktop {
    .related_keywords {
        .keywords-wrapper {
            width: 50%;
        }
    }

    #browse_new {
        &.video_removed_page {
            .message_wrapper {
                padding: 1.5rem;
            }
        }
    }
}
