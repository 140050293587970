.tos_container {
    a {
        text-decoration: none;
    }

    ul {
        list-style: unset;
        margin: 1rem 0 1rem 0;

        li {
            margin-left: 2.298rem;
            padding-left: 0.7rem;
        }
    }

    ol {
        margin: 1rem 0 1rem 0;

        li {
            margin-left: 2.6125rem;
            padding-left: 0.3875rem;
        }
    }
}
