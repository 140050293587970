// main: style.less
@import '../../common/styles/general';

html {
    background-color: @back-d3;
}

body {
    color: @text;
    font-size: 13px;
    min-height: 100vh;
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    display: block;
    opacity: 1;
    overflow: hidden;
    font-size: calc(10px + (21 - 10) * ((100vw - 1000px) / (2600 - 1000)));
}

hr {
    height: 1px;
    color: @back-d1;
    background: @back-d1;
    font-size: 0;
    border: 0;
    clear: both;
    margin: 5px 0;
}

img {
    -ms-interpolation-mode: bicubic;
}

#svg_holder {
    display: none;
}

.main-container {
    margin-top: 1.5rem;
}

@-webkit-keyframes flash {
    0%,
    100%,
    50% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes flash {
    0%,
    100%,
    50% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

textarea:focus::-webkit-input-placeholder,
input:focus::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.2);
}
textarea:focus:-moz-placeholder,
input:focus:-moz-placeholder {
    /* FF 4-18 */
    color: rgba(0, 0, 0, 0.2);
}
textarea:focus::-moz-placeholder,
input:focus::-moz-placeholder {
    /* FF 19+ */
    color: rgba(0, 0, 0, 0.2);
}
textarea:focus:-ms-input-placeholder,
input:focus:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.2);
}

.ads_disclaimer {
    display: block;
    overflow: hidden;
    clear: both;
    color: @back-l4;
    text-align: center;
    padding: 3px 0;
}

.channels_scroll_list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;

    a.ps {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        text-align: center;
        line-height: 1.25rem;
        font-size: 0.75rem;

        img {
            display: block;
            margin-bottom: 0.25rem;
            border-radius: 0.25rem;
        }
    }

    a.ps:nth-child(n + 7) {
        display: none;
    }

    a.as {
        display: inline-block;
        color: @text;
        text-align: center;
        font-size: 0.9em;
        padding: 3px 10px 3px 1px;
        background-color: @back-d4;
        margin: 2px;
        .rounded(5px);

        img {
            display: inline-block;
            width: 40px;
            height: 40px;
            .rounded(5px);
            vertical-align: middle;
            background-color: @back;
        }

        &:hover {
            background-color: @front-d4;
        }
    }
}

.medal_hoder {
    span {
        display: inline-block;
        position: relative;

        &.m1 {
            color: @front-l4;
        }

        &.m2 {
            color: @text;
        }

        &.m3,
        &.m4 {
            color: #daa736;
        }

        &.ms {
            padding: 2px 4px;
            background-color: @back-d1;
            .rounded(5px);
            color: @text;
            svg {
                fill: @front-l2;
            }
        }

        img {
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

@keyframes fa-blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fa-blink {
    -webkit-animation: fa-blink 2s linear infinite;
    -moz-animation: fa-blink 2s linear infinite;
    -ms-animation: fa-blink 2s linear infinite;
    -o-animation: fa-blink 2s linear infinite;
    animation: fa-blink 2s linear infinite;
}

.new_design_survey {
    display: block;
    padding: 2px 0;
    text-align: center;
    color: @front-l4;
}

.footer_bbm {
    text-align: center;
    margin: 10px 0;

    div {
        min-width: 1px;
        min-height: 1px;
        display: inline-block;
        margin: 0 5px;
    }
}

.ptgncdn_holder {
    .rounded(3px);
    display: block;
}

.ptgncdn_holder_footer {
    margin: 5px;
}

.ptgncdn_holder_ntv {
    margin: 0 auto;
    .rounded(3px);
}

#dab_5151_ab {
    height: 1px;
    width: 1px;
    position: absolute;
    left: -999em;
    top: -999em;
}

#interstitial_div_v3 {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: @back-d3;
    z-index: 10000;
    font-size: 12px;

    .top {
        height: 6vh;
        width: 100vw;
        padding: 0.5vh;
        line-height: 6vh;

        img {
            height: 5vh;
            float: left;
            display: block;
            cursor: pointer;
        }

        em {
            height: 5vh;
            float: left;
            display: block;
            line-height: 5vh;
            padding-left: 5px;
            font-style: normal;
            text-transform: uppercase;
            color: @back-l4;
        }

        span {
            float: right;
            height: 5vh;
            line-height: 5vh;
            width: 20vw;
            text-align: center;
            background-color: @front-d3;
            font-size: 1.3em;
            cursor: pointer;
            text-shadow: 0px 1px 0px #854629;
            text-transform: uppercase;
            font-weight: bold;
            .rounded(3px);

            svg {
                fill: @front-l4;
            }

            &:hover {
                background-color: @front-d4;
            }
        }
    }

    iframe {
        height: 93vh;
        width: 100vw;
        background-color: @back-d3;
        border: 0;
        display: block;
        background: url(/static/mobile/Images/preloader.gif) center center no-repeat;
    }
}

#vast_tester {
    height: 70vh;

    .controls {
        margin: 20px;

        .vast_url {
            height: 35px;
            line-height: 35px;
            width: 80vw;
            display: block;
            margin-bottom: 10px;
            background-color: @text-d1;
            border: 2px solid @back-d4;
        }

        button {
            padding: 10px;
            cursor: pointer;
        }
    }

    #video_container {
        width: 90vw !important;
        height: 50vh;
    }

    #vast_video_player {
        width: 90vw !important;
        height: 50vh;
    }
}

/* Icon pulse */
.fa-fadeinout {
    display: inline-block;
    -moz-animation: pulse 2s infinite linear;
    -o-animation: pulse 2s infinite linear;
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 5px;
}

::-webkit-scrollbar:horizontal {
    height: 1px;
}

::-webkit-scrollbar-thumb {
    background-color: @back-d1;
    border-radius: 10px;
    border: 0 none;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

@keyframes bounce {
    0% {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        opacity: 0.8;
    }
    100% {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        opacity: 0.8;
    }
    100% {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
    }
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader {
    display: inline-block;
    font-size: 10px;
    text-indent: -9999em;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background: transparent;
    background: -moz-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: -webkit-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: -o-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: -ms-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: linear-gradient(to right, @front-d4 10%, fade(@front-d4, 0) 42%);
    position: relative;
    -webkit-animation: load3 0.7s infinite linear;
    animation: load3 0.7s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: @front-d4;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: @back-d3;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@media all and (min-width: @breakpoint-xl) {
    .results {
        .channels_scroll_list {
            grid-template-columns: repeat(10, 1fr);

            a.ps:nth-child(-n + 10) {
                display: flex;
            }

            a.ps:nth-child(n + 11) {
                display: none;
            }
        }
    }
    .videos {
        .channels_scroll_list {
            grid-template-columns: repeat(10, 1fr);

            a.ps:nth-child(-n + 12) {
                display: flex;
            }

            a.ps:nth-child(n + 13) {
                display: none;
            }
        }
    }
}
