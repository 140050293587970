// main:style.less
.remodal {
    background-color: var(--modal-inner-content-bg-color);
    border: none;
    border-radius: 0.5rem;
    padding: 1rem;

    h4 {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 700;
        color: var(--modal-title-color);
        padding: 0 1rem;

        .i_svg {
            fill: rgba(255, 255, 255, 0.5);
        }
    }

    .t {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
    }

    .remodal-close {
        width: 1.5rem;
        height: 1.5rem;
        right: 0.5rem;
        margin-top: 0;
        top: 0.5rem;
        left: auto;
        .flex-display();
        .align-items(center);
        .justify-content(center);

        .i_svg {
            position: static;
            fill: var(--modal-close-button-color);
            height: 0.75rem;
            width: 0.75rem;
        }
    }

    &.auth-remodal {
        padding: 0;
    }
}

.download-remodal {
    padding: 1.5rem;
    max-width: 25rem;

    .success {
        color: var(--success-color);
    }

    .video-download-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        > p.ft-button-bordered {
            width: 100%;
        }

        p > #icon > svg {
            top: 0.125rem;
        }
    }

    #captcha_holder {
        width: 19rem;
        margin: 0 auto;
    }

    h4 {
        padding-bottom: 0.5rem;
    }

    .download-list {
        overflow: hidden;
        .space-vertical();

        .pl {
            display: none;
        }

        .pl2 > #d-icon > svg {
            top: 0.125rem;
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    .download-promo {
        padding: 0 1.5rem;
        left: 0;
        z-index: 100;
        background-color: var(--modal-inner-content-bg-color);
        display: none;
    }
}

.ml-challenge-remodal {
    .ml_challenge_captcha {
        .image_holder {
            padding: 10px;

            p {
                padding: 50px;
            }

            img {
                .rounded(5px);
            }
        }

        fieldset {
            padding-bottom: 20px;
            label {
                width: 23%;
                display: inline-block;
                height: 30px;
                line-height: 30px;
                text-align: left;
                background-color: @back-d4;
                margin: 2px;

                &:hover {
                    background-color: @back-d1;
                }

                input {
                    margin-right: 7px;
                    margin-left: 3px;
                }
            }
        }

        .ml_challenge_captcha_button {
            display: none;
        }
    }

    .ml_challenge_captcha_2 {
        display: none;
    }

    #ml_challenge_cycler {
        position: relative;
    }
    #ml_challenge_cycler img {
        position: absolute;
        z-index: 1;
        left: 134px;
        top: 0;
        bottom: 0;
    }
    #ml_challenge_cycler img.active {
        z-index: 3;
    }
}
.setting-remodal {
    .setting {
        padding-bottom: 5px;
        overflow: hidden;
        text-align: left;
        width: 290px;
        margin: 10px auto;

        span {
            display: block;
            height: 26px;
            line-height: 26px;
            float: left;
            padding-right: 10px;
            width: 120px;
        }
    }
}

.flag-remodal {
    width: 25rem;
    padding: 1.5rem;

    h4 {
        color: var(--modal-title-color);
    }

    p.t {
        color: var(--modal-text-color);
        font-weight: 400;
        font-size: 0.875rem;
        padding: 1rem 0;
        line-height: 1.5em;
    }

    .flag {
        padding: 0 0 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }

    .cancel_row {
        padding: 1rem 0 0 0;
    }

    .sign,
    .report {
        padding: 0 1.875rem;
        height: 2.875rem;
        line-height: 2.875rem;
    }

    .report {
        opacity: 50%;
        padding: 0 3.125rem;
    }

    .report.active {
        opacity: 100%;
        background-color: @front-d4;
    }
}

.auth-remodal {
    width: fit-content;
    max-width: 50rem !important;

    .form-holder {
        background-color: transparent;
        overflow: hidden;
        padding: 0;
        margin: 0px;
        .rounded(0);
        font-size: 0.9em;
        text-align: left !important;

        a {
            color: @front-l4;
        }
    }
}

#language-remodal,
#gender-remodal {
    padding-top: 2.5rem;
}

#language-remodal,
#gender-remodal,
#edition-remodal,
.broadcast-remodal,
.message-remodal {
    max-width: 25rem;

    .ft-button {
        width: 100%;
    }
}

.broadcast-remodal {
    form {
        margin-top: 1rem;
    }

    .ft-button {
        width: 100%;
    }
}

.message-remodal {
    padding: 1.5rem;

    .text-area {
        margin-top: 1rem;
        font-size: 0.825rem;
    }

    .submit-container {
        display: block;
        padding-top: 1.5rem;
    }

    h4 {
        font-size: 1rem;
    }
}
