// main:style.less
.nav-bar {
    >ul {
        >li {
            float: left;
            padding: 5px 10px;
            margin: 0 3px;
            font-size: 1.2em;
            background-color: @back-d4;

            >a {
                display: block;
                color: @back-l4;
            }

            &:hover {
                background-color: @front-d4;

                >a {
                    color: @text !important;
                }
            }
        }
        >li.h {
            color: @back-l4;
            position: relative;
            cursor: pointer;
            min-width: 200px;
            text-align: center;

            ul {
                position: absolute;
                z-index: 1000;
                background-color: @back;
                width: 100%;
                top: 30px;
                left:0;
                display: none;
                text-align: left;

                li {
                    a {
                        display: block;
                        padding: 5px;
                        color: @text;

                        &:hover {
                            background-color: @back-d4;
                        }

                        span {
                            float: right;
                            color: @back-l4;
                        }
                    }
                }

                li.active a {color: @front; background-color: @back-d3;}
            }

            &:hover {
                background-color: @front-d4;
                color: @text;
                ul {
                    display: block;
                }
            }
        }

        >li.active a {color: @front;}
        >li.active {color: @front;}

        >li.hd {
            float: right;
        }
    }
}

.nav-bar-left, .nav-bar-left-live {

  #time_slider {
    margin: 10px;
  }

  #time_slider_value {
    height: 25px;
    li {
      display: inline-block;
      font-size: 1.25em;
      color: @back-l4;

      &.min {
        float: left;
        padding-left: 5px;
      }

      &.max {
        float: right;
        margin-right: -8px;
      }
    }
  }

  >ul {
    padding: 5px;

    >li {
      &.h {
        color: @back-l4;
        padding-top: 10px;
        font-size: 1.25em;

        &:first-child {
          padding-top: 0;
        }
      }

      &.hd {
        display: inline-block;
        text-align: center;
        padding: 3px;
        background-color: rgba(0,0,0,0.2);
        margin-top: 5px;
        .rounded(5px);
        font-size: 1em;
      }

      &.sub {
        padding-left: 25px;
      }

      a {
        color: @text-d1;
        padding: 2px 0;
        display: block;
        font-size: 1.15em;

        span {
          float: right;
          color: @back-l2;
        }

        &:hover {
          color: @text-l1;
        }
      }

      &.active {
        a {
          color: @front-l4;
        }
      }
    }
  }


}
