/* Shorts iframe */
#shorts-frame {
  width: 100%;
  margin: 10px 0;
  height: calc(355px + 1.2rem);

  ins {
    height: 100%;
  }
}
@media screen and (max-width: 1024px) {
  #shorts-frame {
    height: calc(275px + 1.2rem);
  }
}
@media screen and (max-width: 768px) {
  #shorts-frame {
    height: calc(230px + 1.2rem);
  }
}
