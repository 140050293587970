// main:style.less

footer {
    display: flex;
    flex-direction: column;
    padding: 1rem 2.5rem 1rem 2.5rem;
    background-color: var(--footer-bg-color);
    gap: 1rem;

    .logo {
        display: block;
        width: 10.3125rem;
    }

    .nav_container {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        &.links {
            flex-direction: column;
        }
    }

    section {
        &.footer_section {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding-bottom: 1rem;
            border-bottom: 0.0625rem solid var(--footer-login-separator-color);
        }
    }

    nav {
        display: flex;
        justify-content: center;
        gap: 1rem;

        a {
            color: var(--footer-nav-text-color);
            font-size: @footer-font-size;
            line-height: 1.25rem;
        }

        &.secondary {
            a {
                color: var(--footer-highlighted-text-color);
            }
        }
    }

    .copyright {
        color: var(--footer-highlighted-text-color);
        font-size: @footer-font-size;
        line-height: 1.25rem;
    }

    @media all and (min-width: @breakpoint-xl) {
        .nav_container.links {
            flex-direction: row;
        }

        nav {
            justify-content: flex-end;
        }
    }
}
