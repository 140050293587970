// main:style.less

#upload {
    .upload-title {
        padding-top: 0.2rem;
        padding-bottom: 1rem;
        padding-left: 0;
    }

    .custom-file-upload {
        height: 1.5rem;
        width: 3.75rem;
        font-size: 0.75rem;
        cursor: pointer;
        color: var(--upload-button-text-color);
        background-image: linear-gradient(
            var(--upload-button-gradient-color-start),
            var(--upload-button-gradient-color-end)
        );
    }

    .check-url-button {
        width: 5rem;
        padding: 0;
        margin-top: 0.625rem;
    }

    #captcha_holder {
        overflow: hidden;
        height: 11.25rem;
        text-align: center;
        width: 19rem;
        margin: 0 auto;
        margin-top: 0.625rem;
    }

    .type_switcher {
        .upload_options {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 1rem;
            gap: 1rem;

            a {
                font-weight: 400;
                display: flex;
                width: 100%;
                max-width: 25rem;
                padding: 0.1875rem 0.5rem;
                border: 0.0625rem solid var(--upload-inactive-option-border-color);

                &.active {
                    border: 0.0625rem solid var(--primary-btn-border-color);
                    border-radius: 0.25rem;
                }
            }
        }
    }

    .top_details {
        margin: 0 auto;

        .title {
            display: flex;
            justify-content: center;
            padding: 1rem 0;
        }

        .upload_wrapper {
            margin: 0 auto;
            max-width: 25rem;
            padding: 0.5rem;
            background-color: var(--textarea-bg-color);
            border-radius: 0.25rem;

            .upload_field {
                background-color: var(--textarea-bg-color);
                flex-grow: 1;
                display: flex;
                align-items: center;
                input[type='file'] {
                    display: none;
                }

                .file-selector-info {
                    color: var(--upload-no-file-selected-text-color);
                    font-size: 0.825rem;
                    padding-left: 0.5rem;
                }
            }
        }

        .error {
            text-align: center;
            color: var(--form-error-color);
            padding-top: 0.3125rem;
        }

        .url_upload {
            display: none;

            .form-field {
                height: 1.5rem;
            }

            #check_url_button {
                display: none;
            }
        }
    }

    .form-container {
        display: none;
        .input_container_wrapper {
            display: flex;
            flex-direction: column;
        }

        .input_container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .input_gap {
            gap: 1rem;
        }

        .input_group {
            max-width: 25rem;
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }

        .input_button {
            width: 100%;
            background-image: linear-gradient(
                var(--upload-button-gradient-color-start),
                var(--upload-button-gradient-color-end)
            );
            color: var(--upload-button-text-color);

            .i_svg {
                color: var(--upload-button-text-color);
            }
        }

        .input_label {
            align-self: flex-start;
            margin-bottom: 0.5rem;
        }

        .input_label_asterix {
            color: var(--upload-star-color);
        }

        .text-info {
            align-self: flex-start;
            margin-top: 0.25rem;
            font-size: 0.625rem;
        }
    }

    .title_form {
        font-size: 0.625rem;
        margin: 0 auto;
        max-width: 25rem;
        padding-top: 0.25rem;
        padding-bottom: 0.375rem;
        color: var(--upload-major-storage-text-color);
    }

    .input-select-option {
        font-size: 0.875rem;
        color: var(--upload-default-option);
    }

    .progress_stats {
        padding-top: 0.25rem;

        span {
            display: inline-block;
            padding-top: 0.25rem;
            color: var(--upload-major-storage-text-color);
        }
    }

    .custom_url_input {
        border: none;
        background-color: var(--upload-progress-background-color);
        width: 100%;
        color: var(--upload-url-text-color);
        padding: 0.203125rem 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition:
            background-color 600000s 0s,
            color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    }

    .upload_icon {
        font-weight: bold;
        font-size: 0.7rem;
    }

    label.error {
        color: var(--form-error-color);
        font-size: 0.625rem;
        align-self: flex-start;
    }

    input.error {
        border: 0.0625rem solid var(--form-error-color);
    }

    .been_uploaded {
        color: var(--success-color);
        padding-top: 0.5rem;
        opacity: 0;
    }

    .check-boxes {
        display: flex;
        width: 100%;
        gap: 0.5rem;
        flex-wrap: wrap;

        label {
            .adjusted-flex-basis(2, 0.5rem);
            font-size: 0.875rem;
            text-align: center;

            input {
                display: none;
            }

            &.is_selected {
                border-radius: 0.25rem;
                box-shadow: inset 0 0 0 0.0625rem var(--primary-btn-border-color);
            }
        }
    }

    .radio-boxes {
        display: flex;
        width: 100%;
        gap: 0.5rem;

        label {
            font-size: 0.875rem;
            text-align: center;

            input {
                display: none;
            }

            &.is_selected {
                border-radius: 0.25rem;
                box-shadow: inset 0 0 0 0.0625rem var(--primary-btn-border-color);
            }
        }
    }

    .text-field {
        resize: none;
    }

    .progress {
        position: relative;
        height: 2.5rem;
        background-color: var(--upload-progress-background-color);
        width: 100%;
        border-radius: 0.25rem;

        .bar {
            background-color: var(--upload-progress-color);
            width: 0%;
            height: 2.5rem;
            border-radius: 0.25rem;
        }

        .percent {
            position: absolute;
            display: block;
            top: 0.625rem;
            font-size: 1.125rem;
            margin: 0 auto;
            width: 100%;
            text-align: center;
        }
    }
}

.magic_suggest_input {
    .ms-ctn {
        border: none;
        background-color: var(--input-bg-color);
    }

    .ms-ctn input {
        font-size: 1rem;
        margin: 0;
        padding: 0.03625rem 0.75rem;
    }

    .ms-res-ctn {
        background-color: var(--upload-progress-background-color);
        border: 0.0625rem solid var(--upload-inactive-option-border-color);
        border-radius: 0.25rem;
    }

    .ms-res-ctn .ms-res-item {
        color: var(--upload-suggestion-text-color);
        background-color: transparent;
    }

    .ms-res-ctn .ms-res-item-active {
        background-color: var(--upload-suggestion-background-active);
        em {
            color: var(--upload-suggestion-matched-word-active) !important;
        }
        .defaulthover;
    }

    .ms-sel-ctn .ms-sel-item {
        border: none;
    }

    .ms-sel-ctn .ms-sel-item:hover {
        border: none;
    }

    .ms-ctn .ms-sel-item {
        background: var(--upload-suggestion-background);
        padding: 0.1875rem 0.5rem;
        border-radius: 0.25rem;
        border: none;
        margin-top: 0;
        margin: 0;
    }

    .ms-ctn .ms-sel-item:hover {
        border: none;
    }

    .ms-ctn-focus .ms-sel-item:hover {
        border: none;
    }

    .ms-res-ctn .ms-res-item em {
        font-style: normal;
        color: var(--upload-suggestion-matched-word);
        background: none;
        .defaulthover;
    }

    .ms-sel-ctn .ms-sel-item .ms-close-btn {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAOCAYAAADjXQYbAAAAAXNSR0IArs4c6QAAADhJREFUKFNjZGBgYHiZWv1ffHYrI4iNzGcEScAEQQqQ+WDVyALIClGMQpYAsfHrJMpOrK4dPl4BAE5JUy9F6DvxAAAAAElFTkSuQmCC);
    }

    .ms-helper {
        bottom: -1.5rem;
    }
}

html.desktop {
    #upload {
        .upload-title {
            text-align: center;
        }

        .type_switcher {
            .upload_options {
                justify-content: center;
                flex-direction: row;
                gap: 1.5rem;

                a {
                    max-width: 11.75rem;
                }
            }
        }

        .form-container {
            padding: 2rem;
        }

        .input_container {
            .input_group {
                max-width: none;
            }

            &.row_direction {
                flex-direction: row;
                .title {
                    margin-bottom: 0.9375rem;
                }
            }

            .radio-boxes {
                gap: 0.5rem;
            }

            .check-boxes {
                width: 100%;
                gap: 1rem;
                flex-wrap: wrap;

                label {
                    .adjusted-flex-basis(5, 1rem);
                }
            }
        }

        #upload_form_button {
            align-self: flex-start;
            width: 32%;
        }
    }

    @media all and (min-width: @breakpoint-xl) {
        #upload {
            .input_container {
                .check-boxes {
                    label {
                        .adjusted-flex-basis(7, 1rem);
                    }
                }
            }
        }
    }
}
