#search_v2.profile_page {
    .i_svg {
        top: unset;
    }

    .top {
        margin-bottom: 1rem;

        .p {
            display: flex;
            align-items: center;

            .avatar {
                max-height: 3.5rem;
            }

            .info {
                overflow: hidden;
                padding: 0.25rem 0 0.25rem 0.5rem;

                .details {
                    display: flex;
                    align-items: center;
                    color: var(--profile-details-color);
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 1rem;

                    span {
                        margin-right: 0.5rem;
                        text-transform: capitalize;
                    }

                    .divider {
                        line-height: 0.75rem;
                        border-right: 1px solid var(--profile-details-icon-color);
                        padding-right: 0.5rem;
                    }

                    .flag {
                        height: 1em;
                        margin-right: 0.5rem;
                        width: unset;
                        border-radius: 0;
                    }
                }

                .profile_name {
                    padding: 0.25rem 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 1.5rem;
                    color: var(--profile-text-color);
                }
            }
        }

        .i {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            margin-top: 1rem;

            .ft-button-bordered,
            .ft-button {
                flex-grow: 1;
                flex-basis: 0;

                svg {
                    vertical-align: text-bottom;
                }
            }

            .ft-button-bordered {
                svg {
                    fill: var(--profile-contrast-text-color);
                }
            }
        }
    }

    nav.top {
        margin-left: -1rem;
        margin-right: -1rem;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        overflow-x: scroll;
        overflow-y: hidden;
        width: auto;
        white-space: nowrap;

        a {
            text-transform: capitalize;
            padding: 0.25rem 0;
            margin-right: 1.5rem;
            color: var(--profile-text-color);
            flex-grow: 1;
            flex-basis: 0;
            font-size: 1rem;
            line-height: 1.5rem;
            text-align: left;

            &:first-child {
                margin-left: 1rem;
            }

            &:last-child {
                margin-right: 1rem;
            }

            &.active {
                border-bottom: 0.125rem solid var(--profile-contrast-color);
            }

            &.empty {
                opacity: 0.2;
            }

            span {
                color: var(--profile-contrast-text-color);
                display: block;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.5rem;
            }
        }
    }

    .data {
        .nav_quick_top {
            .filter {
                margin: 0.5rem 0;
            }
        }

        .gap {
            height: 1.5rem;
        }

        h2 {
            line-height: normal;
            height: auto;
            padding: 0;
            margin: 0 0 1rem;

            .more {
                margin-left: auto;

                svg {
                    width: 0.7rem;
                }
            }

            a {
                color: var(--profile-text-color);
            }
        }

        .comment_list {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            gap: 0.5rem;

            a {
                .adjusted-flex-basis(3, 0.5rem);
                display: block;
                background-color: var(--profile-content-bg-color);
                height: 5.125rem;
                padding: 0.5rem;
                color: var(--profile-text-color);
                overflow: hidden;
                border-radius: 0.25rem;

                span {
                    color: var(--profile-contrast-text-color);
                    display: block;
                    font-weight: 700;
                    font-size: 0.625rem;
                    line-height: 1rem;
                }

                p {
                    font-weight: 400;
                    font-size: 0.6875rem;
                    line-height: 1rem;
                }

                img {
                    width: 1.125rem;
                }
            }
        }

        .main_content_title {
            a {
                display: flex;
                align-items: center;
            }
        }

        .subscribers_list {
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            gap: 0.25rem;

            a {
                .adjusted-flex-basis(4, 0.25rem);
                padding: 0 0.75rem;
                height: 6.125rem;
                overflow: hidden;
                text-align: center;

                span {
                    color: var(--profile-contrast-text-color);
                    display: block;
                    line-height: 1.25rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 0.875rem;

                    &.d {
                        font-size: 0.75rem;
                        line-height: 1.125rem;
                        color: var(--profile-details-color);
                    }
                }

                img {
                    max-width: 3.5rem;
                    max-height: 3.5rem;
                    border-radius: 3.5rem;
                }
            }
        }

        .channels_list {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            overflow: hidden;

            a {
                .adjusted-flex-basis(4, 0.5rem);
                text-align: center;
                border-right: 0.25rem;

                span {
                    color: var(--profile-contrast-text-color);
                    font-size: 0.75rem;
                    line-height: 1.25rem;
                    display: block;
                }

                img {
                    width: 5rem;
                    border-radius: 0.25rem;
                    border-right: 0.25rem;
                }
            }
        }

        .similar {
            height: auto;
            overflow-x: scroll;
            overflow-y: hidden;
            width: auto;
            white-space: nowrap;
            text-transform: capitalize;
            padding-bottom: 0.25rem;

            a.image {
                display: inline-block;
                text-align: center;
                color: @text-d1;
                font-size: 0.8em;
                vertical-align: middle;
                overflow: hidden;
                width: 5rem;

                img {
                    border-radius: 0.25rem;
                    display: block;
                    margin-bottom: 0.25rem;
                    background-color: @back-d4;
                    width: 90%;
                }
            }
        }

        .achievements {
            padding-top: 0.5rem;

            .achievements_list {
                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
            }

            .achievement {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                flex-basis: 100%;

                .details {
                    display: flex;
                    color: var(--profile-details-color);

                    span {
                        font-size: 1rem;
                        font-weight: 700;
                        line-height: 1.5rem;
                    }

                    .l {
                        border-right: 1px solid var(--profile-details-icon-color);
                        padding-right: 0.75rem;
                        margin-right: 0.75rem;
                    }

                    .m {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                    }
                }

                .description {
                    color: var(--profile-text-color);
                    font-size: 1rem;
                    line-height: 1.5rem;
                }

                .progress {
                    --progress-height: 2.5rem;

                    height: var(--progress-height);
                    line-height: var(--progress-height);
                    position: relative;
                    text-align: center;
                    background-color: var(--profile-secondary-bg-color);
                    border-radius: 0.25rem;

                    span {
                        z-index: 1;
                        position: relative;
                    }

                    .inner {
                        position: absolute;
                        height: var(--progress-height);
                        left: 0;
                        top: 0;
                        background-color: var(--profile-contrast-color);
                        border-radius: 0.25rem;
                        z-index: 0;
                    }
                }

                .stats {
                    div {
                        line-height: 1.25rem;
                    }

                    .points {
                        color: var(--profile-text-color);
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        font-weight: 400;

                        strong {
                            font-weight: 700;
                        }
                    }

                    .next_level {
                        color: var(--profile-details-color);
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                    }
                }
            }
        }

        .empty_feed {
            padding: 1.25rem;
            text-align: center;
            font-size: 1.5em;
        }
    }
}

#profile_main {
    h2 {
        padding: 1.875rem 0.3125rem 0.625rem 0.3125rem;
    }

    .profiles {
        clear: both;
        overflow: hidden;

        a.as {
            display: inline-block;
            color: @text;
            font-size: 0.9em;
            padding: 3px 10px 3px 1px;
            background-color: @back-d4;
            margin: 2px;
            border-radius: 5px;
            width: 13.6%;

            img {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 5px;
                vertical-align: middle;
                background-color: @back;
            }

            &:hover {
                background-color: @front-d4;
            }
        }
    }
}

html.desktop {
    #search_v2.profile_page {
        .top {
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;

            .p,
            .i {
                .adjusted-flex-basis(2, 1rem);
            }

            .i {
                margin-top: 0;
            }
        }

        nav.top {
            a {
                text-align: center;
            }
        }

        .data {
            .subscribers_list {
                gap: 0.5rem;

                a {
                    .adjusted-flex-basis(5, 0.5rem);
                }
            }

            h2 {
                .icon {
                    svg {
                        fill: var(--profile-icon-color);
                    }
                }
            }

            .comment_list {
                a {
                    .adjusted-flex-basis(2, 0.5rem);
                    padding: 1rem;
                    span,
                    p {
                        font-size: 0.875rem;
                    }

                    span {
                        line-height: 1rem;
                    }

                    p {
                        line-height: 1.25rem;
                    }
                }
            }

            .channels_list {
                a {
                    .adjusted-flex-basis(5, 0.5rem);

                    img {
                        width: 100%;
                    }
                }
            }

            .achievements {
                .achievements_list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }

                .achievement {
                    .adjusted-flex-basis(2, 0.5rem);
                    margin-top: 0;
                }
            }
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        #search_v2.profile_page {
            .data {
                .comment_list {
                    a {
                        .adjusted-flex-basis(5, 0.5rem);
                    }
                }

                .subscribers_list {
                    a {
                        .adjusted-flex-basis(10, 0.5rem);
                    }
                }

                .channels_list {
                    a {
                        .adjusted-flex-basis(12, 0.5rem);
                    }
                }

                .achievements {
                    .achievement {
                        .adjusted-flex-basis(4, 0.5rem);
                    }
                }
            }
        }
    }
}
