#channels {
    .space-vertical();
    overflow: hidden;

    .nav_quick_top {
        margin-bottom: 0.625rem;
    }

    ul.results {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 0.5rem;
        row-gap: 1rem;

        li {
            overflow: hidden;

            a.title {
                display: block;
                color: var(--channel-text-color);
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1.25rem;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            a.image {
                display: block;
                position: relative;

                img {
                    width: 100%;
                    border-radius: 0.25rem;
                    margin: 0 auto;
                }
            }
        }
    }

    .pagination {
        margin: 1.5rem 0 0;
    }
}

html.desktop {
    #channels {
        .space-vertical(1.5rem);
        .nav_quick_top {
            margin-bottom: 1rem;
        }

        ul.results {
            grid-template-columns: repeat(5, 1fr);
            column-gap: 1rem;

            li {
                a.title {
                    text-align: center;
                }
            }
        }

        .pagination {
            margin: 1.5rem auto 0;
        }
    }
}

@media all and (min-width: @breakpoint-xl) {
    html.desktop {
        #channels {
            ul.results {
                grid-template-columns: repeat(10, 1fr);
            }
        }
    }
}
