.sidebar-nav {
    .item {
        display: flex;
        align-items: center;
        padding: 1.25rem 1rem;
        background-color: var(--sidebar-nav-item-bg-color);
        color: var(--sidebar-nav-item-color);
        font-size: 0.875rem;
        line-height: 1.25rem;
        border-top: 0.0625rem solid var(--sidebar-nav-item-border-color);
        border-bottom: none;
        .defaulthover;

        &.active {
            color: var(--sidebar-nav-item-active-color);
        }

        &:first-child:not(:last-child) {
            border-radius: 0.5rem 0.5rem 0 0;
            border-top: none;
        }

        &:last-child:not(:first-child) {
            border-radius: 0 0 0.5rem 0.5rem;
        }

        &:first-child:last-child {
            border-radius: 0.5rem;
            border-top: none;
        }

        .i_svg {
            width: 1.5rem;
            height: 1.5rem;
            color: var(--sidebar-nav-item-icon-color);
            position: static;

            &:first-child {
                margin-right: 1rem;
                padding: 0.125rem;
            }

            &:last-child {
                margin-right: 0;
                width: 1.25rem;
                height: 1.25rem;
                padding: 0.125rem;
            }
        }

        .text {
            margin-right: auto;
        }

        .warning {
            display: flex;
            margin-left: auto;
            margin-right: 1rem;

            .i_svg {
                color: var(--sidebar-nav-item-warning-icon-color);
                padding: 0;
            }
        }
    }
}

html.desktop {
    .sidebar-nav {
        .item {
            padding: 0.75rem 1rem;

            &:not(.active) {
                .hover-desktop-only({background-color: var(--sidebar-nav-item-hover-bg-color)});
            }
        }
    }
}
