// main:style.less

@media screen and (min-width:769px) and (max-width: 1399px){
  .last_underplayer_ad {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
    body {
        font-size: 15px;
    }
}

@media screen and (min-width: 2600px) {
  body {
    font-size: 17px;
  }
}
