#video_container #main_video_player {
    .vjs-loading-spinner {
        border: 0.5rem solid var(--video-spinner-base-color);
        border-top: 0.5rem solid var(--video-spinner-color);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        animation: spin 1s linear infinite;
        opacity: 1;
        visibility: visible;
        top: 45%;

        &:before,
        &:after {
            display: none;
        }
    }

    &.vjs-fullscreen .vjs-loading-spinner {
        top: 50%;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html.desktop #video_container #main_video_player .vjs-loading-spinner {
    top: 50%;
}
